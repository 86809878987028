import React, {useState, useEffect, useRef, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { FaFile, FaFolder, FaRegFolder } from 'react-icons/fa6';
import { FiChevronLeft, FiChevronRight, FiEdit, FiFile, FiFolder, FiGrid, FiInfo, FiList, FiMoreHorizontal, FiPlus, FiSearch, FiShare, FiTrash } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import Modal from './Modal';
import { UserContext } from '../Context/UserContext';
import { EditDiagramModalV2, NewDiagramModal } from './MyDiagrams';
import { getAllFolders, createFolder, updateFolder, deleteFolder } from '../services/folderService';
import moment from 'moment';
import 'moment/locale/fr';
import { useForm } from 'react-hook-form';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const colorList = ["", "#1CC0FF", "#FFA6BC", "#92B4FB", "#50E3C1", "#FE9A49", "#B9E886"];

const projectsDataTest = [
  {id: 1, name: "Project name test 1", updatedAt: "06/02/2024", color: "#1CC0FF", type: "folder"},
  {id: 2, name: "Project name test 2", updatedAt: "06/02/2024", color: "", type: "folder"},
  {id: 3, name: "File name test 3", updatedAt: "06/02/2024", color: "#FFA6BC", type: "file"},
  {id: 4, name: "Project name test 4", updatedAt: "06/02/2024", color: "#B9E886", type: "folder"},
];

export function NewProjectModal({close, idFolder}){

  const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const {t} = useTranslation();
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const changeColor = (newColor)=>{
    setColor(newColor);
  }

  const submit = async (data)=>{
    setLoading(true);
    const dataToSend = {
      "name": data.name,
      "parentFolder": idFolder,
      "color": color
    };
    createFolder(dataToSend)
    .then((res)=>{
      console.log(res);
      setLoading(false);
      close();
    })
    .catch((err)=>{
      console.log(err);
      setErrorCode(err.response.data.message)
      setLoading(false);
    })
  }

  if(loading){
    return(
      <div className='min-h-32' >
        <div className='loader' />
      </div>
    )
  }

  return(
    <form onSubmit={handleSubmit(submit)}>
      { errorCode && <label className='text-xs text-red-700 font-semibold truncate mb-5' >{t(`ERRORS.${errorCode}`)}</label>}
      <div className='flex flex-col mb-3' >
        <div className='flex items-center justify-between' >
          <label className='text-xs mb-0.5 ml-2.5 text-gray-500' >{t("GLOBAL.NAME")} *</label>
          { errors.name && <label className='text-xs text-red-700 font-semibold truncate' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
        </div>
        <input
          name='name'
          type='text'
          placeholder={t("GLOBAL.NAME")}
          className='border rounded-lg px-2 py-1 text-sm'
          {...register("name", { required: true })}
        />
      </div>
      <div className='flex flex-wrap gap-3' >
        {colorList.map((it)=>
          it.length === 0
          ? <div onClick={(e)=>{changeColor("") }} key={it} className={cx("h-5 w-5 cursor-pointer rounded-full border hover:border-aqua", {'border-azure border-2':it=== color, 'border-black':it!== color})} />
          : <div onClick={(e)=>{changeColor(it) }} key={it} className={cx("h-5 w-5 cursor-pointer rounded-full border hover:border-aqua", {'border-azure border-2':it=== color, 'border-white':it!== color})} style={{backgroundColor: it}} />
        )}
      </div>

      <div className='flex justify-end mt-6' >
        <button className='border border-azure font-semibold rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure' >
          {t("GLOBAL.SAVE")}
        </button>
      </div>
    </form>
  )
}

export function EditProjectModal({oldData, close}){

  const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const {t} = useTranslation();
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);

  const changeColor = (newColor)=>{
    setColor(newColor);
  }

  const submit = async (data)=>{
    setLoading(true);
    const dataToSend = {
      "name": data.name,
      "parentFolder": oldData?.parentFolder ? oldData.parentFolder : "",
      "color": color
    };
    updateFolder(dataToSend, oldData.id)
    .then((res)=>{
      console.log(res);
      setLoading(false);
      close();
    })
    .catch((err)=>{
      console.log(err);
      setLoading(false);
    })
  }

  useEffect(()=>{
    if(Object.keys(oldData).length > 0){
      setValue("name",oldData.name);
      setColor(oldData?.color);
    }
  },[oldData]);

  if(loading){
    return(
      <div className='min-h-32' >
        <div className='loader' />
      </div>
    )
  }

  return(
    <form onSubmit={handleSubmit(submit)}>
      <div className='flex flex-col mb-3' >
        <div className='flex items-center justify-between' >
          <label className='text-xs mb-0.5 ml-2.5 text-gray-500' >{t("GLOBAL.NAME")} *</label>
          { errors.name && <label className='text-xs text-red-700 font-semibold truncate' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
        </div>
        <input
          name='name'
          type='text'
          placeholder={t("GLOBAL.NAME")}
          className='border rounded-lg px-2 py-1 text-sm'
          {...register("name", { required: true })}
        />
      </div>
      <div className='flex flex-wrap gap-3' >
        {colorList.map((it)=>
          it.length === 0
          ? <div onClick={(e)=>{changeColor("") }} key={it} className={cx("h-5 w-5 cursor-pointer rounded-full border hover:border-aqua", {'border-azure border-2':it=== color, 'border-black':it!== color})} />
          : <div onClick={(e)=>{changeColor(it) }} key={it} className={cx("h-5 w-5 cursor-pointer rounded-full border hover:border-aqua", {'border-azure border-2':it=== color, 'border-white':it!== color})} style={{backgroundColor: it}} />
        )}
      </div>

      <div className='flex justify-end mt-6' >
        <button className='border border-azure font-semibold rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure' >
          {t("GLOBAL.SAVE")}
        </button>
      </div>
    </form>
  )
}

export function ProjectContainer({project, openEditFolder, openEditFile}){

  const {setCurrDiagramId, setCurrDiagramAccessType} = useContext(UserContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeColor = (newColor)=>{
    project.color = newColor;
    setIsMenuOpen(false);
  }

  const clickProject = ()=>{
    if(project.type === "folder"){
      navigate('/'+project.id)
    }else{
      setCurrDiagramId(project.project);
      setCurrDiagramAccessType(project.accessType === null ? "OWNER" : project.accessType);
      navigate(`/board/${project.project}`);
    }
  }

  const clickEdit = ()=>{
    if(project.type === "folder"){
      openEditFolder();
    }else{
      openEditFile();
    }
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div onClick={()=> clickProject() } className='flex flex-col justify-between border rounded-lg customShadow p-4 cursor-pointer relative bg-white' >
      <div className='flex items-start justify-between' >
        {
          project.color
          ? project.type === "folder"
            ? <FaFolder size={42} className='mb-4' strokeWidth={1} color={project.color} />
            : <FaFile size={42} className='mb-4' strokeWidth={1} color={project.color} />
          : project.type === "folder"
            ? <FiFolder size={44} className='mb-4' strokeWidth={1} />
            : <FiFile size={44} className='mb-4' strokeWidth={1} />
        }
        <FiMoreHorizontal onClick={(e)=>{e.stopPropagation(); setIsMenuOpen(!isMenuOpen) }} size={16} className='cursor-pointer hover:text-azure' />
      </div>
      <div>
        <h4 className='font-semibold mb-1' >{project.name}</h4>
        <p className='text-xs text-gray-500' >Dernière mise à jour le 06/02/2024 16:00</p>
      </div>
      { isMenuOpen &&
        <div onClick={(e)=>{e.stopPropagation(); }} ref={menuRef} className='absolute z-20 py-2 w-max max-w-[8rem] top-9 right-2 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            <li onClick={()=> clickEdit() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiEdit className='text-yellow-500' />
              <span>{t("GLOBAL.EDIT")}</span>
            </li>
            <li className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiTrash className='text-red-600' />
              <span>{t("GLOBAL.DELETE")}</span>
            </li>
            <li className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiShare className='text-sky-500' />
              <span>{t("GLOBAL.SHARE")}</span>
            </li>
            <li className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiInfo />
              <span>{t("GLOBAL.INFORMATIONS")}</span>
            </li>
            <li className='px-2 py-1' >
              <div className='flex flex-wrap gap-3' >
                {colorList.map((it)=>
                  it.length === 0
                  ? <div onClick={(e)=>{changeColor("") }} key={it} className="h-4 w-4 rounded-full border border-black hover:border-azure" />
                  : <div onClick={(e)=>{changeColor(it) }} key={it} className="h-4 w-4 rounded-full border border-white hover:border-azure" style={{backgroundColor: it}} />
                )}
              </div>
            </li>
          </ul>
        </div>
      }
    </div>
  )
}

export function FolderContainer({project, openEditFolder, fetchFolders, isPreview, toProject}){

  const {setCurrDiagramId, setCurrDiagramAccessType} = useContext(UserContext);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeColor = (newColor)=>{
    project.color = newColor;
    setIsMenuOpen(false);
    const dataToSend = {
      "name": project.name,
      "parentFolder": project.parentFolder,
      "color": newColor
    };
    updateFolder(dataToSend, project.id)
    .then(()=>{
      fetchFolders();
    })
    .catch((err)=>{ console.log(err); })
  }

  const clickProject = ()=>{
    if(isPreview){
      toProject(project.id)
    }else{
      navigate('/'+project.id);
    }
  }

  const clickEdit = ()=>{
    openEditFolder(project);
  }

  const removeFolder = ()=>{
    withReactContent(Swal).fire({
      title: t("HOME.FOLDERS_VIEW.DELETE_MODAL.TITLE"),
      text: t("HOME.FOLDERS_VIEW.DELETE_MODAL.TEXT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFolder(project.id).then((res)=>{
          fetchFolders();
          Swal.fire({
            title: "Supprimé!",
            text: "Votre élément a été supprimé.",
            icon: "success"
          })
        }).catch((err)=> console.log(err) )
      }
    })
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div onClick={()=> clickProject() } className='flex flex-col justify-between border rounded-lg customShadow p-4 cursor-pointer relative bg-white' >
      <div className='flex items-start justify-between' >
        {
          project?.color
          ? <FaFolder size={42} className='mb-4' strokeWidth={1} color={project?.color} />
          : <FiFolder size={44} className='mb-4' strokeWidth={1} />
        }
        { !isPreview &&
          <FiMoreHorizontal
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen)
            }}
            size={16}
            className='cursor-pointer hover:text-azure'
          />
        }
      </div>
      <div>
        <h4 className='font-semibold mb-1' >{project.name}</h4>
        <p className='text-xs text-gray-500' >Dernière mise à jour le {moment(project?.updatedAt).locale('fr').format('ll')}</p>
      </div>
      { isMenuOpen &&
        <div onClick={(e)=>{e.stopPropagation(); }} ref={menuRef} className='absolute z-20 py-2 w-max max-w-[8rem] top-9 right-2 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            <li onClick={()=> clickEdit() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiEdit className='text-yellow-500' />
              <span>{t("GLOBAL.EDIT")}</span>
            </li>
            <li onClick={()=> removeFolder() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiTrash className='text-red-600' />
              <span>{t("GLOBAL.DELETE")}</span>
            </li>
            {/* <li className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiShare className='text-sky-500' />
              <span>{t("GLOBAL.SHARE")}</span>
            </li> */}
            {/* <li onClick={()=> console.log(project) } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiInfo />
              <span>{t("GLOBAL.INFORMATIONS")}</span>
            </li> */}
            <li className='px-2 py-1' >
              <div className='flex flex-wrap gap-3' >
                {colorList.map((it)=>
                  it.length === 0
                  ? <div onClick={(e)=>{changeColor("") }} key={it} className="h-4 w-4 rounded-full border border-black hover:border-azure" />
                  : <div onClick={(e)=>{changeColor(it) }} key={it} className="h-4 w-4 rounded-full border border-white hover:border-azure" style={{backgroundColor: it}} />
                )}
              </div>
            </li>
          </ul>
        </div>
      }
    </div>
  )
}

export default function Folders() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const { setShapes } = useContext(UserContext);

  const [viewType, setViewType] = useState("grid");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderData, setNewFolderData] = useState("");
  const [isEditFolderModalOpen, setIsEditFolderModalOpen] = useState(false);
  const [editFolderModalData, setEditFolderModalData] = useState({});

  const [isNewDiagramModalOpen, setIsNewDiagramModalOpen] = useState(false);
  const [isEditDiagramModalOpen, setIsEditDiagramModalOpen] = useState(false);
  const [editDiagramModalData, setEditDiagramModalData] = useState({});

  const [projects, setProjects] = useState([]);

  const openNewFolderModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewFolderModalOpen(true);
    setNewFolderData(data);
  }
  const closeNewFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewFolderModalOpen(false);
    setNewFolderData({});
    fetchFolders();
  }

  const openEditFolderModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditFolderModalData(data);
    setIsEditFolderModalOpen(true);
  }
  const closeEditFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditFolderModalOpen(false);
    setEditFolderModalData({});
    fetchFolders();
  }

  const openNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewDiagramModalOpen(true);
  }
  const closeNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewDiagramModalOpen(false);
  }

  const openEditDiagramModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditDiagramModalData(data);
    setIsEditDiagramModalOpen(true);
  }
  const closeEditDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditDiagramModalOpen(false);
    setEditDiagramModalData({});
  }

  const fetchFolders = ()=>{
    getAllFolders()
    .then((res)=> setProjects(res) )
    .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    fetchFolders();
  },[]);

  return (
    <div >
      <div className='flex items-center justify-between mb-8' >
        <h1 className='text-xl font-bold ' >{t("HOME.FOLDERS_VIEW.TITLE")}</h1>
        <div className='flex gap-2' >
          {/* <button onClick={()=> viewType === "grid" ? setViewType("table"): setViewType("grid") } className='px-2 rounded-lg hover:text-azure' >
            {
              viewType === "grid"
              ? <FiList size={20} />
              : <FiGrid size={20} />
            }
          </button> */}
          <div className='px-2.5 py-1.5 rounded-lg border text-sm flex items-center gap-2 bg-white' >
            <FiSearch size={16} className='text-gray-500' />
            <input
              type='text'
              placeholder={t("GLOBAL.SEARCH")}
              className='bg-transparent'
              value={search}
              onChange={(e)=> setSearch(e.target.value) }
            />
          </div>
          {/* <button onClick={()=> openNewDiagramModal() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
            <FiPlus size={16} strokeWidth={2.5} />
            {t("HOME.FOLDERS_VIEW.NEW_PROCESS_BTN")}
          </button> */}
          <button onClick={()=> openNewFolderModal("") } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
            <FiPlus size={16} strokeWidth={2.5} />
            {t("HOME.FOLDERS_VIEW.NEW_FOLDER_BTN")}
          </button>
        </div>
      </div>
      <div className='bg-white p-4 border rounded-lg min-h-[70vh] flex flex-col justify-between' >
        <div className='grid grid-cols-4 gap-4' >
          {projects.map((it)=> <FolderContainer key={it.id} project={it} openEditFolder={openEditFolderModal} fetchFolders={fetchFolders} /> )}
          {/* {projectsDataTest.map((it)=> <ProjectContainer key={it.id} project={it} openEditFolder={openEditFolderModal} openEditFile={openEditDiagramModal} /> )} */}
        </div>
        {/* <div className='mt-4 flex items-center justify-between w-full' >
          <div className='flex gap-3 items-center' >
            <button onClick={()=> page > 1 && setPage(page-1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
              <FiChevronLeft size={16} />
            </button>
            <span className='font-semibold text-sm' >{page}</span>
            <button onClick={()=> !isLastPage && setPage(page+1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
              <FiChevronRight size={16} />
            </button>
          </div>
          <div className='flex items-center w-max gap-5' >
            <label className='w-max text-sm' >{t("GLOBAL.PAGINATION_TITLE")}</label>
            <select id="sizeOfPage" defaultValue={10} onChange={(e)=> setSizeOfPage(e.target.value) } className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
              <option value={10} >10</option>
              <option value={15} >15</option>
              <option value={20} >20</option>
            </select>
          </div>
        </div> */}
      </div>
      <Modal isOpen={isNewFolderModalOpen} close={closeNewFolderModal} title={t("HOME.FOLDERS_VIEW.NEW_FOLDER_TITLE")} >
        <NewProjectModal close={closeNewFolderModal} idFolder={newFolderData} />
      </Modal>
      <Modal isOpen={isEditFolderModalOpen} close={closeEditFolderModal} title={t("GLOBAL.EDIT")} >
        <EditProjectModal oldData={editFolderModalData} close={closeEditFolderModal} />
      </Modal>
      <Modal isOpen={isNewDiagramModalOpen} title={t("HOME.NEW_BTN")} close={closeNewDiagramModal} >
        <NewDiagramModal setShapes={setShapes} navigate={navigate} close={closeNewDiagramModal} origine="folder" parentFolder="" />
      </Modal>
      <Modal isOpen={isEditDiagramModalOpen} title={t("GLOBAL.EDIT")} close={closeEditDiagramModal} >
        <EditDiagramModalV2 data={editDiagramModalData} close={closeEditDiagramModal} />
      </Modal>
    </div>
  )
}
