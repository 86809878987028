import React, {useContext, useEffect, useRef, useState} from 'react';
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';
import {motion} from "framer-motion";

import visioBpmLogo from '../../assets/VISIO_BPM_logo.png';
import {FiChevronLeft, FiChevronRight, FiGlobe, FiGrid, FiLogOut, FiUser, FiUserCheck, FiUsers} from 'react-icons/fi';
import {useTranslation} from "react-i18next";
import {loginEvent} from "../../Context/events";
import {FaUsers} from "react-icons/fa";
import {UserContext} from "../../Context/UserContext";

function MasterRoot(props) {

  const location = useLocation();
  const {t, i18n} = useTranslation();
  const { user } = useContext(UserContext);

  const accountMenuRef = useRef(null);
  const langMenuRef = useRef(null);

  const [selectedNav, setSelectedNav] = useState("/");
  const [isBarOpen, setBarOpen] = useState(true);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  const logOut = ()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit('login', false);
  }

  const translate = async (lang)=>{
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    setIsLangMenuOpen(false);
  }

  const handleClickOutside = (event) => {
    if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
      setIsAccountMenuOpen(false);
    }
    if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
      setIsLangMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(location.pathname !== selectedNav ){
      setSelectedNav(location.pathname);
    }
  }, [location]);

  return (
    <div className="flex justify-end bg-[#FAFAFA] min-h-[100vh]">
      <motion.div
        initial={false}
        animate={{
          width: isBarOpen ? '14rem' : '4rem',
          transition: {duration: 0.3, ease: "easeInOut"}
        }}
        className={cx("fixed top-0 left-0 px-2 py-4 h-[100vh] bg-white customShadow", {
          'w-56': isBarOpen,
          'w-16': !isBarOpen
        })}
        // className={cx("fixed top-0 left-0 px-2 py-4 h-[100vh] bg-white customShadow", {'w-56': isBarOpen, 'w-16': !isBarOpen})}
      >
        <div className="relative flex justify-center mb-4">
          <div className={cx({'w-[10rem] h-16': isBarOpen, 'w-12 h-12': !isBarOpen})}>
            <img src={visioBpmLogo} alt="visio bpm" className="h-full w-full object-contain"/>
          </div>
          <button className="absolute right-[-22px] top-8 border rounded-full p-1 bg-white"
                  onClick={() => setBarOpen(!isBarOpen)}>
            {
              isBarOpen
                ? <FiChevronLeft size={20} className="h-5 w-5 object-contain"/>
                : <FiChevronRight size={20} className="h-5 w-5 object-contain"/>
            }
          </button>
        </div>
        <ul className="flex flex-col gap-2">
          <li>
            <Link to="/"
                  className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
                    'bg-azure text-white': selectedNav === "/",
                    'justify-center': !isBarOpen
                  })}>
              <FiGrid/>
              {isBarOpen && <span className='text-sm font-semibold'>{t("MASTER.NAV.DASHBOARD")}</span>}
            </Link>
          </li>
          <li>
            <Link to="/clients"
                  className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
                    'bg-azure text-white': selectedNav === "/clients",
                    'justify-center': !isBarOpen
                  })}>
              <FaUsers/>
              {isBarOpen && <span className='text-sm font-semibold'>{t("MASTER.NAV.CLIENTS")}</span>}
            </Link>
          </li>
          <li>
            <Link to="/users"
                  className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
                    'bg-azure text-white': selectedNav === "/users",
                    'justify-center': !isBarOpen
                  })}>
              <FiUsers/>
              {isBarOpen && <span className='text-sm font-semibold'>{t("MASTER.NAV.USERS")}</span>}
            </Link>
          </li>
        </ul>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          width: isBarOpen ? 'calc(100vw - 14rem)' : 'calc(100vw - 4rem)',
          transition: {duration: 0.3, ease: "easeInOut"}
        }}
        className={cx("px-8 py-3")}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-light ">{t("MASTER.NAV.TITLE")}</h1>
          <div className="flex items-center gap-2" >
            <div className="relative">
              <button onClick={() => setIsAccountMenuOpen(true)}
                      className="flex items-center gap-2 rounded-full text-gray-500 bg-white px-2 py-1">
                <FiUser onClick={() => console.log(user)} size={14}/>
                <span className="text-xs font-semibold">{user.firstName} {user.lastName}</span>
              </button>
              {isAccountMenuOpen &&
                <div ref={accountMenuRef}
                     className='absolute z-20 py-2 w-max bottom-[-4px] translate-y-[100%] right-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden'>
                  <ul className='flex flex-col gap-2'>
                    <li>
                      <Link onClick={() => setIsAccountMenuOpen(false)} to="/account"
                            className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                        <FiUser/>
                        <span>{t("GLOBAL.ACCOUNT")}</span>
                      </Link>
                    </li>
                    <li onClick={() => logOut()}
                        className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                      <FiLogOut/>
                      <span>{t("GLOBAL.LOGOUT")}</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
            <div className="relative" >
              <button
                onClick={() => setIsLangMenuOpen(true)}
                className="flex items-center gap-2 rounded-full text-gray-500 bg-white px-2 py-1">
                <FiGlobe size={16}/>
                <span className="text-xs font-semibold">{i18n.language}</span>
              </button>
              {isLangMenuOpen &&
                <div ref={langMenuRef}
                     className='absolute z-20 py-2 w-max min-w-[60px] bottom-[-4px] translate-y-[100%] right-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden'>
                  <ul className='flex flex-col gap-2'>
                    <li
                      onClick={()=> translate("fr") }
                      className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                      <span>FR</span>
                    </li>
                    <li
                      onClick={()=> translate("en") }
                      className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                      <span>ENG</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <Outlet/>
      </motion.div>
    </div>
  );
}

export default MasterRoot;
