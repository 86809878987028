import api from "./api";

export const getAllUsers = async ()=>{

  try {
    const response = await api.post('/admin/users/all', {'keyWord': ""} );
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getUserById = async (id)=>{
  try {
    const response = await api.get(`/admin/users/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getUsersInfoByIds = async (ids)=>{
  try {
    const response = await api.post(`/admin/users/infos`, {ids});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getAllRoles = async ()=>{
  try {
    const response = await api.get('/admin/roles/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getUserByPage = async (page, size, search) =>{
  const params = {
    page: page,
    size: size,
    sort: `id,asc`,
  };

  const formData = new FormData();
  formData.append('filter', new Blob([JSON.stringify({
    keyWord: search,
  })], { type: 'application/json' }));

  try {
    const response = await api.post('/admin/users/page', formData, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createUser = async (formData) =>{

  try {
    const response = await api.post('/admin/users/create', formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateUser = async (formData, id) =>{

  try {
    const response = await api.put(`/admin/users/update/${id}`, formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteUser = async (id)=>{
  try {
    const response = await api.delete(`/admin/users/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
