import React, {useEffect, useState, useRef, useContext, useCallback} from 'react';
import { Stage, Layer, Line, Rect, Transformer, Text, Group, Arrow, Circle, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { renderToStaticMarkup } from 'react-dom/server';

import { FiCircle, FiClock, FiPlusSquare, FiMail, FiTriangle, FiPlus, FiUser, FiSettings, FiRewind, FiX, FiTrash, FiInfo } from 'react-icons/fi';
import { TbRectangle, TbPentagon, TbScript, TbHandStop, TbArrowBigRight, TbNorthStar } from "react-icons/tb";
import { CgArrowLongRightE } from "react-icons/cg";
import { LuSquareEqual } from "react-icons/lu";
import { PiTableFill, PiWaveTriangleBold } from "react-icons/pi";

import { DragContext } from '../Context/DragContext';
import { UserContext } from '../Context/UserContext';

import { undoEvent, redoEvent } from '../Context/events';

import {
  BpmnStartEvent,
  BpmnStartEventTimer,
  BpmnStartEventMessage,
  BpmnStartEventSignal,
  BpmnStartEventCondition,
  BpmnStartEventParallelMultiple,
  BpmnStartEventMultiple,

  BpmnTask,
  BpmnUserTask,
  BpmnServiceTask,
  BpmnReceiveTask,
  BpmnSendTask,
  BpmnScriptTask,
  BpmnManualTask,
  BpmnBusinessRuleTask,

  BpmnSubprocessCollapsed,

  BpmnGateway,
  BpmnGatewayOr,
  BpmnGatewayParallel,
  BpmnGatewayEventbased,
  BpmnGatewayComplex,

  BpmnIntermediateEvent,
  BpmnIntermediateEventCatchTimer,
  BpmnIntermediateEventCatchMessage,
  BpmnIntermediateEventCatchSignal,
  BpmnIntermediateEventCatchLink,
  BpmnIntermediateEventCatchCompensation,
  BpmnIntermediateEventCatchEscalation,
  BpmnIntermediateEventCatchCondition,
  BpmnIntermediateEventCatchParallelMultiple,
  BpmnIntermediateEventCatchMultiple,

  BpmnEndEvent,
  BpmnEndEventTerminate,
  BpmnEndEventMessage,
  BpmnEndEventSignal,
  BpmnEndEventCompensation,
  BpmnEndEventEscalation,
  BpmnEndEventError,
  BpmnEndEventCancel,
  BpmnEndEventMultiple,

  BpmnConnection,
  DashedLine,
  DashedConnectionLine,

  BpmnParticipant,
  BpmnLaneDivideTwo,

  BpmnDataObject,
  BpmnDataStore,
  BpmnTextAnnotation
} from '../assets/SVGIcons';
import { getProjectById, getProjectCurrVersion } from '../services/projectService';
import { parseXML } from './Header';
import EditableTextKonvaV2 from '../ressources/EditableTextKonvaV2';
import { useTranslation } from 'react-i18next';


const getCursorPosition = (e)=>{
  const stage = e.target.getStage();
  // Get the raw pointer position
  const pointerPosition = stage.getPointerPosition();
  // Adjusting for scale and position (offset)
  const scale = stage.scaleX(); // assuming scaleX and scaleY are the same
  const position = stage.position();
  const transformedPointerPosition = {
    x: (pointerPosition.x - position.x) / scale,
    y: (pointerPosition.y - position.y) / scale
  };

  return transformedPointerPosition;
}

function checkIfInsideProcess(shape, shapes) {
  for (let process of shapes) {
    if (process.type === 'process') {
      let isInside = false;

      // Check for rectangles
      if (shape.type === 'rectangle' || shape.type === 'TextAnnotation' ) {
        isInside = (
          shape.x >= process.x &&
          shape.y >= process.y &&
          shape.x + shape.width <= process.x + process.width &&
          shape.y + shape.height <= process.y + process.height
        );
      }

      // Check for circles
      else if (shape.type === 'circle') {
        isInside = (
          shape.x - shape.radius >= process.x &&
          shape.y - shape.radius >= process.y &&
          shape.x + shape.radius <= process.x + process.width &&
          shape.y + shape.radius <= process.y + process.height
        );
      }

      else if (shape.type === 'arrow') {
        const start = { x: shape.points[0], y: shape.points[1] };
        const end = { x: shape.points[6], y: shape.points[7] };
        isInside = (
          start.x >= process.x && start.y >= process.y &&
          end.x <= process.x + process.width && end.y <= process.y + process.height
        );
      }

      if (shape.type === 'square') {
        isInside = (
          shape.x >= process.x &&
          shape.y >= process.y &&
          shape.x + shape.width <= process.x + process.width &&
          shape.y + shape.height <= process.y + process.height
        );
      }

      if (isInside) {
        return process.id;
      }
    }
  }
  return null;
}

function checkIfInsideDivideLine(shape, shapes) {
  for (let process of shapes) {
    if (process.type === 'divideLine') {
      let isInside = false;

      // Check for rectangles
      if (shape.type === 'rectangle' || shape.type === 'TextAnnotation' ) {
        isInside = (
          shape.x >= process.x &&
          shape.y >= process.y &&
          shape.x + shape.width <= process.x + process.width &&
          shape.y + shape.height <= process.y + process.height
        );
      }

      // Check for circles
      else if (shape.type === 'circle') {
        isInside = (
          shape.x - shape.radius >= process.x &&
          shape.y - shape.radius >= process.y &&
          shape.x + shape.radius <= process.x + process.width &&
          shape.y + shape.radius <= process.y + process.height
        );
      }

      else if (shape.type === 'arrow') {
        const start = { x: shape.points[0], y: shape.points[1] };
        const end = { x: shape.points[6], y: shape.points[7] };
        isInside = (
          start.x >= process.x && start.y >= process.y &&
          end.x <= process.x + process.width && end.y <= process.y + process.height
        );
      }

      if (shape.type === 'square') {
        isInside = (
          shape.x >= process.x &&
          shape.y >= process.y &&
          shape.x + shape.width <= process.x + process.width &&
          shape.y + shape.height <= process.y + process.height
        );
      }

      if (isInside) {
        return process.name;
      }
    }
  }
  return null;
}

function truncateText(text, maxLength) {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function Rectangle({ shape, i, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, isResizing, selectedShape, setSelectedShape, setDraggedArrowsToHide, openBar, handleRightClick }) {

  const groupRef = useRef(null);
  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);
  const [initialPositions, setInitialPositions] = useState({});

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'Task':
        return null
      case 'UserTask':
        return <FiUser size={20} color='#2EBED6' />
      case 'ServiceTask':
        return <FiSettings size={20} color='#2EBED6' />
      case 'ReceiveTask':
        return <FiMail size={20} color='#2EBED6' />
      case 'SendTask':
        return <FiMail size={20} color='#C2E4EF' fill='#2EBED6' />
      case 'ScriptTask':
        return <TbScript size={20} color='#2EBED6' />
      case 'ManualTask':
        return <TbHandStop size={20} color='#2EBED6'/>
      case 'BusinessRuleTask':
        return <PiTableFill size={20} color='#2EBED6' />
      default:
        break;
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }

    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            // return { ...s, points: updatedPoints };
          } else {
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }

  };

  const onDragEnd = (e)=>{
    setIsSelecting(false);
    setIsDraggingShape(false);
    setIsShapeDragging(false);
    const updatedRectangles = shapes.slice();
    const updatedShape = {
      ...shape,
      x: e.target.x(),
      y: e.target.y(),
    };
    updatedShape.processId = checkIfInsideProcess(updatedShape, shapes);
    updatedShape.divideLineName = checkIfInsideDivideLine(updatedShape, shapes);
    updatedRectangles[i] = updatedShape;
    shapes.forEach((s) => {
      if (s.type === 'arrow' && s.linkedShapeId === updatedShape.id) {
      }
    });
    updateShapes(updatedShape, i);
    if(selectedIds.length > 1){
      setDraggedArrowsToHide({from: "", to: ""});
    }else{
      updateArrowAtEnd(e);
    }
    if (selectedShape?.id === shape.id) {
      setSelectedShape(updatedShape);
    }
  }

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: "", to: ""});

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      if(snappedX > newPoints[2]){
        newPoints[0] = snappedX ;
      }else{
        newPoints[0] = snappedX + (shape.width);
      }
      newPoints[1] = snappedY + (shape.height / 2);
      newPoints[3] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX > newPoints[4]){
        newPoints[6] = snappedX ;
      }else{
        newPoints[6] = snappedX + (shape.width);
      }
      newPoints[7] = snappedY + (shape.height / 2);
      newPoints[5] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
  }

  return (
    <Group
      ref={groupRef}
      key={i}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable
      onClick={(e) => setSelectedId(e, shape.id)}
      onDragStart={(e) => {
        // if (selectedIds.length > 1) {
        //   e.evt.preventDefault();
        //   return;
        // }
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsDraggingShape(true);
        setIsShapeDragging(true);
        // setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => onDragEnd(e) }
      onDblClick={()=> openBar("properties") }
    >
      <Rect
        id={shape.id}
        x={0}
        y={0}
        width={shape.width}
        height={shape.height}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        // cornerRadius={shape.cornerRadius}
        cornerRadius={7}
        fill={shape.bgColor}
        rotation={shape.rotation}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      />
      {
        !isDraggingShape &&
        <Text
          // text={shape.name}
          text={truncateText(shape.name, shape.width / 2)}
          x={shape.width / 2}
          y={shape.height / 2}
          offsetY={6}
          offsetX={shape.width / 2}
          listening={false}
          width={shape.width}
          fontSize={shape.fontSize}
          align='center'
          verticalAlign='middle'
          fontStyle={`${shape.fontWeight} ${shape.fontStyle}`}
          textDecoration={shape.textDecoration}
          fill={shape.fontColor}
        />
      }
      {iconImage && (
        !isDraggingShape ?
        <KonvaImage
          image={iconImage}
          x={2}
          y={2}
          width={14}
          height={14}
          listening={false}
        />
        : null
      )}
    </Group>
  );
}

function MyCircle({ shape, index, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, setSelectedShape, setDraggedArrowsToHide, openBar, handleRightClick }) {
  const [isDraggingShape, setIsDraggingShape] = useState(false);

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);
  const [initialPositions, setInitialPositions] = useState({});

  const getShapeIcon = ()=>{

    if(shape.family === "StartEvent"){
      switch (shape.shapeType) {
        case 'StartEvent':
          return null
        case 'TimerEventDefinition':
          return <FiClock size={20} color='#7BA23D' />
        case 'MessageEventDefinition':
          return <FiMail size={20} color='#7BA23D' />
        case 'SignalEventDefinition':
          return <FiTriangle size={20} color='#7BA23D' />
        case 'ConditionalEventDefinition':
          return <LuSquareEqual size={20} color='#7BA23D' />
        case 'ParallelMultipleStartEvent':
          return <FiPlus size={20} color='#7BA23D' />
        case 'MultipleStartEvent':
          return <TbPentagon size={20} color='#7BA23D' />
        default:
          break;
      }
    }else{
      switch (shape.shapeType) {
        case 'EndEvent':
          return null
        case 'TerminateEventDefinition':
          return <FiCircle size={20} fill='#D6323A' color='#D6323A' />
        case 'MessageEventDefinition':
          return <FiMail size={20} color='#D6323A' />
        case 'SignalEventDefinition':
          return <FiTriangle size={18} fill='#D6323A' color='#D6323A' />
        case 'CompensateEventDefinition':
          return <FiRewind size={18} fill='#D6323A' color='#D6323A' />
        case 'EscalationEventDefinition':
          return <FiTriangle size={18} fill='#D6323A' color='#D6323A' />
        case 'ErrorEventDefinition':
          return <PiWaveTriangleBold size={18} strokeWidth={30} fill='#D6323A' color='#D6323A' />
        case 'CancelEventDefinition':
          return <FiX size={20} strokeWidth={5} fill='#D6323A' color='#D6323A' />
        case 'MultipleEndEvent':
          return <TbPentagon size={20} fill='#D6323A' color='#D6323A' />
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }
    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            // return { ...s, points: updatedPoints };
          } else {
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }
  };

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: "", to: ""});

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      if(snappedX > newPoints[2] ){
        newPoints[0] = snappedX - (shape.radius) ;
      }else{
        newPoints[0] = snappedX + (shape.radius);
      }
      newPoints[1] = snappedY;
      newPoints[3] = snappedY;

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX > newPoints[4] ){
        newPoints[6] = snappedX - (shape.radius);
      }else{
        newPoints[6] = snappedX + (shape.radius);
      }
      newPoints[7] = snappedY;
      newPoints[5] = snappedY;

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
  }

  return (
    <Group
      key={index}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.radius*2}
      height={shape.radius*2}
      draggable
      onClick={(e) =>{ setSelectedId(e, shape.id)}}
      onDragStart={(e) => {
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsDraggingShape(true);
        setIsShapeDragging(true);
        // setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => {
        setIsSelecting(false);
        setIsDraggingShape(false);
        setIsShapeDragging(false);
        const updatedShapes = shapes.slice();
        const updatedCircle = {
          ...shape,
          x: e.target.x(),
          y: e.target.y(),
        };
        updatedCircle.processId = checkIfInsideProcess(updatedCircle, shapes);
        updatedCircle.divideLineName = checkIfInsideDivideLine(updatedCircle, shapes);
        updatedShapes[index] = updatedCircle;
        updateShapes(updatedCircle, index);
        if(selectedIds.length > 1){
          setDraggedArrowsToHide({from: "", to: ""});
        }else{
          updateArrowAtEnd(e);
        }
        setSelectedShape(updatedCircle);
      }}
      onDblClick={()=> openBar("properties") }
    >
      <Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      />
      {iconImage && (
        !isDraggingShape ?
        <KonvaImage
          image={iconImage}
          x={ - iconImage.width / 2}
          y={ - iconImage.height / 2}
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
        : null
      )}
    </Group>
  );
}

function IntermediateCircle({ shape, index, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, setSelectedShape, setDraggedArrowsToHide, openBar, handleRightClick }) {

  const groupRef = useRef();
  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const [initialPositions, setInitialPositions] = useState({});

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'IntermediateCatchEvent':
        return null
      case 'TimerEventDefinition':
        return <FiClock size={18} color='#F9BF00' />
      case 'MessageEventDefinition':
        return <FiMail size={18} color='#F9BF00' />
      case 'SignalEventDefinition':
        return <FiTriangle size={16} color='#F9BF00' />
      case 'LinkEventDefinition':
        return <TbArrowBigRight size={18} color='#F9BF00' />
      case 'CompensateEventDefinition':
        return <FiRewind size={18} color='#F9BF00' />
      case 'EscalationEventDefinition':
        return <TbPentagon size={18} color='#F9BF00' />
      case 'ConditionalEventDefinition':
        return <LuSquareEqual size={18} color='#F9BF00' />
      case 'IntermediateParallelMultipleEvent':
        return <FiPlus size={18} strokeWidth={5} color='#F9BF00' />
      case 'IntermediateMultipleEvent':
        return <TbPentagon size={18} color='#F9BF00' />
      default:
        break;
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }

    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            // return { ...s, points: updatedPoints };
          } else {
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }
  };

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      if(snappedX > newPoints[2] ){
        newPoints[0] = snappedX - (shape.radius) ;
      }else{
        newPoints[0] = snappedX + (shape.radius);
      }
      newPoints[1] = snappedY;
      newPoints[3] = snappedY;

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX > newPoints[4] ){
        newPoints[6] = snappedX - (shape.radius);
      }else{
        newPoints[6] = snappedX + (shape.radius);
      }
      newPoints[7] = snappedY;
      newPoints[5] = snappedY;

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
    setDraggedArrowsToHide({from: "", to: ""});
  }

  return (
    <Group
      ref={groupRef}
      key={index}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.radius*2}
      height={shape.radius*2}
      draggable
      onClick={(e) => setSelectedId(e, shape.id)}
      onDragStart={(e) => {
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsDraggingShape(true);
        setIsShapeDragging(true);
        setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => {
        setIsSelecting(false);
        setIsDraggingShape(false);
        setIsShapeDragging(false);
        const updatedShapes = shapes.slice();
        const updatedCircle = {
          ...shape,
          x: e.target.x(),
          y: e.target.y()
        };
        updatedCircle.processId = checkIfInsideProcess(updatedCircle, shapes);
        updatedCircle.divideLineName = checkIfInsideDivideLine(updatedCircle, shapes);
        updatedShapes[index] = updatedCircle;
        updateShapes(updatedCircle, index);
        if(selectedIds.length > 1){
          setDraggedArrowsToHide({from: "", to: ""});
        }else{
          updateArrowAtEnd(e);
        }
        setSelectedShape(updatedCircle);
      }}
      onDblClick={()=> openBar("properties") }
    >
      <Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
        // draggable
        // onClick={(e) => setSelectedId(e, shape.id)}
        // onDragStart={(e) => {
        //   setIsSelecting(false);
        //   setIsDraggingShape(true);
        //   setSelectedId(e, shape.id);
        // }}
        // onDragMove={onDragMove}
        // onDragEnd={(e) => {
        //   setIsSelecting(false);
        //   setIsDraggingShape(false);
        //   const updatedShapes = shapes.slice();
        //   const updatedCircle = {
        //     ...shape,
        //     x: e.target.x(),
        //     y: e.target.y(),
        //   };
        //   updatedCircle.processId = checkIfInsideProcess(updatedCircle, shapes);
        //   updatedShapes[index] = updatedCircle;
        //   updateShapes(updatedShapes);
        // }}
      />
      { !isDraggingShape &&<Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius - 4}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill="transparent"
        listening={false}
      />}
      {iconImage && (
        !isDraggingShape ?
        <KonvaImage
          image={iconImage}
          // x={shape.x - iconImage.width / 2}
          // y={shape.y - iconImage.height / 2}
          x={0 - iconImage.width / 2}
          y={0 - iconImage.height / 2}
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
        : null
      )}
    </Group>
  );
}

function Gateway({ shape, index, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, setSelectedShape, setDraggedArrowsToHide, openBar, handleRightClick }){

  const groupRef = useRef();
  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);
  const [initialPositions, setInitialPositions] = useState({});

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'ExclusiveGateway':
        return null
      case 'InclusiveGateway':
        return <FiCircle size={20} color='#F9BF00' />
      case 'ParallelGateway':
        return <FiPlus strokeWidth={4} size={20} color='#F9BF00' />
      case 'EventBasedGateway':
        return <TbPentagon size={20} color='#F9BF00' />
      case 'ComplexGateway':
        return <TbNorthStar size={20} color='#F9BF00' />
      default:
        break;
    }
  }

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }

    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            // return { ...s, points: updatedPoints };
          } else {
            // For other shapes, just adjust x and y
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }
  };

  const onDragEnd = (e) => {
    setIsSelecting(false);
    setIsDraggingShape(false);
    setIsShapeDragging(false);
    const updatedRectangles = shapes.slice();
    const updatedShape = {
      ...shape,
      x: e.target.x(),
      y: e.target.y(),
    };
    updatedShape.processId = checkIfInsideProcess(updatedShape, shapes);
    updatedShape.divideLineName = checkIfInsideDivideLine(updatedShape, shapes);
    updatedRectangles[index] = updatedShape;
    shapes.forEach((s) => {
      if (s.type === 'arrow' && s.linkedShapeId === updatedShape.id) {
      }
    });
    updateShapes(updatedShape, index);
    setSelectedShape(updatedShape);
    // if(selectedIds.length > 1) {
    //   const group = groupRef.current;
    //   updateArrowAtEnd({ target: group });
    //   return null;
    // }
    if(selectedIds.length > 1){
      setDraggedArrowsToHide({from: "", to: ""});
    }else{
      updateArrowAtEnd(e);
    }
  }

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: "", to: ""});

    // console.log(indexFrom);
    // console.log(indexTo);

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      // if(newPoints[0] > newPoints[2] ){
      //   newPoints[0] = snappedX - shape.width*0.775;
      // }else{
      //   newPoints[0] = snappedX + (shape.width) - shape.width*0.225 ;
      // }

      if(snappedX - shape.width*0.225 > newPoints[2]){
        newPoints[0] = snappedX - shape.width*0.775;
      }else{
        newPoints[0] = snappedX + (shape.width) - shape.width*0.225 ;
      }

      newPoints[1] = snappedY + (shape.height / 2) + shape.height*0.225;
      newPoints[3] = snappedY + (shape.height / 2) + shape.height*0.225;

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX - shape.width*0.225 > newPoints[4]){
        newPoints[6] = snappedX - shape.width*0.775;
      }else{
        newPoints[6] = snappedX + (shape.width) - shape.width*0.225 ;
      }
      // if(newPoints[6] > newPoints[4] ){
      //   newPoints[6] = snappedX - shape.width*0.775;
      // }else{
      //   newPoints[6] = snappedX + (shape.width) - shape.width*0.225 ;
      // }
      newPoints[7] = snappedY + (shape.height / 2) + shape.height*0.225;
      newPoints[5] = snappedY + (shape.height / 2) + shape.height*0.225;

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  return(
    <Group
      ref={groupRef}
      key={shape.id}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable
      onClick={(e) => setSelectedId(e, shape.id)}
      onDragStart={(e) => {
        // if (selectedIds.length > 1) {
        //   e.evt.preventDefault();
        //   return;
        // }
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsDraggingShape(true);
        setIsShapeDragging(true);
        setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => onDragEnd(e) }
      onDblClick={()=> openBar("properties") }
    >
      <Rect
        id={shape.id}
        width={shape.width}
        height={shape.height}
        x={0}
        y={0}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
        rotation={45}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      />
      {iconImage && (
        !isDraggingShape ?
        <KonvaImage
          image={iconImage}
          x={0 - iconImage.width / 2}
          y={iconImage.height / 2 }
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
        : null
      )}
    </Group>
  )
}

function DivideLine({it, setSelectedId, selectedIds, openBar, handleRightClick}){

  const textRef_part1 = useRef();
  const [textWidthPart1, setTextWidthPart1] = useState(60);

  let text_part1 = it.name;
  let yPosition_part1 = it.height/2 + textWidthPart1/2;
  let sliceValue = 0.1604* (it.height - 1.645);

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    if (textRef_part1.current) {
      setTextWidthPart1(textRef_part1.current.getTextWidth());
    }
  }, [it]);

  return(
    <Group
      key={it.id}
      id={it.id}
      x={it.x}
      y={it.y}
      draggable={false}
      // listening={false}
      onClick={(e) =>{ setSelectedId(e, it.id)}}
      onDblClick={(e)=>{ openBar("properties"); setSelectedId(e, it.id) }}
      onContextMenu={(event) => handleRightClick(event, it.id)}
    >
      <Rect
        x={0}
        y={0}
        width={it.width}
        height={it.height}
        stroke="#000"
        fill='#fff'
        draggable={false}
        // listening={false}
      />
      <Text
        ref={textRef_part1}
        text={truncateText(text_part1.slice(0, sliceValue), sliceValue-1)}
        x={text_part1.length >= 40 ? 8 : 12 }
        y={yPosition_part1}
        fontSize={12}
        rotation={-90}
        fontStyle='bold'
        listening={false}
        fill={selectedIds.includes(it.id) ? "#01A1FF" : "#000"}
      />
    </Group>
  )
}

function Process({shape, shapes, setShapes, index, setSelectedId, selectedIds, setIsResizing, isSelecting, updateShapeAtIndex, openBar, handleRightClick}){

  const textRef_part1 = useRef();
  const textRef_part2 = useRef();
  const groupRef = useRef();
  const rect1Ref = useRef(null);
  const transformerRef = useRef();

  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const [textWidthPart1, setTextWidthPart1] = useState(60);
  const [textWidthPart2, setTextWidthPart2] = useState(60);
  const [selectedBand, setSelectedBand] = useState(null);

  let text_part1 = shape.name;
  let text_part2 = shape.name;

  let yPosition_part1 = shape.height/2 + textWidthPart1/2;
  let yPosition_part2 = shape.height/2 + textWidthPart2/2;

  const handleDragMove = (e) => {
    setIsDraggingShape(true);
    const dx = groupRef.current.x() - shape.x;
    const dy = groupRef.current.y() - shape.y;

    const updatedShapes = shapes.map(s => {
      if (s.processId === shape.id) {
        if (s.type === 'arrow') {
          const newPoints = s.points.map((point, index) => {
            return index % 2 === 0 ? point + dx : point + dy;
          });
          return { ...s, points: newPoints };
        } else {
          return { ...s, x: s.x + dx, y: s.y + dy };
        }
      }
      return s;
    });

    const updatedShape = {
      ...shape,
      x: e.target.x(),
      y: e.target.y()
    };
    updatedShapes[index] = updatedShape;

    setShapes(updatedShapes);
  };

  const handleDragEnd = (e)=>{
    setIsDraggingShape(false);

    const dx = groupRef.current.x() - shape.x;
    const dy = groupRef.current.y() - shape.y;

    const updatedShapes = shapes.map(s => {
      if (s.processId === shape.id) {
        if (s.type === 'arrow') {
          const newPoints = s.points.map((point, index) => {
            return index % 2 === 0 ? point + dx : point + dy;
          });
          return { ...s, points: newPoints };
        } else {
          return { ...s, x: s.x + dx, y: s.y + dy };
        }
      }
      return s;
    });
    setShapes(updatedShapes);
  }

  const handleResizeEnd = (e) => {
    setIsResizing(false)
    const node = groupRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset the scale to prevent further scaling
    node.scaleX(1);
    node.scaleY(1);

    // Calculate new dimensions
    const newGroupWidth = shape.width * scaleX;
    const newGroupHeight = shape.height * scaleY;

    // New width for the second rectangle
    const newSecondRectWidth = newGroupWidth;

    // Update the shape data in the state
    const updatedShapes = shapes.map((s, i) => {
      if (i === index) {
        return {
          ...s,
          x: s.x * scaleX,
          y: s.y * scaleY,
          width: newSecondRectWidth,
          height: newGroupHeight
        };
      } else if(s.type === "divideLine"){
        return {
          ...s,
          x: s.x * scaleX,
          y: s.y * scaleY,
          width: s.width * scaleX,
          height: s.height * scaleY,
        };
      }
      return s;
    });
    setShapes(updatedShapes);

    // Force redraw the layer to update the transformer
    transformerRef.current.forceUpdate();
    transformerRef.current.getLayer().batchDraw();
  };

  useEffect(() => {
    if (transformerRef.current) {
      transformerRef.current.rotateEnabled(false);
      transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [shapes]);

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    if (textRef_part1.current) {
      setTextWidthPart1(textRef_part1.current.getTextWidth());
    }
    if (textRef_part2.current) {
      setTextWidthPart2(textRef_part2.current.getTextWidth());
    }
  }, [shape]);

  useEffect(()=>{
    if(isSelecting){
      setSelectedBand(null)
    }
  },[isSelecting]);

  const handleKeyDown = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace' ) {
      if(selectedBand){
        const updatedShapes = shapes.slice();
        const updatedProcess = updatedShapes[index];
        updatedProcess.divideLine -= 1;
        updateShapeAtIndex(updatedProcess, index);
        setSelectedBand(null)
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedBand]);

  return(
    <React.Fragment>
      <Group
        ref={groupRef}
        id={shape.id}
        onTransformStart={()=> setIsResizing(true) }
        onTransformEnd={handleResizeEnd}
        x={shape.x}
        y={shape.y}

        draggable
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd }
        onDblClick={()=> openBar("properties") }
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      >
        <Rect
          ref={rect1Ref}
          x={0}
          y={0}
          width={50}
          height={shape.height}
          stroke="#000"
          fill='#fff'
          onClick={(e) =>{ setSelectedId(e, shape.id); console.log(shape.x, shape.y, shape.width, shape.height)}}
        />
        <Rect
          x={50}
          y={0}
          width={shape.width -50}
          height={shape.height}
          stroke="#000"
          fill='#fff'
          draggable={false}
          listening={false}
        />
        <Text
          ref={textRef_part1}
          text={text_part1.slice(0, 50) }
          x={text_part1.length >= 50 ? 8 : 12 }
          y={yPosition_part1}
          fontSize={12}
          rotation={-90}
          fontStyle='bold'
          listening={false}
        />
        { text_part1.length >= 50 &&
          <Text
            ref={textRef_part2}
            text={truncateText(text_part2.slice(50, 100), 49)}
            x={28}
            y={yPosition_part2}
            fontSize={12}
            rotation={-90}
            fontStyle='bold'
            listening={false}
          />
        }
      </Group>
      {
        shapes.map((it)=>
          it.type === "divideLine" &&
          <DivideLine
            key={it.id}
            it={it}
            setSelectedId={setSelectedId}
            selectedIds={selectedIds}
            openBar={openBar}
            handleRightClick={handleRightClick}
          />
        )
      }
      { selectedIds.includes(shape.id) && <Transformer ref={transformerRef} /> }
    </React.Fragment>
  )
}

function CustomArrowV2({ shape, shapes, onUpdate, onShapeClick, onDragStart, onDragEnd, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };
  const handleStartDrag = (e) => {
    if (selectedIds.length > 1) {
      e.evt.preventDefault();
      return;
    }
    onDragStart(e, shape.id);
  };
  const onDragMove = (e) => {
    const node = e.target;
    // Calculate snap positions for both big and small squares
    const snappedX = node.x();
    const snappedY = node.y();

    // Update the node position
    node.position({
      x: snappedX,
      y: snappedY
    });
  };
  // const handleDragEnd = (e) => {
  //   const dx = groupRef.current.x();
  //   const dy = groupRef.current.y();

  //   groupRef.current.x(0);
  //   groupRef.current.y(0);

  //   const newPoints = shape.points.map((point, index) => {
  //     if (index % 2 === 0) {
  //       return point + dx;
  //     }
  //     return point + dy;
  //   });

  //   onUpdate(shape.id, newPoints, shape.from, shape.to);
  //   groupRef.current.position({ x: 0, y: 0 });
  //   onDragEnd(e, shape.id);
  // };
  const handleDragEnd = (e) => {
    const dx= e.target.x();
    const dy= e.target.y();

    e.target.x(0);
    e.target.y(0);

    const newPoints = shape.points.map((point, index) => {
      if (index % 2 === 0) {
        return point + dx;
      }
      return point + dy;
    });

    onUpdate(shape.id, newPoints, shape.from, shape.to);
    e.target.position({ x: 0, y: 0 });
    groupRef.current && onDragEnd(e, shape.id);
  };

  const snapToCircle = (x, y, circle) => {
    const dx = x - circle.x;
    const dy = y - circle.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const radius = circle.radius;

    // Calculate the closest point on the circumference
    const closestX = circle.x + (dx / distance) * radius;
    const closestY = circle.y + (dy / distance) * radius;

    return { x: closestX, y: closestY };
  };

  const snapToRect = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x, y: centerY }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width, y: centerY }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height }; // Snap to center of bottom side
    }
  };

  const snapToSquare = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2 - rect.width*0.45;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x - rect.width*0.775, y: centerY + rect.height*0.225 }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width - rect.width*0.225, y: centerY + rect.height*0.225 }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height + rect.height*0.45 }; // Snap to center of bottom side
    }
  };


  const updateArrow = (index, x, y) => {
    let snappedShapeId = null;

    shapes.forEach(rect => {
      if(rect.type === "rectangle"){
        if (x > rect.x - 20 && x < rect.x + rect.width + 20 && y > rect.y - 20 && y < rect.y + rect.height + 20) {
          const snapped = snapToRect(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      } else if(rect.type === "square") {
        if (x > rect.x - 30 && x < rect.x + rect.width + 30 && y > rect.y - 30 && y < rect.y + rect.height + 30) {
          const snapped = snapToSquare(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
      else if (rect.type === "circle") {
        const distanceToCenter = Math.sqrt(Math.pow(x - rect.x, 2) + Math.pow(y - rect.y, 2));
        if (distanceToCenter < rect.radius + 20) {
          const snapped = snapToCircle(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
    });

    const newPoints = [...shape.points];

    if (index === 0) {
      newPoints[0] = x;
      newPoints[1] = y;
      newPoints[3] = y;
    } else if(index === 1) {
      newPoints[6] = x;
      newPoints[7] = y;
      newPoints[5] = y;
    }else{
      newPoints[2] = x;
      newPoints[4] = x;
    }

    if (index === 0) {
      onUpdate(shape.id, newPoints, snappedShapeId, shape.to);
    } else if (index === 1) {
      onUpdate(shape.id, newPoints, shape.from, snappedShapeId);
    }else{
      onUpdate(shape.id, newPoints, shape.from, shape.to);
    }

  };
  const handleDragMove = (index) => {
    const anchor = index === 0 ? anchorOneRef.current : anchorTwoRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  };
  const handleDragMoveMiddleAnchor = (index)=>{
    const anchor = middleAnchorRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  }

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable
      id={shape.id}
      onClick={handleClick}
      onDragStart={handleStartDrag}
      onDragMove={onDragMove}
      onDragEnd={handleDragEnd}
    >
      <Arrow
        id={shape.id}
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(0)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(1)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMoveMiddleAnchor(2)}
      />}
    </Group>
  )
}

function DashedCustomArrow({ shape, shapes, onUpdate, onShapeClick, onDragStart, onDragEnd, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };
  const handleStartDrag = (e) => {
    onDragStart(e, shape.id);
  };
  const onDragMove = (e) => {
    const node = e.target;
    // Calculate snap positions for both big and small squares
    const snappedX = node.x();
    const snappedY = node.y();

    // Update the node position
    node.position({
      x: snappedX,
      y: snappedY
    });
  };
  const handleDragEnd = (e) => {
    const dx = groupRef.current.x();
    const dy = groupRef.current.y();

    groupRef.current.x(0);
    groupRef.current.y(0);

    const newPoints = shape.points.map((point, index) => {
      if (index % 2 === 0) {
        return point + dx;
      }
      return point + dy;
    });

    onUpdate(shape.id, newPoints, shape.from, shape.to);
    groupRef.current.position({ x: 0, y: 0 });
    onDragEnd(e, shape.id);
  };

  const snapToCircle = (x, y, circle) => {
    const dx = x - circle.x;
    const dy = y - circle.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const radius = circle.radius;

    // Calculate the closest point on the circumference
    const closestX = circle.x + (dx / distance) * radius;
    const closestY = circle.y + (dy / distance) * radius;

    return { x: closestX, y: closestY };
  };

  const snapToRect = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x, y: centerY }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width, y: centerY }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height }; // Snap to center of bottom side
    }
  };

  const snapToSquare = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2 - rect.width*0.45;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x - rect.width*0.775, y: centerY + rect.height*0.225 }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width - rect.width*0.225, y: centerY + rect.height*0.225 }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height + rect.height*0.45 }; // Snap to center of bottom side
    }
  };


  const updateArrow = (index, x, y) => {
    let snappedShapeId = null;

    shapes.forEach(rect => {
      if(rect.type === "rectangle"){
        if (x > rect.x - 20 && x < rect.x + rect.width + 20 && y > rect.y - 20 && y < rect.y + rect.height + 20) {
          const snapped = snapToRect(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      } else if(rect.type === "square") {
        if (x > rect.x - 30 && x < rect.x + rect.width + 30 && y > rect.y - 30 && y < rect.y + rect.height + 30) {
          const snapped = snapToSquare(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
      else if (rect.type === "circle") {
        const distanceToCenter = Math.sqrt(Math.pow(x - rect.x, 2) + Math.pow(y - rect.y, 2));
        if (distanceToCenter < rect.radius + 20) {
          const snapped = snapToCircle(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
    });

    const newPoints = [...shape.points];

    if (index === 0) {
      newPoints[0] = x;
      newPoints[1] = y;
      newPoints[3] = y;
    } else if(index === 1) {
      newPoints[6] = x;
      newPoints[7] = y;
      newPoints[5] = y;
    }else{
      newPoints[2] = x;
      newPoints[4] = x;
    }

    if (index === 0) {
      onUpdate(shape.id, newPoints, snappedShapeId, shape.to);
    } else if (index === 1) {
      onUpdate(shape.id, newPoints, shape.from, snappedShapeId);
    }else{
      onUpdate(shape.id, newPoints, shape.from, shape.to);
    }

  };
  const handleDragMove = (index) => {
    const anchor = index === 0 ? anchorOneRef.current : anchorTwoRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  };
  const handleDragMoveMiddleAnchor = (index)=>{
    const anchor = middleAnchorRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  }

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable
      id={shape.id}
      onClick={handleClick}
      onDragStart={handleStartDrag}
      onDragMove={onDragMove}
      onDragEnd={handleDragEnd}
    >
      <Arrow
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        dash={[10, 5]}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        stroke="#000"
        strokeWidth={1}
        fill="#fff"
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(0)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(1)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMoveMiddleAnchor(2)}
      />}
    </Group>
  )
}

function DashedCustomLine({ shape, shapes, onUpdate, onShapeClick, onDragStart, onDragEnd, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };
  const handleStartDrag = (e) => {
    if (selectedIds.length > 1) {
      e.evt.preventDefault();
      return;
    }
    onDragStart(e, shape.id);
  };
  const onDragMove = (e) => {
    const node = e.target;
    // Calculate snap positions for both big and small squares
    const snappedX = node.x();
    const snappedY = node.y();

    // Update the node position
    node.position({
      x: snappedX,
      y: snappedY
    });
  };

  // const handleDragEnd = (e) => {
  //   if(groupRef.current){
  //     const dx = groupRef.current.x();
  //     const dy = groupRef.current.y();
  //     const x= e.target.x();
  //     const y= e.target.y();
  //     console.log(x, dx);

  //     groupRef.current.x(0);
  //     groupRef.current.y(0);

  //     const newPoints = shape.points.map((point, index) => {
  //       if (index % 2 === 0) {
  //         return point + dx;
  //       }
  //       return point + dy;
  //     });

  //     onUpdate(shape.id, newPoints, shape.from, shape.to);
  //     groupRef.current.position({ x: 0, y: 0 });
  //     onDragEnd(e, shape.id);
  //   }
  // };
  const handleDragEnd = (e) => {
    const dx= e.target.x();
    const dy= e.target.y();

    e.target.x(0);
    e.target.y(0);

    const newPoints = shape.points.map((point, index) => {
      if (index % 2 === 0) {
        return point + dx;
      }
      return point + dy;
    });

    onUpdate(shape.id, newPoints, shape.from, shape.to);
    e.target.position({ x: 0, y: 0 });
    groupRef.current && onDragEnd(e, shape.id);
  };

  const snapToCircle = (x, y, circle) => {
    const dx = x - circle.x;
    const dy = y - circle.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const radius = circle.radius;

    // Calculate the closest point on the circumference
    const closestX = circle.x + (dx / distance) * radius;
    const closestY = circle.y + (dy / distance) * radius;

    return { x: closestX, y: closestY };
  };

  const snapToRect = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x, y: centerY }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width, y: centerY }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height }; // Snap to center of bottom side
    }
  };

  const snapToSquare = (x, y, rect) => {
    const centerX = rect.x + rect.width / 2 - rect.width*0.45;
    const centerY = rect.y + rect.height / 2;
    const leftDist = Math.abs(x - rect.x);
    const rightDist = Math.abs(x - (rect.x + rect.width));
    const topDist = Math.abs(y - rect.y);
    const bottomDist = Math.abs(y - (rect.y + rect.height));

    // Find the closest side
    const minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

    if (minDist === leftDist) {
      return { x: rect.x - rect.width*0.775, y: centerY + rect.height*0.225 }; // Snap to center of left side
    } else if (minDist === rightDist) {
      return { x: rect.x + rect.width - rect.width*0.225, y: centerY + rect.height*0.225 }; // Snap to center of right side
    } else if (minDist === topDist) {
      return { x: centerX, y: rect.y }; // Snap to center of top side
    } else {
      return { x: centerX, y: rect.y + rect.height + rect.height*0.45 }; // Snap to center of bottom side
    }
  };


  const updateArrow = (index, x, y) => {
    let snappedShapeId = null;

    shapes.forEach(rect => {
      if(rect.type === "rectangle" || rect.type === "TextAnnotation"){
        if (x > rect.x - 20 && x < rect.x + rect.width + 20 && y > rect.y - 20 && y < rect.y + rect.height + 20) {
          const snapped = snapToRect(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      } else if(rect.type === "square") {
        if (x > rect.x - 30 && x < rect.x + rect.width + 30 && y > rect.y - 30 && y < rect.y + rect.height + 30) {
          const snapped = snapToSquare(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
      else if (rect.type === "circle") {
        const distanceToCenter = Math.sqrt(Math.pow(x - rect.x, 2) + Math.pow(y - rect.y, 2));
        if (distanceToCenter < rect.radius + 20) {
          const snapped = snapToCircle(x, y, rect);
          snappedShapeId = rect.id;
          x = snapped.x;
          y = snapped.y;
        }
      }
    });

    const newPoints = [...shape.points];

    if (index === 0) {
      newPoints[0] = x;
      newPoints[1] = y;
      newPoints[3] = y;
    } else if(index === 1) {
      newPoints[6] = x;
      newPoints[7] = y;
      newPoints[5] = y;
    }else{
      newPoints[2] = x;
      newPoints[4] = x;
    }

    if (index === 0) {
      onUpdate(shape.id, newPoints, snappedShapeId, shape.to);
    } else if (index === 1) {
      onUpdate(shape.id, newPoints, shape.from, snappedShapeId);
    }else{
      onUpdate(shape.id, newPoints, shape.from, shape.to);
    }

  };
  const handleDragMove = (index) => {
    const anchor = index === 0 ? anchorOneRef.current : anchorTwoRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  };
  const handleDragMoveMiddleAnchor = (index)=>{
    const anchor = middleAnchorRef.current;
    const x = anchor.x();
    const y = anchor.y();

    // Update arrow points based on dragged anchor
    updateArrow(index, x, y);
  }

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable
      id={shape.id}
      onClick={handleClick}
      onDragStart={handleStartDrag}
      onDragMove={onDragMove}
      onDragEnd={handleDragEnd}
    >
      <Line
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        dash={[10, 5]}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(0)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMove(1)}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable
        onDragMove={() => handleDragMoveMiddleAnchor(2)}
      />}
    </Group>
  )
}

function SubProcess({ shape, i, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, isResizing, selectedShape, setSelectedShape, setDraggedArrowsToHide, openBar, handleRightClick }){
  const [isDraggingShape, setIsDraggingShape] = useState(false);
  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);
  const [initialPositions, setInitialPositions] = useState({});

  useEffect(() => {
    const svgString = renderToStaticMarkup( <FiPlusSquare size={20} color='#2EBED6'/>);
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svg);

    setIconURL(blobURL);
  }, []);

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }

    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            return { ...s, points: updatedPoints };
          } else {
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }
  };

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      if(snappedX > newPoints[2] ){
        newPoints[0] = snappedX ;
      }else{
        newPoints[0] = snappedX + (shape.width);
      }
      newPoints[1] = snappedY + (shape.height / 2);
      newPoints[3] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX > newPoints[4] ){
        newPoints[6] = snappedX ;
      }else{
        newPoints[6] = snappedX + (shape.width);
      }
      newPoints[7] = snappedY + (shape.height / 2);
      newPoints[5] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
    setDraggedArrowsToHide({from: "", to: ""});
  }

  return (
    <Group
      key={i}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable
      onClick={(e) => setSelectedId(e, shape.id)}
      onDragStart={(e) => {
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsDraggingShape(true);
        setIsShapeDragging(true);
        setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => {
        setIsSelecting(false);
        setIsDraggingShape(false);
        setIsShapeDragging(false);
        const updatedRectangles = shapes.slice();
        const updatedShape = {
          ...shape,
          x: e.target.x(),
          y: e.target.y(),
        };
        updatedShape.processId = checkIfInsideProcess(updatedShape, shapes);
        updatedShape.divideLineName = checkIfInsideDivideLine(updatedShape, shapes);
        updatedRectangles[i] = updatedShape;
        shapes.forEach((s) => {
          if (s.type === 'arrow' && s.linkedShapeId === updatedShape.id) {
          }
        });
        updateShapes(updatedShape, i);
        if(selectedIds.length > 1){
          setDraggedArrowsToHide({from: "", to: ""});
        }else{
          updateArrowAtEnd(e);
        }
        if (selectedShape?.id === shape.id) {
          setSelectedShape(updatedShape);
        }
      }}
      onDblClick={()=> openBar("properties") }
    >
      <Rect
        id={shape.id}
        x={0}
        y={0}
        width={shape.width}
        height={shape.height}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        // cornerRadius={shape.cornerRadius}
        cornerRadius={3}
        fill={shape.bgColor}
        rotation={shape.rotation}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      />
      {iconImage && (
        !isDraggingShape ?
        <KonvaImage
          image={iconImage}
          x={(shape.width/2) - (iconImage.width/2)}
          y={shape.height - iconImage.height + 2}
          width={20}
          height={20}
          listening={false}
        />
        : null
      )}
    </Group>
  );
}

function TextAnnotation({shape, index, selectedIds, setSelectedId, setIsSelecting, shapes, setShapes, updateShapes, setIsShapeDragging, setSelectedShape, setDraggedArrowsToHide, openBar, deselectAll, handleRightClick}){

  const [isEditing, setIsEditing] = useState(false);
  const [initialPositions, setInitialPositions] = useState({});

  const onDragMove = (e) => {
    let idFrom = shapes.find(obj => obj.from === shape.id);
    let idTo = shapes.find(obj => obj.to === shape.id);
    setDraggedArrowsToHide({from: idFrom?.id, to: idTo?.id});

    if(!Object.keys(initialPositions).includes(shape.id)){
      setSelectedId(e, shape.id)
      return null;
    }

    if (selectedIds.length > 1) {
      const deltaX = e.target.x() - initialPositions[shape.id].x;
      const deltaY = e.target.y() - initialPositions[shape.id].y;

      const updatedShapes = shapes.map((s) => {
        if (selectedIds.includes(s.id)) {
          if (s.type === "arrow") {
            const updatedPoints = s.points.map((point, index) => {
              return index % 2 === 0 ? point + deltaX : point + deltaY;
            });
            return { ...s, points: updatedPoints };
          } else {
            return {
              ...s,
              x: initialPositions[s.id].x + deltaX,
              y: initialPositions[s.id].y + deltaY,
            };
          }
        }
        return s;
      });

      shapes.forEach((shape, index) => {
        if (selectedIds.includes(shape.id)) {
          if(shape.type === "arrow"){
            initialPositions[shape.id].points = updatedShapes[index].points;
          }else{
            initialPositions[shape.id].x = updatedShapes[index].x;
            initialPositions[shape.id].y = updatedShapes[index].y;
          }
        }
      });
      setShapes(updatedShapes);
    }
  };

  const updateArrowAtEnd = (e)=>{
    const node = e.target;
    const snappedX = node.x();
    const snappedY = node.y();
    const updatedShapes = shapes.slice();
    let indexFrom = shapes.findIndex(obj => obj.from === shape.id);
    let indexTo = shapes.findIndex(obj => obj.to === shape.id);

    if( indexFrom !== -1){
      const newPoints = [...shapes[indexFrom].points];
      if(snappedX > newPoints[2]){
        newPoints[0] = snappedX ;
      }else{
        newPoints[0] = snappedX + (shape.width);
      }
      newPoints[1] = snappedY + (shape.height / 2);
      newPoints[3] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexFrom],
        points: newPoints,
      };
      updatedShapes[indexFrom] = updatedArrow;
      updateShapes(updatedArrow, indexFrom);
    }
    if(indexTo !== -1){
      const newPoints = [...shapes[indexTo].points];
      if(snappedX > newPoints[4]){
        newPoints[6] = snappedX ;
      }else{
        newPoints[6] = snappedX + (shape.width);
      }
      newPoints[7] = snappedY + (shape.height / 2);
      newPoints[5] = snappedY + (shape.height / 2);

      const updatedArrow = {
        ...updatedShapes[indexTo],
        points: newPoints,
      };
      updatedShapes[indexTo] = updatedArrow;
      updateShapes(updatedArrow, indexTo);
    }
    setDraggedArrowsToHide({from: "", to: ""});
  }

  const handleMouseEnterRect = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'move';
  };

  const handleMouseLeaveRect = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
  };

  const handleInputChange = (value) => {
    const selectedShape = shapes[index];
    const updatedSelectedShape = {
      ...selectedShape,
      description: value,
    };
    updateShapes(updatedSelectedShape, index);
  };

  useEffect(()=>{
    if(isEditing){
      deselectAll();
    }
  },[isEditing]);

  return(
    <Group
      key={shape.id}
      id={shape.id}
      width={shape.width}
      height={shape.height}
      x={shape.x}
      y={shape.y}
      draggable
      onClick={(e) => setSelectedId(e, shape.id)}
      onDragStart={(e) => {
        if (selectedIds.length > 1) {
          const initialPos = {};
          shapes.forEach((shape) => {
            if (selectedIds.includes(shape.id)) {
              if(shape.type === "arrow"){
                initialPos[shape.id] = {points: shape.points, type: shape.type};
              }else{
                initialPos[shape.id] = { x: shape.x, y: shape.y, type: shape.type };
              }
            }
          });
          setInitialPositions(initialPos);
        }
        setIsSelecting(false);
        setIsShapeDragging(true);
        setSelectedId(e, shape.id);
      }}
      onDragMove={onDragMove}
      onDragEnd={(e) => {
        setIsSelecting(false);
        setIsShapeDragging(false);
        const updatedRectangles = shapes.slice();
        const updatedShape = {
          ...shape,
          x: e.target.x(),
          y: e.target.y(),
        };
        updatedShape.processId = checkIfInsideProcess(updatedShape, shapes);
        updatedShape.divideLineName = checkIfInsideDivideLine(updatedShape, shapes);
        updatedRectangles[index] = updatedShape;
        shapes.forEach((s) => {
          if (s.type === 'arrow' && s.linkedShapeId === updatedShape.id) {
          }
        });
        updateShapes(updatedShape, index);
        if(selectedIds.length > 1){
          setDraggedArrowsToHide({from: "", to: ""});
        }else{
          updateArrowAtEnd(e);
        }
        setSelectedShape(updatedShape);
      }}
      onDblClick={()=> openBar("properties") }
    >
      <Rect
        x={0}
        y={0}
        width={shape.width}
        height={shape.height}
        fill={shape.bgColor}
        stroke="black"
        strokeWidth={0}
        onMouseEnter={handleMouseEnterRect}
        onMouseLeave={handleMouseLeaveRect}
        onContextMenu={(event) => handleRightClick(event, shape.id)}
      />

      {/* Left border */}
      <Rect
        x={0}
        y={0}
        width={shape.borderWidth}
        height={shape.height}
        fill="black"
      />

      {/* Top border, half length */}
      <Line
        points={[0, 0, 0 + shape.width / 3, 0]}
        stroke="black"
        strokeWidth={shape.borderWidth}
        lineCap="butt"
      />

      {/* Bottom border, half length */}
      <Line
        points={[0, 0 + shape.height, 0 + shape.width / 3, 0 + shape.height]}
        stroke="black"
        strokeWidth={shape.borderWidth}
        lineCap="butt"
      />
      <EditableTextKonvaV2
        x={7}
        y={7}
        text={shape?.description}
        onChange={handleInputChange}
        width={shape.width-14}
        height={shape.height-14}
        setIsEditing={setIsEditing}
        shape={shape}
      />
    </Group>
  )
}

function RightClickMenu({ xPos, yPos, showMenu, closeMenu, openBar, deleteShape }){
  const {t} = useTranslation();

  if (!showMenu) return null;

  const menuStyle = {
    position: 'absolute',
    top: `${yPos}px`,
    left: `${xPos}px`,
    zIndex: 1000
  };

  return (
    <div  style={menuStyle} className='py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
      <ul className='flex flex-col gap-2' >
        <li onClick={()=> deleteShape() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
          <FiTrash className='text-red-600' />
          <span>{t("GLOBAL.DELETE")}</span>
        </li>
        <li onClick={()=> openBar("properties") } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
          <FiInfo />
          <span>{t("GLOBAL.INFORMATIONS")}</span>
        </li>
      </ul>
    </div>
  );
};

export default function CanvaStage({shapes, setShapes, undoHistory, setUndoHistory, redoHistory, setRedoHistory, isRightSideBarFocused, openBar}) {

  // const stageRef = useRef(null);
  const transformerRef = useRef();
  const drawingShape = useRef(null);
  const mainContainerRef = useRef(null);
  const hiddenContainerRef = useRef(null);
  const { draggedShape, setDraggedShape, selectedShape, setSelectedShape, draggedShapeType, draggedShapeFamily } = useContext(DragContext);
  const {
    isFullLeftBar, isFullRightBar,
    stageScale, setStageScale,
    stageX, setStageX,
    stageY, setStageY,
    mode, setMode,
    stageRef, currDiagramId, currDiagramAccessType
  } = useContext(UserContext);

  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isShapeDragging, setIsShapeDragging] = useState(false);
  const [boardWidth, setBoardWidth] = useState("");
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  // const [shapes, setShapes] = useState([]);
  // const [mode, setMode] = useState("select");
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isDraggingGird, setIsDraggingGird] = useState(false);
  const [draggedArrowsToHide, setDraggedArrowsToHide] = useState({from: "", to: ""});

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});

  const [divideLineAdded, isDivideLineAdded] = useState(null);

  const [selectionRect, setSelectionRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  // const [selectedShape, setSelectedShape] = useState({ x: 0, y: 0, width: 0, height: 0, borderWidth: 1, cornerRadius: 0, rotation: 0, borderColor: '#000', bgColor: 'transparent', nbLines: 4 });

  const updateShapes = (newShapes) => {
    // Capture current state before the update
    setUndoHistory([...undoHistory, shapes]);
    // Clear the redo history on new action
    setRedoHistory([]);
    // Update shapes
    setShapes(newShapes);
  };

  const updateShapeAtIndex = (updatedShape, index) => {
    setUndoHistory([...undoHistory, shapes]);
    // Clear the redo history on new action
    setRedoHistory([]);
    // Update the specific shape at the index
    setShapes(prevShapes => {
      const newShapes = [...prevShapes];
      newShapes[index] = updatedShape;
      return newShapes;
    });
  };

  const handleDelete = () => {
    if(selectedIds.length > 1){
      const updatedShapes = shapes.filter(shape => !selectedIds.includes(shape.id));
      updateShapes(updatedShapes);
    }else{
      const selectedShape = shapes.filter(shape=> shape.id === selectedIds[0])[0];
      if(selectedShape.type === "divideLine" ){
        let updatedShapes = shapes.slice();
        const processIndex = updatedShapes.findIndex(s => s.id === selectedShape.processId);
        const updatedProcess = updatedShapes[processIndex];
        updatedProcess.divideLine -= 1;
        updateShapeAtIndex(updatedProcess, processIndex);
        updatedShapes = shapes.filter(shape => shape.id !== selectedShape.id );
        updateShapes(updatedShapes);
        isDivideLineAdded(new Date().getTime());
      }else{
        const updatedShapes = shapes.filter(shape => shape.id !== selectedIds[0]);
        updateShapes(updatedShapes);
      }
    }

    setSelectedIds([]);
    const container = stageRef.current.getStage().container();
    container.style.cursor = 'default';
  };

  const getDraggedShape = ()=>{

    if(draggedShapeFamily === "StartEvent"){
      switch (draggedShapeType) {
        case 'StartEvent':
          return <BpmnStartEvent width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'TimerEventDefinition':
          return <BpmnStartEventTimer width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'MessageEventDefinition':
          return <BpmnStartEventMessage width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'SignalEventDefinition':
          return <BpmnStartEventSignal width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'ConditionalEventDefinition':
          return <BpmnStartEventCondition width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'ParallelMultipleStartEvent':
          return <BpmnStartEventParallelMultiple width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
        case 'MultipleStartEvent':
          return <BpmnStartEventMultiple width="40" height="40" fill="#CCE0C6" stroke='#7BA23D' />
      }
    }else if(draggedShapeFamily?.includes("Task")){
      switch (draggedShapeType) {
        case 'Task':
          return <BpmnTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'UserTask':
          return <BpmnUserTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'ServiceTask':
          return <BpmnServiceTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'ReceiveTask':
          return <BpmnReceiveTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'SendTask':
          return <BpmnSendTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'ScriptTask':
          return <BpmnScriptTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'ManualTask':
          return <BpmnManualTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
        case 'BusinessRuleTask':
          return <BpmnBusinessRuleTask width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />
      }
    }else if(draggedShapeFamily?.includes("Gateway")){
      switch (draggedShapeType) {
        case 'ExclusiveGateway':
          return <BpmnGateway width="50" height="50" fill="#fef6d9" stroke='#F9BF00' />
        case 'InclusiveGateway':
          return <BpmnGatewayOr width="50" height="50" fill="#fef6d9" stroke='#F9BF00' />
        case 'ParallelGateway':
          return <BpmnGatewayParallel width="50" height="50" fill="#fef6d9" stroke='#F9BF00' />
        case 'EventBasedGateway':
          return <BpmnGatewayEventbased width="50" height="50" fill="#fef6d9" stroke='#F9BF00' />
        case 'ComplexGateway':
          return <BpmnGatewayComplex width="50" height="50" fill="#fef6d9" stroke='#F9BF00' />
      }
    }else if(draggedShapeFamily === "IntermediateCatchEvent"){
      switch (draggedShapeType) {
        case 'IntermediateCatchEvent':
          return <BpmnIntermediateEvent width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'TimerEventDefinition':
          return <BpmnIntermediateEventCatchTimer width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'MessageEventDefinition':
          return <BpmnIntermediateEventCatchMessage width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'SignalEventDefinition':
          return <BpmnIntermediateEventCatchSignal width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'LinkEventDefinition':
          return <BpmnIntermediateEventCatchLink width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'CompensateEventDefinition':
          return <BpmnIntermediateEventCatchCompensation width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'EscalationEventDefinition':
          return <BpmnIntermediateEventCatchEscalation width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'ConditionalEventDefinition':
          return <BpmnIntermediateEventCatchCondition width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'IntermediateParallelMultipleEvent':
          return <BpmnIntermediateEventCatchParallelMultiple width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
        case 'IntermediateMultipleEvent':
        return <BpmnIntermediateEventCatchMultiple width="40" height="40" fill="#F9BF00" stroke='#F9BF00' />;
      }
    }else if(draggedShapeFamily === "EndEvent"){
      switch (draggedShapeType) {
        case 'EndEvent':
          return <BpmnEndEvent width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'TerminateEventDefinition':
          return <BpmnEndEventTerminate width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'MessageEventDefinition':
          return <BpmnEndEventMessage width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'SignalEventDefinition':
          return <BpmnEndEventSignal width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'CompensateEventDefinition':
          return <BpmnEndEventCompensation width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'EscalationEventDefinition':
          return <BpmnEndEventEscalation width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'ErrorEventDefinition':
          return <BpmnEndEventError width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'CancelEventDefinition':
          return <BpmnEndEventCancel width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
        case 'MultipleEndEvent':
          return <BpmnEndEventMultiple width="40" height="40" fill="#D6323A" stroke='#FEEDB7' />;
      }
    }else{
      switch (draggedShapeType) {
        case "SequenceFlow":
          return <BpmnConnection width="40" height="40" />;
        case "Association":
          return <DashedLine width="40" height="40" />;
        case "MsgFlow":
          return <DashedConnectionLine width="40" height="40" />;
        case "process":
          return <BpmnParticipant width="200" height="200" />;
        case "SubProcess":
          return <BpmnSubprocessCollapsed width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />;
        case "CallActivity":
          return <BpmnSubprocessCollapsed width="90" height="90" fill="#C2E4EF" stroke='#2EBED6' />;
        case "divideLine":
          return <BpmnLaneDivideTwo width="38" height="68" />
        case "TextAnnotation":
          return <BpmnTextAnnotation width="38" height="68" />
        default:
          break;
      }
    }
  }

  const undo = () => {
    if (undoHistory.length > 0) {
      const previousState = undoHistory[undoHistory.length - 1];

      setRedoHistory(redoHistory => [...redoHistory, shapes]);
      setShapes(previousState);

      setUndoHistory(undoHistory => undoHistory.slice(0, -1));
    }
  };

  const redo = () => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory[redoHistory.length - 1];

      setUndoHistory(undoHistory => [...undoHistory, shapes]);
      setShapes(nextState);

      setRedoHistory(redoHistory => redoHistory.slice(0, -1));
    }
  };

  const handleShapeClick = (e, id) => {
    const isSelected = selectedIds.includes(id);
    if (e.evt?.shiftKey || e.evt?.ctrlKey) {
      setSelectedIds(isSelected ? selectedIds.filter(sid => sid !== id) : [...selectedIds, id]);
    } else {
      if(!isSelected){
        setSelectedIds([id]);
      }
    }
  };

  const deselectAll = ()=>{
    setSelectedIds([]);
  }

  const circleStyleByType = (type)=>{
    if(type.includes("End")){
      return {borderWidth: 4, borderColor: "#D6323A", bgColor: "#fbeaeb", name: "Fin"}
    }else if(type.includes("IntermediateCatchEvent")){
      return {borderWidth: 1, borderColor: "#F9BF00", bgColor: "#fffcf2", name: "Événement"}
    }else{
      return {borderWidth: 2, borderColor: "#7BA23D", bgColor: "#ebf3e8", name: "Start"}
    }
  }
  const onDrop = useCallback((event) => {

    const stage = stageRef.current.getStage();
    const rect = stage.container().getBoundingClientRect();

    // Adjust for the clientX and clientY based on the bounding rectangle
    let id = Math.random().toString(36).substr(2, 9);
    let x = event.clientX - rect.left+10;
    let y = event.clientY - rect.top +30;

    // Adjust for the current scale of the stage
    x = x / stage.scaleX();
    y = y / stage.scaleY();

    // Adjust for the current position (offset) of the stage
    x = x - stage.x() / stage.scaleX();
    y = y - stage.y() / stage.scaleY();

    if(draggedShape === 'arrow'){
      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        points: [x, y, x+50, y, x+50, y+50, x+100, y+50],
        from: "",
        to: "",
        processId: "",
        name: "Flux",
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {}
      };
    } else if(draggedShape === 'process'){
      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        x: x,
        y: y,
        width: 850,
        height: 500,
        points: undefined,
        radius: undefined,
        rotation: 0,
        cornerRadius: 10,
        borderWidth: 1,
        borderColor: '#000',
        bgColor: 'transparent',
        name: "Processus",
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {},
        divideLine: 1,
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        fontSize: 12,
        fontColor: "#000"
      };
    } else if(draggedShape === 'circle'){

      const circleStyle = circleStyleByType(draggedShapeFamily);

      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        family: draggedShapeFamily,
        x: x,
        y: y,
        width: 16,
        height: 16,
        points: undefined,
        radius: 16,
        rotation: 0,
        cornerRadius: 7,
        borderWidth: circleStyle.borderWidth,
        borderColor:  circleStyle.borderColor,
        bgColor:  circleStyle.bgColor,
        processId: "",
        name:  circleStyle.name,
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {},
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        fontSize: 12,
        fontColor: "#000"
      };
    } else if(draggedShape === 'square'){
      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        family: draggedShapeFamily,
        x: x,
        y: y,
        width: 30,
        height: 30,
        points: undefined,
        radius: undefined,
        rotation: 0,
        cornerRadius: 7,
        borderWidth: 2,
        borderColor:  "#F9BF00",
        bgColor:  "#fef6d9",
        processId: "",
        name: "Passerelle",
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {},
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        fontSize: 12,
        fontColor: "#000"
      };
    } else if(draggedShape === "divideLine"){
      drawingShape.current = {
        id,
        type: 'rectangle',
        x: x,
        y: y,
        width: 10,
        height: 10,
      };
      let processId = checkIfInsideProcess(drawingShape.current, shapes)
      if(processId){
        const updatedShapes = shapes.slice();
        const processIndex = updatedShapes.findIndex(s => s.id === processId);
        const updatedProcess = updatedShapes[processIndex];
        if(updatedProcess.divideLine <= 1){
          let divideLine_1 = {
            id: `divideLine_1_${id}`,
            type: draggedShape,
            shapeType: "Lane",
            family: "Lane",
            x: updatedProcess.x + 50,
            y: updatedProcess.y,
            width: updatedProcess.width - 50,
            height: updatedProcess.height/2,
            processId: processId,
            points: undefined,
            radius: undefined,
            rotation: 0,
            cornerRadius: 10,
            borderWidth: 1,
            borderColor: '#000',
            bgColor: '',
            name: "divide line",
            description: "",
            participants: {},
            evaluable: {},
            consulte: {},
            informe: {},
            fontWeight: "",
            fontStyle: "",
            textDecoration: "",
            fontSize: 12,
            fontColor: "#000"
          };
          let divideLine_2 = {
            id: `divideLine_2_${id}`,
            type: draggedShape,
            shapeType: "Lane",
            family: "Lane",
            x: updatedProcess.x + 50,
            y: updatedProcess.y + updatedProcess.height/2,
            width: updatedProcess.width - 50,
            height: updatedProcess.height/2,
            processId: processId,
            points: undefined,
            radius: undefined,
            rotation: 0,
            cornerRadius: 10,
            borderWidth: 1,
            borderColor: '#000',
            bgColor: '',
            name: "divide line",
            description: "",
            participants: {},
            evaluable: {},
            consulte: {},
            informe: {},
            fontWeight: "",
            fontStyle: "",
            textDecoration: "",
            fontSize: 12,
            fontColor: "#000"
          };
          // shapes.forEach((shape)=>{
          //   shape.type !== "process" && (shape.divideLineName = "divide line");
          // })
          updateShapes([...shapes, divideLine_1, divideLine_2]);
          drawingShape.current = null;
          setSelectedIds([]);
          setDraggedShape(null);
          updatedProcess.divideLine = 2;
          updateShapeAtIndex(updatedProcess, processIndex);
          isDivideLineAdded(new Date().getTime());
        }else{
          const updatedShapes = shapes.slice();
          const processIndex = updatedShapes.findIndex(s => s.id === processId);
          const updatedProcess = updatedShapes[processIndex];
          let divideLine_1 = {
            id: `divideLine_1_${id}`,
            type: draggedShape,
            shapeType: "Lane",
            family: "Lane",
            x: updatedProcess.x + 50,
            y: updatedProcess.y + updatedProcess.height,
            width: updatedProcess.width - 50,
            height: 200,
            processId: processId,
            points: undefined,
            radius: undefined,
            rotation: 0,
            cornerRadius: 10,
            borderWidth: 1,
            borderColor: '#000',
            bgColor: '',
            name: "divide line",
            description: "",
            participants: {},
            evaluable: {},
            consulte: {},
            informe: {},
            fontWeight: "",
            fontStyle: "",
            textDecoration: "",
            fontSize: 12,
            fontColor: "#000"
          };
          updateShapes([...shapes, divideLine_1]);
          drawingShape.current = null;
          setSelectedIds([]);
          setDraggedShape(null);
          updatedProcess.divideLine += 1;
          updatedProcess.height += 200;
          updateShapeAtIndex(updatedProcess, processIndex);
          isDivideLineAdded(new Date().getTime());
        }
      }
      return null ;
    } else if(draggedShape === "TextAnnotation"){
      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        family: draggedShapeType,
        x: x,
        y: y,
        width: 120,
        height: 65,
        points: undefined,
        radius: undefined,
        rotation: 0,
        cornerRadius: 10,
        borderWidth: 1,
        borderColor: '#000',
        bgColor: '#d3d3d3',
        name: "",
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {},
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        fontSize: 12,
        fontColor: "#000"
      };
    } else{
      drawingShape.current = {
        id,
        type: draggedShape,
        shapeType: draggedShapeType,
        family: draggedShapeFamily,
        x: x,
        y: y,
        width: 80,
        height: 50,
        points: undefined,
        radius: undefined,
        rotation: 0,
        cornerRadius: 7,
        borderWidth: 2,
        borderColor: '#2EBED6',
        bgColor: '#C2E4EF',
        processId: "",
        name: draggedShapeType === "Task" ? "Tâche" : "Sous-processus",
        description: "",
        participants: {},
        evaluable: {},
        consulte: {},
        informe: {},
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        fontSize: 12,
        fontColor: "#000"
      };
      draggedShapeType === "CallActivity" && (drawingShape.current.linkedProcessId = "");
    }

    drawingShape.current.processId = checkIfInsideProcess(drawingShape.current, shapes);
    drawingShape.current.divideLineName = checkIfInsideDivideLine(drawingShape.current, shapes);
    updateShapes([...shapes, drawingShape.current]);
    drawingShape.current = null;
    setSelectedIds([id]);
    setDraggedShape(null);
  }, [draggedShape, setDraggedShape]);

  const divideLines = shapes.filter(shape => shape.type === "divideLine");
  const divideLineDependency = JSON.stringify(divideLines);
  useEffect(() => {
    shapes.forEach((shape)=>{
      shape.divideLineName = checkIfInsideDivideLine(shape, shapes);
    })
  }, [divideLineDependency]);

  // useEffect(()=>{
  //   if(divideLineAdded){
  //     shapes.forEach((shape)=>{
  //       shape.divideLineName = checkIfInsideDivideLine(shape, shapes);
  //     })
  //   }
  // },[divideLineAdded])

  const calculateMenuPosition = () => {
    if (!stageRef.current || !Object.keys(selectedShape).length) {
      return { top: 0, left: 0 };
    }

    const stage = stageRef.current;
    const scale = stage.scaleX();
    const stagePosition = stage.position();
    let x, y;
    if(selectedShape.type === "circle"){
      x = (selectedShape.x + (selectedShape.radius) + 10) * scale + stagePosition.x;
      y = selectedShape.y * scale + stagePosition.y - (selectedShape.radius);
    }else{
      x = (selectedShape.x + selectedShape.width + 10) * scale + stagePosition.x;
      y = selectedShape.y * scale + stagePosition.y;
    }

    return { top: y, left: x };
  };
  const calculateTopMenuPosition = () => {
    if (!stageRef.current || !Object.keys(selectedShape).length) {
      return { top: 0, left: 0 };
    }

    const stage = stageRef.current;
    const scale = stage.scaleX();
    const stagePosition = stage.position();
    let x, y;
    x = (selectedShape.x + selectedShape.width*0.85) * scale + stagePosition.x;
    y = (selectedShape.y - 10) * scale + stagePosition.y;
    return { top: y, left: x };
  };

  const quickAction = (type)=>{
    const x = selectedShape.x + selectedShape.width;
    const y = selectedShape.y;
    let id = Math.random().toString(36).substr(2, 9);
    let shape = {};
    let circleStyle = {};
    switch (type) {
      case "task":
        shape = {
          id,
          type: 'rectangle',
          shapeType: 'Task',
          family: 'Task',
          x: x + 100,
          y: y,
          width: 80,
          height: 50,
          points: undefined,
          radius: undefined,
          rotation: 0,
          cornerRadius: 7,
          borderWidth: 2,
          borderColor: '#2EBED6',
          bgColor: '#C2E4EF',
          processId: "",
          name: "Tâche",
          description: "",
          participants: {},
          evaluable: {},
          consulte: {},
          informe: {},
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          fontSize: 12,
          fontColor: "#000"
        }
        break;
      case "gateway":
        shape = {
          id,
          type: "square",
          shapeType: "ExclusiveGateway",
          family: "ExclusiveGateway",
          x: x + 115,
          y: y,
          width: 30,
          height: 30,
          points: undefined,
          radius: undefined,
          rotation: 0,
          cornerRadius: 7,
          borderWidth: 2,
          borderColor:  "#F9BF00",
          bgColor:  "#fef6d9",
          processId: "",
          name: "Passerelle",
          description: "",
          participants: {},
          evaluable: {},
          consulte: {},
          informe: {},
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          fontSize: 12,
          fontColor: "#000"
        }
        break;
      case "intermediateEvent":
        circleStyle = circleStyleByType("IntermediateCatchEvent");
        shape = {
          id,
          type: "circle",
          shapeType: "IntermediateCatchEvent",
          family: "IntermediateCatchEvent",
          x: x + 115,
          y: y + 25,
          width: 16,
          height: 16,
          points: undefined,
          radius: 16,
          rotation: 0,
          cornerRadius: 7,
          borderWidth: circleStyle.borderWidth,
          borderColor:  circleStyle.borderColor,
          bgColor:  circleStyle.bgColor,
          processId: "",
          name:  circleStyle.name,
          description: "",
          participants: {},
          evaluable: {},
          consulte: {},
          informe: {},
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          fontSize: 12,
          fontColor: "#000"
        }
        break;
      case "endEvent":
        circleStyle = circleStyleByType("EndEvent");
        shape = {
          id,
          type: "circle",
          shapeType: "EndEvent",
          family: "EndEvent",
          x: x + 115,
          y: y + 25,
          width: 16,
          height: 16,
          points: undefined,
          radius: 16,
          rotation: 0,
          cornerRadius: 7,
          borderWidth: circleStyle.borderWidth,
          borderColor:  circleStyle.borderColor,
          bgColor:  circleStyle.bgColor,
          processId: "",
          name:  circleStyle.name,
          description: "",
          participants: {},
          evaluable: {},
          consulte: {},
          informe: {},
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          fontSize: 12,
          fontColor: "#000"
        }
        break;
      case "TextAnnotation":
        shape = {
          id,
          type: "TextAnnotation",
          shapeType: "TextAnnotation",
          family: "TextAnnotation",
          x: x-selectedShape.width,
          y: y-130,
          width: 120,
          height: 65,
          points: undefined,
          radius: undefined,
          rotation: 0,
          cornerRadius: 10,
          borderWidth: 1,
          borderColor: '#000',
          bgColor: '#d3d3d3',
          name: "",
          description: "",
          participants: {},
          evaluable: {},
          consulte: {},
          informe: {},
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          fontSize: 12,
          fontColor: "#000"
        }
        break;
      default:
        break;
    }

    const getArrowPointsByShape =()=>{
      switch (selectedShape.type) {
        case "rectangle":
          return [x, y+20, x+50, y+20, x+50, y+30, x+100, y+30];
        case "circle":
          return [x, y+4, x+50, y+4, x+50, y+30, x+100, y+30];
        case "square":
          return [x-8, y+20, x+50, y+20, x+50, y+30, x+100, y+30];
        default:
          return [x, y+20, x+50, y+20, x+50, y+30, x+100, y+30];
      }
    }

    const getArrowPointsByShapeForNote = ()=>{
      return [x-selectedShape.width, y+30, x-selectedShape.width*1.5, y+30, x-selectedShape.width*1.5, y-100, x-selectedShape.width, y-100]
    }

    shape.processId = checkIfInsideProcess(shape, shapes);
    shape.divideLineName = checkIfInsideDivideLine(shape, shapes);
    let arrow = {
      id: Math.random().toString(36).substr(2, 9),
      type: "arrow",
      shapeType: type === "TextAnnotation" ? "Association" :  "SequenceFlow",
      points: type === "TextAnnotation" ? getArrowPointsByShapeForNote() : getArrowPointsByShape(),
      from: selectedShape.id,
      to: id,
      processId: "",
      name: "Flux",
      description: "",
      participants: {},
      evaluable: {},
      consulte: {},
      informe: {}
    };
    arrow.processId = checkIfInsideProcess(arrow, shapes);
    arrow.divideLineName = checkIfInsideDivideLine(arrow, shapes);
    updateShapes([...shapes, shape, arrow]);
  }

  const handleStageDragMove = (e) => {
    if (!isDraggingGird) {
      const stage = e.target.getStage();
      setStageX(stage.x());
      setStageY(stage.y());
    }
  };

  const handleStageDragEnd = (e) => {
    if (!isDraggingGird) {
      const stage = e.target.getStage();
      setStageX(stage.x());
      setStageY(stage.y());
    }
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if(newScale > 0.24 && newScale < 4 ){
      setStageScale(newScale);
      setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
      setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
    }
  };

  const handleMouseDown = (e) => {
    if(mode === 'panning' || isResizing) return;
    if (mode === 'select') {
      if (e.target === e.target.getStage()) {
        setSelectedIds([]);
      }
      setIsSelecting(true);
      const pos = getCursorPosition(e);
      setSelectionRect({ x: pos.x, y: pos.y, width: 0, height: 0 });
    }
  };

  const handleMouseMove = (e) => {
    if(mode === 'panning' || isResizing) return;
    if(mode === 'select' && isSelecting ){
      const point = getCursorPosition(e);
      const newSelectionRect = {
        x: selectionRect.x,
        y: selectionRect.y,
        width: point.x - selectionRect.x,
        height: point.y - selectionRect.y,
      };
      setSelectionRect(newSelectionRect);
    }
  };

  const handleMouseUp = () => {
    if(mode === 'panning') return;
    if (mode === 'select' && isSelecting) {
      setIsSelecting(false);
      const normalizedSelectionRect = {
        x: Math.min(selectionRect.x, selectionRect.x + selectionRect.width),
        y: Math.min(selectionRect.y, selectionRect.y + selectionRect.height),
        width: Math.abs(selectionRect.width),
        height: Math.abs(selectionRect.height),
      };
      selectShapes(normalizedSelectionRect);
      setSelectionRect({ x: 0, y: 0, width: 0, height: 0 });
      setSelectedShape({});
    }
  };
  const isOverlapping = (rect, shape) => {
    return !(rect.x > shape.x + shape.width ||
             rect.x + rect.width < shape.x ||
             rect.y > shape.y + shape.height ||
             rect.y + rect.height < shape.y);
  };
  const isLineOverlapping = (rect, line) => {
    const [x1, y1, x2, y2] = line.points;

    const isEndPointInside = (x, y) => {
      return x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height;
    };

    if (isEndPointInside(x1, y1) || isEndPointInside(x2, y2)) {
      return true;
    }
    return false;
  };

  const selectShapes = (selectionRect) => {
    const newSelectedIds = shapes.filter(shape => {
      let isShapeOverlapping;
      if(shape.type === 'process' || shape.type === 'divideLine' ) return;
      if (shape.type === 'arrow') {
        isShapeOverlapping = isLineOverlapping(selectionRect, shape);
      } else {
        isShapeOverlapping = isOverlapping(selectionRect, shape);
      }

      return isShapeOverlapping;
    }).map(shape => shape.id);
    setSelectedIds(newSelectedIds);
    setShapes([...shapes]);
  };

  const handleResizeStart = (e) => {
    setIsResizing(true);
  };

  const handleResizeEnd = (e) => {
    setIsResizing(false);

    const shape = e.target;
    const scaleX = shape.scaleX();
    const scaleY = shape.scaleY();

    const snappedX = shape.x();
    const snappedY = shape.y();

    const updatedShapes = shapes.map((s) => {
      if (selectedIds.includes(s.id)) {
        if (s.type === 'rectangle') {
          const newWidth = shape.width() * scaleX;
          const newHeight = shape.height() * scaleY;

          const snappedWidth = newWidth;
          const snappedHeight = newHeight;

          return {
            ...s,
            x: snappedX,
            y: snappedY,
            width: snappedWidth,
            height: snappedHeight,
            rotation: shape.rotation()
          };
        } else if (s.type === 'circle') {
          const newDiameter = shape.width() * scaleX;
          const snappedDiameter = newDiameter;
          return {
            ...s,
            x: snappedX,
            y: snappedY,
            radius: snappedDiameter / 2
          };
        } else if(s.type === "TextAnnotation"){
          const newWidth = shape.width() * scaleX;
          const newHeight = shape.height() * scaleY;

          const snappedWidth = newWidth;
          const snappedHeight = newHeight;

          return {
            ...s,
            x: snappedX,
            y: snappedY,
            width: snappedWidth,
            height: snappedHeight,
          };
        }
      }
      return s;
    });

    updateShapes(updatedShapes);

    // Reset the scale of the shape
    shape.scaleX(1);
    shape.scaleY(1);
    shape.width(shape.width() * scaleX);
    shape.height(shape.height() * scaleY);
    shape.x(snappedX);
    shape.y(snappedY);
  };

  useEffect(() => {
    if (selectedIds.length > 0 && transformerRef.current) {
      const stage = transformerRef.current.getStage();

      const selectedShapes = selectedIds.length > 1
      // .map(id => shapes.find(shape => shape.id === id && shape.type !== 'arrow'))
        ?
          selectedIds.map(id => shapes.find(shape => shape.id === id && (shape.type !== 'process' ) ))
          .filter(shape => shape)
          .map(shape => stage.findOne(`#${shape.id}`)).filter(node => node !== undefined)
        :
          selectedIds.map(id => shapes.find(shape => shape.id === id && (shape.type !== 'arrow' && shape.type !== 'process' )))
          .filter(shape => shape)
          .map(shape => stage.findOne(`#${shape.id}`))

      transformerRef.current.nodes(selectedShapes);

      if (selectedIds.length > 1) {
        // Disable resizing for multiple selections
        transformerRef.current.enabledAnchors([]);
        transformerRef.current.rotateEnabled(false);
      } else {
        const getSelectedShape = shapes.find(shape => selectedIds.includes(shape.id) );
        // Enable resizing for a single selection
        if(getSelectedShape && getSelectedShape.type === "rectangle" ){
          transformerRef.current.enabledAnchors([
            'top-left', 'top-center', 'top-right',
            'middle-left', 'middle-right',
            'bottom-left', 'bottom-center', 'bottom-right'
          ]);
        } else if(getSelectedShape && getSelectedShape.type === "TextAnnotation"){
          transformerRef.current.enabledAnchors([
            'top-left', 'top-center', 'top-right',
            'middle-left', 'middle-right',
            'bottom-left', 'bottom-center', 'bottom-right'
          ]);
        } else if(getSelectedShape && getSelectedShape.type === "divideLine"){
          // transformerRef.current.enabledAnchors([
          //   'top-center', 'bottom-center'
          // ]);
          transformerRef.current.enabledAnchors([]);
          transformerRef.current.rotateEnabled(false);
        }
        else{
          transformerRef.current.enabledAnchors([
            'top-left', 'top-right',
            'bottom-left', 'bottom-right'
          ]);
        }
        transformerRef.current.rotateEnabled(false);
      }

      transformerRef.current.on('transformstart', handleResizeStart);
      transformerRef.current.on('transformend', handleResizeEnd);
      transformerRef.current.on('transform', (e) => {
        const shape = e.target;
        const width = shape.width() * shape.scaleX();
        const getSelectedShape = shapes.find(sh => sh.id === shape.attrs.id );
        if(getSelectedShape?.type === "circle"){
          if (width > 50 ) {
            const newScale = 50 / shape.width();
            shape.scaleX(newScale);

            transformerRef.current.stopTransform();
          }
        } else if(getSelectedShape?.type === "TextAnnotation"){
          if (width > 250 ) {
            const newScale = 250 / shape.width();
            shape.scaleX(newScale);

            transformerRef.current.stopTransform();
          }else if(width < 100){
            const newScale = 100 / shape.width();
            shape.scaleX(newScale);

            transformerRef.current.stopTransform();
          }
        } else{
          if (width > 250 ) {
            const newScale = 250 / shape.width();
            shape.scaleX(newScale);

            transformerRef.current.stopTransform();
          }else if(width < 50){
            const newScale = 50 / shape.width();
            shape.scaleX(newScale);

            transformerRef.current.stopTransform();
          }
        }
    });
    } else if (transformerRef.current) {
      transformerRef.current.nodes([]);
      transformerRef.current.off('transformstart', handleResizeStart);
      transformerRef.current.off('transformend', handleResizeEnd);
    }
  }, [selectedIds, shapes]);

  // const handleResize = () => {
  //   setDimensions({
  //     width: mainContainerRef.current.offsetWidth,
  //     height: mainContainerRef.current.offsetHeight,
  //   });
  //
  //   setTimeout(() => {
  //     mainContainerRef.current && console.log(mainContainerRef.current.offsetWidth);
  //     setDimensions({
  //       width: mainContainerRef?.current === null ? window.innerWidth*0.7 : mainContainerRef.current.offsetWidth,
  //       height: mainContainerRef.current === null ? window.innerHeight*0.7 : mainContainerRef.current.offsetHeight,
  //     });
  //   }, 300);
  // };

  // useEffect(() => {
  //   if(boardWidth.length > 0){
  //     switch(boardWidth){
  //       case "w-[calc(100%_-_16rem)]":
  //         setDimensions({
  //           width: mainContainerRef?.current === null ? window.innerWidth*0.7 : mainContainerRef.current.offsetWidth,
  //           height: mainContainerRef.current === null ? window.innerHeight*0.7 : mainContainerRef.current.offsetHeight,
  //         });
  //     }
  //
  //     if(currDiagramAccessType === "READ"){
  //       if (isFullRightBar){
  //         return 'w-[calc(100%_-_16rem)]';
  //       }else{
  //         return 'w-[calc(100%_-_1.5rem)]';
  //       }
  //     }
  //     if(isFullLeftBar && isFullRightBar){
  //       return 'w-[calc(100%_-_24rem)]';
  //     }else if (isFullLeftBar){
  //       return 'w-[calc(100%_-_9.5rem)]';
  //     }else if (isFullRightBar){
  //       return 'w-[calc(100%_-_20rem)]';
  //     }else{
  //       return 'w-[calc(100%_-_5.5rem)]';
  //     }
  //   }
  // }, [boardWidth]);

  //////////////////////////////////////////////////////////////////////////////////// here
  // useEffect(() => {
  //   if(mainContainerRef.current){
  //     console.log(mainContainerRef.current.offsetWidth);
  //     setDimensions({
  //       width: mainContainerRef.current.offsetWidth,
  //       height: mainContainerRef.current.offsetHeight,
  //     });
  //   }
  // }, [mainContainerRef.current]);

  //////////////////////////////////////////////////////////////////////////////////// here

  // useEffect(() => {
  //   if(boardWidth.length > 0 && mainContainerRef.current){
  //     handleResize();
  //     window.addEventListener('resize', handleResize);
  //     return () => window.removeEventListener('resize', handleResize);
  //   }
  // }, [mainContainerRef, boardWidth]);

  const handleKeyDown = (event) => {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.tagName === 'SELECT') {
      return;
    }
    if (event.key === 'Delete' || event.key === 'Backspace' ) {
      if(!isRightSideBarFocused){
        handleDelete();
      }
    }
    else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault();
      undo();
    }
    else if ((event.ctrlKey && event.key === 'y') || (event.metaKey && event.shiftKey && event.key === 'Z')) {
      event.preventDefault();
      redo();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIds, undoHistory, redoHistory, isRightSideBarFocused]);

  const getBoardWidth = ()=>{
    if(currDiagramAccessType === "READ"){
      if (isFullRightBar){
        return 'w-[calc(100%_-_16rem)]';
      }else{
        return 'w-[calc(100%_-_1.5rem)]';
      }
    }
    if(isFullLeftBar && isFullRightBar){
      return 'w-[calc(100%_-_24rem)]';
    }else if (isFullLeftBar){
      return 'w-[calc(100%_-_9.5rem)]';
    }else if (isFullRightBar){
      return 'w-[calc(100%_-_20rem)]';
    }else{
      return 'w-[calc(100%_-_5.5rem)]';
    }
  }
  useEffect(()=>{
    setBoardWidth(getBoardWidth());
  },[isFullLeftBar, isFullRightBar]);

  useEffect(()=>{
    if(mode === 'panning'){
      setIsDraggingGird(true);
    }else{
      setIsDraggingGird(false);
    }
  },[mode]);

  useEffect(()=>{
    if(selectedIds.length === 1 ){
      const getSelectedShape = shapes.find(shape => selectedIds.includes(shape.id) );
      setSelectedShape(getSelectedShape);
    }else{
      setSelectedShape({});
    }
  },[selectedIds]);

  useEffect(() => {
    const handleUndo = (state) => {
      if(state){
        undo();
      }
    };
    const handleRedo = (state) => {
      if(state){
        redo();
      }
    };

    undoEvent.on('undo', handleUndo);
    redoEvent.on('redo', handleRedo);

    return () => {
      undoEvent.removeListener('undo', handleUndo);
      redoEvent.removeListener('redo', handleRedo);
    };
  }, [undoEvent, redoEvent, undoHistory, redoHistory]);

  const getProjectInfo = ()=>{
    setIsFetchLoading(true)
    getProjectCurrVersion(currDiagramId)
    .then((res)=>{
      parseXML(res, setShapes);
    })
    .catch((err)=> console.log(err) )
    .finally(()=> setIsFetchLoading(false) )
  }
  useEffect(()=>{
    if(currDiagramId){
      getProjectInfo();
    }
  },[currDiagramId]);

  const handleRightClick = (event, id) => {
    event.evt.preventDefault();
    handleShapeClick(event, id);
    setIsSelecting(false);
    setMenuVisible(true);
    setMenuPosition({ x: event.evt.pageX, y: event.evt.pageY });
    document.addEventListener('click', closeMenu);
  };

  const closeMenu = () => {
    setMenuVisible(false);
    document.removeEventListener('click', closeMenu);
  };

  if(isFetchLoading){
    return(
      <div className={`${boardWidth} h-[90vh] relative`}  >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${boardWidth} border-t bg-white select-none overflow-hidden`}
      ref={mainContainerRef}
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <RightClickMenu
        xPos={menuPosition.x}
        yPos={menuPosition.y}
        showMenu={menuVisible}
        closeMenu={closeMenu}
        openBar={openBar}
        deleteShape={handleDelete}
      />
      <div id="drag-image" className='absolute top-0 left-[-200px]' >
        {getDraggedShape()}
      </div>

       {/*<button onClick={()=>{ console.log(shapes)}} >teest</button>*/}

      <div className='relative' >
      { !isShapeDragging && Object.keys(selectedShape).length > 0 && (
          selectedShape.family === "StartEvent" ||
          selectedShape.family?.includes("Task") ||
          selectedShape.family === "SubProcess" ||
          selectedShape.family === "CallActivity" ||
          selectedShape.family?.includes("Gateway") ||
          selectedShape.family === "IntermediateCatchEvent"
        ) ?
        <>
          <div className='bg-white flex shadow absolute z-[5] translate-x-[-100%] translate-y-[-100%]' style={calculateTopMenuPosition()} >
            <div className='p-1 border-b cursor-pointer hover:bg-gray-100' onClick={()=> quickAction("TextAnnotation") } >
              <BpmnTextAnnotation width="18" height="18" />
            </div>
          </div>
          <div className='bg-white shadow absolute z-[5]' style={calculateMenuPosition()} >
            <div className='p-1 border-b cursor-pointer hover:bg-gray-100' onClick={()=> quickAction("task") } >
              <BpmnTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />
            </div>
            <div className='p-1 border-b cursor-pointer hover:bg-gray-100' onClick={()=> quickAction("gateway") } >
              <BpmnGateway width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />
            </div>
            <div className='p-1 border-b cursor-pointer hover:bg-gray-100' onClick={()=> quickAction("intermediateEvent") } >
              <BpmnIntermediateEvent width="18" height="18" fill="#F9BF00" stroke='#FEEDB7' />
            </div>
            <div className='p-1 border-b cursor-pointer hover:bg-gray-100' onClick={()=> quickAction("endEvent") } >
              <BpmnEndEvent width="18" height="18" fill="#D6323A" stroke='#FEEDB7' />
            </div>
          </div>
        </>
        : null
      }
      </div>

      <Stage
        ref={stageRef}
        width={dimensions.width}
        height={dimensions.height}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onWheel={handleWheel}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}
        draggable={isDraggingGird}
        onDragMove={handleStageDragMove}
        onDragEnd={handleStageDragEnd}
        className='z-0'
        style={{ cursor: isDraggingGird ? 'grab' : 'default', position: 'relative' }}
      >
        <Layer>
          {shapes?.map((shape, i) =>{
            switch (shape.type) {
              case 'process':
                return(
                  <Process
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    shapes={shapes}
                    setShapes={updateShapes}
                    updateShapeAtIndex={updateShapeAtIndex}
                    index={i}
                    setIsResizing={setIsResizing}
                    selectedIds={selectedIds}
                    setSelectedId={handleShapeClick}
                    isSelecting={isSelecting}
                    openBar={openBar}
                    handleRightClick={handleRightClick}
                  />
                )
            }
          })}
        </Layer>
        <Layer>
          {
            shapes?.map((shape, i) =>
            {
              if(!isShapeDragging){
                if(draggedArrowsToHide.from === shape.id){
                  return null;
                }
                if(draggedArrowsToHide.to === shape.id){
                  return null;
                }
              }
              switch (shape.type) {
                case 'arrow':
                  if(shape.shapeType.includes("Association")) {
                    return (
                      <React.Fragment key={i}>
                        <DashedCustomLine
                          id={shape.id}
                          key={shape.id}
                          shape={shape}
                          shapes={shapes}
                          selectedIds={selectedIds}
                          onUpdate={(id, newPoints, from, to) => {
                            const updatedShapes = shapes.slice();
                            const shapeIndex = updatedShapes.findIndex(s => s.id === id);
                            const updatedArrow = {
                              ...updatedShapes[shapeIndex],
                              points: newPoints,
                              from: from,
                              to: to
                            };
                            updatedArrow.processId = checkIfInsideProcess(updatedArrow, shapes);
                            updatedArrow.divideLineName = checkIfInsideDivideLine(updatedArrow, shapes);
                            updatedShapes[shapeIndex] = updatedArrow;
                            updateShapeAtIndex(updatedArrow, shapeIndex);
                          }}
                          onShapeClick={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                          onDragStart={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                          onDragEnd={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                        />
                      </React.Fragment>
                    );
                  }
                  else if(shape.shapeType.includes("Sequence")){
                    return (
                      <CustomArrowV2
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        shapes={shapes}
                        selectedIds={selectedIds}
                        onUpdate={(id, newPoints, from, to) => {
                          const updatedShapes = shapes.slice();
                          const shapeIndex = updatedShapes.findIndex(s => s.id === id);
                          const updatedArrow = {
                            ...updatedShapes[shapeIndex],
                            points: newPoints,
                            from: from,
                            to: to
                          };
                          updatedArrow.processId = checkIfInsideProcess(updatedArrow, shapes);
                          updatedArrow.divideLineName = checkIfInsideDivideLine(updatedArrow, shapes);
                          updatedShapes[shapeIndex] = updatedArrow;
                          updateShapeAtIndex(updatedArrow, shapeIndex);
                        }}
                        onShapeClick={(e, id) => {
                          handleShapeClick(e, id);
                        }}
                        onDragStart={(e, id) => {
                          handleShapeClick(e, id);
                        }}
                        onDragEnd={(e, id) => {
                          handleShapeClick(e, id);
                        }}
                      />
                    );
                  }else{
                    return (
                      <React.Fragment key={i}>
                        <DashedCustomArrow
                          id={shape.id}
                          key={shape.id}
                          shape={shape}
                          shapes={shapes}
                          selectedIds={selectedIds}
                          onUpdate={(id, newPoints, from, to) => {
                            const updatedShapes = shapes.slice();
                            const shapeIndex = updatedShapes.findIndex(s => s.id === id);
                            const updatedArrow = {
                              ...updatedShapes[shapeIndex],
                              points: newPoints,
                              from: from,
                              to: to
                            };
                            updatedArrow.processId = checkIfInsideProcess(updatedArrow, shapes);
                            updatedArrow.divideLineName = checkIfInsideDivideLine(updatedArrow, shapes);
                            updatedShapes[shapeIndex] = updatedArrow;
                            updateShapeAtIndex(updatedArrow, shapeIndex);
                          }}
                          onShapeClick={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                          onDragStart={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                          onDragEnd={(e, id) => {
                            handleShapeClick(e, id);
                          }}
                        />
                      </React.Fragment>
                    );
                  }
              }
            })
          }
        </Layer>
        <Layer>
          {shapes?.map((shape, i) =>{
            switch (shape.type) {
              case 'rectangle':
                if(shape.family.includes("Task")){
                  return(
                    <React.Fragment key={shape.id} >
                      <Rectangle
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        i={i}
                        selectedIds={selectedIds}
                        setSelectedId={handleShapeClick}
                        setIsSelecting={setIsSelecting}
                        shapes={shapes}
                        setShapes={setShapes}
                        updateShapes={updateShapeAtIndex}
                        stageScale={stageScale}
                        isResizing={isResizing}
                        selectedShape={selectedShape}
                        setSelectedShape={setSelectedShape}
                        setIsShapeDragging={setIsShapeDragging}
                        setDraggedArrowsToHide={setDraggedArrowsToHide}
                        openBar={openBar}
                        handleRightClick={handleRightClick}
                      />
                    </React.Fragment>
                  );
                }else{
                  return(
                    <React.Fragment key={shape.id} >
                      <SubProcess
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        i={i}
                        selectedIds={selectedIds}
                        setSelectedId={handleShapeClick}
                        setIsSelecting={setIsSelecting}
                        shapes={shapes}
                        setShapes={setShapes}
                        updateShapes={updateShapeAtIndex}
                        stageScale={stageScale}
                        isResizing={isResizing}
                        selectedShape={selectedShape}
                        setSelectedShape={setSelectedShape}
                        setIsShapeDragging={setIsShapeDragging}
                        setDraggedArrowsToHide={setDraggedArrowsToHide}
                        openBar={openBar}
                        handleRightClick={handleRightClick}
                      />
                    </React.Fragment>
                  );
                }
              case 'circle':
                if(shape.family.includes("Intermediate")){
                  return(
                    <IntermediateCircle
                      id={shape.id}
                      key={shape.id}
                      shape={shape}
                      selectedIds={selectedIds}
                      index={i}
                      setSelectedId={handleShapeClick}
                      setIsSelecting={setIsSelecting}
                      shapes={shapes}
                      setShapes={setShapes}
                      updateShapes={updateShapeAtIndex}
                      stageScale={stageScale}
                      setSelectedShape={setSelectedShape}
                      setIsShapeDragging={setIsShapeDragging}
                      setDraggedArrowsToHide={setDraggedArrowsToHide}
                      openBar={openBar}
                      handleRightClick={handleRightClick}
                    />
                  )
                }
                return (
                  <MyCircle
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    selectedIds={selectedIds}
                    index={i}
                    setSelectedId={handleShapeClick}
                    setIsSelecting={setIsSelecting}
                    shapes={shapes}
                    setShapes={setShapes}
                    updateShapes={updateShapeAtIndex}
                    stageScale={stageScale}
                    setSelectedShape={setSelectedShape}
                    setIsShapeDragging={setIsShapeDragging}
                    setDraggedArrowsToHide={setDraggedArrowsToHide}
                    openBar={openBar}
                    handleRightClick={handleRightClick}
                  />
                );
              case "square":
                return(
                  <Gateway
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    selectedIds={selectedIds}
                    index={i}
                    setSelectedId={handleShapeClick}
                    setIsSelecting={setIsSelecting}
                    shapes={shapes}
                    setShapes={setShapes}
                    updateShapes={updateShapeAtIndex}
                    stageScale={stageScale}
                    setSelectedShape={setSelectedShape}
                    setIsShapeDragging={setIsShapeDragging}
                    setDraggedArrowsToHide={setDraggedArrowsToHide}
                    openBar={openBar}
                    handleRightClick={handleRightClick}
                  />
                )
              case "TextAnnotation":
                return(
                  <TextAnnotation
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    selectedIds={selectedIds}
                    index={i}
                    setSelectedId={handleShapeClick}
                    setIsSelecting={setIsSelecting}
                    shapes={shapes}
                    setShapes={setShapes}
                    updateShapes={updateShapeAtIndex}
                    stageScale={stageScale}
                    setSelectedShape={setSelectedShape}
                    setIsShapeDragging={setIsShapeDragging}
                    setDraggedArrowsToHide={setDraggedArrowsToHide}
                    openBar={openBar}
                    deselectAll={deselectAll}
                    handleRightClick={handleRightClick}
                  />
                )
            }
          })}
          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              return newBox;
            }}
          />
          { !isResizing &&
            isSelecting ? (
              <Rect
                x={selectionRect.x}
                y={selectionRect.y}
                width={selectionRect.width}
                height={selectionRect.height}
                fill="rgba(0,0,255,0.3)"
              />
          ) : null}
        </Layer>

      </Stage>
    </div>
  )
}
