import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiChevronDown, FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
  FiEdit,
  FiHome,
  FiTrash, FiX,
  FiXOctagon
} from "react-icons/fi";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import Modal from "../Modal";
import {useForm} from "react-hook-form";
import {createUser, deleteUser, getAllRoles, getUserById, getUserByPage, updateUser} from "../../services/userService";
import {UserContext} from "../../Context/UserContext";
import {getAllTenantsPage, showTenant} from "../../services/tenantService";
import {motion} from "framer-motion";
import {deleteTenantUser} from "../../services/tenantUsers";
import moment from "moment/moment";

function AddUserModal(){

  const rolesInputRef = useRef(null);
  const clientsInputRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isClientListOpen, setIsClientListOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientInfo, setSelectedClientInfo] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientSearchInput, setClientSearchInput] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item)=>{
    if(selectedRoles.includes(item.id)){
      setSelectedRoles(selectedRoles.filter(it=> it !== item.id ));
      setSelectedRolesObj(selectedRolesObj.filter(it=> it !== item ));
    }else{
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const selectClient = (item)=>{
    setSelectedClient(item);
    console.log(item);
    setIsClientListOpen(false);
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: data.password,
      roles: selectedRoles,
      tenant: selectedClient.id
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    createUser(formData)
      .then((response)=> setUserCreated(true) )
      .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
      .finally(()=> setLoading(false) );

  }

  const fetchClients = () => {
    // setLoading(true);
    getAllTenantsPage(1, 10, clientSearchInput)
      .then((data)=>{ setClientsList(data.content) })
      .catch((err) => {console.log(err)})
      // .finally(()=> setLoading(false) )
  }

  useEffect(() => {
    if(Object.keys(selectedClient).length > 0){
      showTenant(selectedClient.id)
        .then((data)=>{ setSelectedClientInfo(data); })
        .catch((err)=>{ console.log(err)})
    }
  }, [selectedClient]);

  useEffect(()=>{
    fetchClients();
  },[clientSearchInput])

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllRoles()
      .then((data)=> setAllRoles(data) )
      .catch((err)=> console.log(err) )
  },[])

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
    if (clientsInputRef.current && !clientsInputRef.current.contains(event.target)) {
      setIsClientListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
        <div className={cx('grid gap-3 mb-3', {'grid-cols-2': selectedRoles.includes(1), 'grid-cols-3': !selectedRoles.includes(1) })}>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.FIRST_NAME")} *</label>
              {errors.firstName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
          { !selectedRoles.includes(1) &&
            <div className='flex flex-col mb-3 relative'>
              <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.CLIENT")} *</label>
                {(isUserSubmit && selectedRoles.length === 0) ?
                  <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
              </div>
              <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={clientsInputRef}>
                <div onClick={() => setIsClientListOpen(!isClientListOpen)}
                     className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
                  <div className='flex max-w-[95%] overflow-hidden'>
                  <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                    {
                      Object.keys(selectedClient).length === 0
                        ? <span className='text-gray-500'>{t("GLOBAL.CLIENT")} *</span>
                        : selectedClient.intitule
                    }
                  </span>
                  </div>
                  {
                    isClientListOpen
                      ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                     onClick={() => setIsClientListOpen(false)}/>
                      : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                       onClick={() => setIsClientListOpen(true)}/>
                  }
                </div>
                {
                  isClientListOpen &&
                  <div
                    className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                    <ul>
                      <li>
                        <input
                          type='text'
                          placeholder={t("GLOBAL.SEARCH")}
                          value={clientSearchInput}
                          onChange={(e) => setClientSearchInput(e.target.value)}
                          className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                        />
                      </li>
                      {
                        clientsList.map((it) =>
                          <li key={it.id} onClick={() => selectClient(it)}
                              className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedClient.id === it.id})}>
                            {it.intitule}
                          </li>
                        )
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col col-span-1'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm'>
              <label className="text-gray-500" >
                {Object.keys(selectedClientInfo).length > 0
                  ? selectedClientInfo.code + "_"
                  : "master_"
                }
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("GLOBAL.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.EMAIL")} *</label>
              {errors.email ?
              errors.email.type === "required"
                ? <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>
                : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.PASSWORD")} *</label>
            {errors.password &&
              <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("GLOBAL.PASSWORD")}
            // {...register("password", { required: true })}
            {...register("password", {
              required: "required",
              minLength: {
                value: 8,
                message: t("ERRORS.PASSWORD_MIN_LENGTH")
              },
              maxLength: {
                value: 16,
                message: t("ERRORS.PASSWORD_MIN_LENGTH")
              },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                message: t("ERRORS.PASSWORD_PATTERN")
              }
            })}
          />
          <div className='px-3.5'>
            <p
              className='text-xs text-gray-500'>{errors.password ? errors.password.message !== "required" && errors.password.message : null}</p>
          </div>
        </div>
        <div className='flex flex-col mb-3 relative'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.ROLE")} *</label>
            {(isUserSubmit && selectedRoles.length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef}>
            <div onClick={() => setIsRolesPickerOpen(!isRolesPickerOpen)}
                 className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                      ? <span className='text-gray-500'>{t("GLOBAL.ROLE")} *</span>
                      : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                  ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                 onClick={() => setIsRolesPickerOpen(false)}/>
                  : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                   onClick={() => setIsRolesPickerOpen(true)}/>
              }
            </div>
            {
              isRolesPickerOpen &&
              <div
                className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("GLOBAL.SEARCH")}
                      value={roleInput}
                      onChange={(e) => onTypeRole(e.target.value)}
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it) =>
                    {
                      if(it.id === 1){
                        return (
                          selectedRoles.some(id => id !== 1)
                            ?
                            <li key={it.id}
                                className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                              {it.name}
                            </li>
                            :
                            <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})}>
                              {it.name}
                            </li>
                        )
                      } else {
                        return (
                          selectedRoles.includes(1)
                            ?
                            <li key={it.id}
                                className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                              {it.name}
                            </li>
                            :
                            <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})}>
                              {it.name}
                            </li>
                        )
                      }
                    }
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='flex justify-between mt-6'>

          <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" checked={enabled}
                   onChange={() => setEnabled(!enabled)}/>
            <div
              className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span
              className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("HOME.USERS_VIEW.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative'>
                <div className='loader'/>
              </div>
              :
              <input
                onClick={() => setIsUserSubmit(true)}
                type="submit"
                value={t("GLOBAL.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
              />
          }
        </div>
      </form>
    </div>
  )
}

function UpdateUserModal({userData}) {

  const rolesInputRef = useRef(null);
  const clientsInputRef = useRef(null);
  const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm();
  const {t, i18n} = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [isClientListOpen, setIsClientListOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientInfo, setSelectedClientInfo] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientSearchInput, setClientSearchInput] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userUpdated, setUserUpdated] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item) => {
    if (selectedRoles.includes(item.id)) {
      setSelectedRoles(selectedRoles.filter(it => it !== item.id));
      setSelectedRolesObj(selectedRolesObj.filter(it => it.id !== item.id));
    } else {
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const selectClient = (item) => {
    setSelectedClient(item);
    setIsClientListOpen(false);
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userNewData = {
      id: userData.id,
      username: data.userName,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: isEditPassword ? data.password : null,
      roles: selectedRoles,
      tenant: selectedClient.id
    }

    console.log(userNewData);

    formData.append('userDto', new Blob([JSON.stringify(userNewData)], { type: 'application/json' }));

    updateUser(formData, userData.id)
      .then((response)=> setUserUpdated(true) )
      .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
      .finally(()=> setLoading(false) );
  }

  const fetchClients = () => {
    // setLoading(true);
    getAllTenantsPage(1, 10, clientSearchInput)
      .then((data)=>{ setClientsList(data.content) })
      .catch((err) => {console.log(err)})
    // .finally(()=> setLoading(false) )
  }

  useEffect(()=>{
    fetchClients();
  },[clientSearchInput])

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllRoles()
      .then((data)=> setAllRoles(data) )
      .catch((err)=> console.log(err) )
  },[]);

  useEffect(()=>{
    if(userInfo && Object.keys(userInfo).length > 0){
      setValue('lastName', userInfo.lastName);
      setValue('firstName', userInfo.firstName);
      setValue('email', userInfo.email);
      setValue('userName', userInfo.username.split('_')[1]);
      setSelectedRolesObj(userInfo.roles);
      setSelectedRoles(userInfo.roles.map(it=>it.id));
      setEnabled(userInfo.enabled);
      validateEmail(userInfo.email);
      userInfo.tenant && setSelectedClient(userInfo.tenant);
    }
  },[userInfo]);

  useEffect(() => {
    if(userData && Object.keys(userData).length > 0){
    getUserById(userData.id)
      .then((data)=> setUserInfo(data) )
      .catch((err)=> console.log(err) );
    }
  }, [userData]);

  useEffect(() => {
    if(Object.keys(selectedClient).length > 0){
      showTenant(selectedClient.id)
        .then((data)=>{ setSelectedClientInfo(data); })
        .catch((err)=>{ console.log(err)})
    }
  }, [selectedClient]);

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userUpdated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_UPDATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
        <div className={cx('grid gap-3 mb-3', {
          'grid-cols-2': selectedRoles.includes(1),
          'grid-cols-3': !selectedRoles.includes(1)
        })}>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.FIRST_NAME")} *</label>
              {errors.firstName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
          { !selectedRoles.includes(1) &&
            <div className='flex flex-col mb-3 relative'>
              <div className='px-2.5 mb-0.5 flex items-center justify-between'>
                <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.CLIENT")} *</label>
                {(isUserSubmit && selectedRoles.length === 0) ?
                  <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
              </div>
              <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={clientsInputRef}>
                <div
                  // onClick={() => setIsClientListOpen(!isClientListOpen)}
                  className={cx('border rounded-lg text-sm px-2 py-1.5 bg-gray-100 flex justify-between items-center')}>
                  <div className='flex max-w-[95%] overflow-hidden'>
                  <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                    {
                      Object.keys(selectedClient).length === 0
                        ? <span className='text-gray-500'>{t("GLOBAL.CLIENT")} *</span>
                        : selectedClient.intitule
                    }
                  </span>
                  </div>
                  {
                    isClientListOpen
                      ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                     onClick={() => setIsClientListOpen(false)}/>
                      : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                       onClick={() => setIsClientListOpen(true)}/>
                  }
                </div>
                {
                  isClientListOpen &&
                  <div
                    className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem] z-30'>
                    <ul>
                      <li>
                        <input
                          type='text'
                          placeholder={t("GLOBAL.SEARCH")}
                          value={clientSearchInput}
                          onChange={(e) => setClientSearchInput(e.target.value)}
                          className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                        />
                      </li>
                      {
                        clientsList.map((it) =>
                          <li key={it.id} onClick={() => selectClient(it)}
                              className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedClient.id === it.id})}>
                            {it.intitule}
                          </li>
                        )
                      }
                    </ul>
                  </div>
                }
              </div>
            </div>
          }
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col col-span-1'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm' >
              <label className="text-gray-500" >
                {Object.keys(selectedClientInfo).length > 0
                  ? selectedClientInfo.code+"_"
                  : "master_"
                }
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("GLOBAL.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.EMAIL")} *</label>
              {errors.email ?
              errors.email.type === "required"
                ? <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>
                : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
              : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3 relative z-10'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.ROLE")} *</label>
            {(isUserSubmit && selectedRoles.length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef}>
            <div onClick={() => setIsRolesPickerOpen(!isRolesPickerOpen)}
                 className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                      ? <span className='text-gray-500'>{t("GLOBAL.ROLE")} *</span>
                      : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                  ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                 onClick={() => setIsRolesPickerOpen(false)}/>
                  : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                   onClick={() => setIsRolesPickerOpen(true)}/>
              }
            </div>
            {
              isRolesPickerOpen &&
              <div
                className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("GLOBAL.SEARCH")}
                      value={roleInput}
                      onChange={(e) => onTypeRole(e.target.value)}
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it) => {
                        if (it.id === 1) {
                          return (
                            selectedRoles.some(id => id !== 1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        } else {
                          return (
                            selectedRoles.includes(1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        }
                      }
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='border p-2 rounded-lg'>
          <div className='flex items-center justify-between'>
            <p className='text-gray-500 text-sm font-semibold'>{t("GLOBAL.EDIT")} {t("GLOBAL.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={() => setIsEditPassword(!isEditPassword)}>
              <FiX
                className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})}/>
            </div>
          </div>
          <motion.div
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: {opacity: 1, height: 'auto', display: 'flex'},
              closed: {opacity: 0, height: 0, display: 'none'}
            }}
            transition={{duration: 0.5}}
            className={cx('flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label onClick={() => console.log(errors.password)}
                     className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.PASSWORD")}</label>
              {errors.password &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", {
                required: isEditPassword ? "required" : false,
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}
            />
            <div className='px-3.5'>
              <p
                className='text-xs text-gray-500'>{errors.password ? errors.password.message !== "required" && errors.password.message : null}</p>
            </div>
          </motion.div>
        </div>
        <div className='flex justify-between mt-6'>

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled}
                   onChange={() => setEnabled(!enabled)}/>
            <div
              className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span
              className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("HOME.USERS_VIEW.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative'>
                <div className='loader'/>
              </div>
              :
              <input
                onClick={() => setIsUserSubmit(true)}
                type="submit"
                value={t("GLOBAL.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
              />
          }
        </div>
      </form>
    </div>
  )
}

function DeleteUserModal({close, userId, getUsers}){

  const {t} = useTranslation();

  const submit = ()=>{
    deleteUser(userId)
      .then((res)=>{
        getUsers();
        close();
      })
      .catch((err)=> console.log(err) );
  }

  return(
    <div className='flex flex-col items-center' >
      <FiAlertCircle size={64} color='#D6323A' />
      <p className='mt-4 font-bold text-lg' >{t("HOME.USERS_VIEW.DELETE_MODAL.TITLE")}</p>

      <div className='w-full mt-6 flex items-center justify-between px-10' >
        <button onClick={()=> close() } className='hover:text-azure hover:border-azure flex items-center gap-2 px-2.5 py-1.5 rounded-lg border text-xs font-semibold text-gray-600' >
          {t("GLOBAL.CANCEL")}
        </button>
        <button onClick={()=> submit() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
          {t("GLOBAL.DELETE")}
        </button>
      </div>
    </div>
  )
}

function MasterUsers(props) {

  const {t} = useTranslation();
  const {userPrivileges} = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [editUserModalData, setEditUserModalData] = useState({});
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [deleteUserModalData, setDeleteUserModalData] = useState({});

  const openAddUserModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddUserModalOpen(true);
  }
  const closeAddUserModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddUserModalOpen(false);
    fetchUsers();
  }
  const openEditUserModal = (user) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditUserModalData(user);
    setIsEditUserModalOpen(true);
  }
  const closeEditUserModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditUserModalOpen(false);
    setEditUserModalData({});
    fetchUsers();
  }

  const openDeleteUserModal = (userId) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsDeleteUserModalOpen(true);
    setDeleteUserModalData(userId);
  }
  const closeDeleteUserModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsDeleteUserModalOpen(false);
    setDeleteUserModalData({});
  }

  const fetchUsers = () => {
    setLoading(true);
    getUserByPage(page, sizeOfPage, searchInput)
      .then((data) => {
        setUsers(data.content);
        setIsLastPage(data.last);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchUsers();
  }, [page, sizeOfPage, searchInput]);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("MASTER.NAV.USERS")}</h4>
      </div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl font-bold">{t("MASTER.USERS.TITLE")}</h1>
        <button onClick={() => openAddUserModal()}
                className="bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
          {t("MASTER.USERS.ADD_BTN")}
        </button>
      </div>
      <div className="bg-white p-0 rounded-lg customShadow">
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.LAST_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.FIRST_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.EMAIL")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.CREATED_AT")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.ROLE")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.CLIENT")}</th>
            <th/>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm ">
          {
            users.map((it) =>
              <tr key={it.id} className={cx('border', {'text-gray-400': !it.enabled})}>
                <td className="text-left py-3 px-2">{it.lastName}</td>
                <td className="text-left py-3 px-2">{it.firstName}</td>
                <td className="text-left py-3 px-2">{it.email}</td>
                <td className="text-left py-3 px-2">{moment(it.createdAt).calendar()}</td>
                <td className="text-left py-3 px-2">{it.roles.map(role => role.name).join(', ')}</td>
                <td onClick={()=> console.log(it) } className="text-left py-3 px-2">{it.tenant}</td>
                <td className='w-20'>
                  <div className='flex items-center gap-4'>
                    <FiEdit onClick={() => openEditUserModal(it)} size={16}
                            className='hover:text-azure cursor-pointer text-yellow-500'/>
                    <FiTrash onClick={() => openDeleteUserModal(it.id)} size={16}
                             className='hover:text-red-700 cursor-pointer text-red-600'/>
                  </div>
                </td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>
      <div className='mt-4 flex items-center justify-between'>
        <div className='flex gap-3 items-center'>
          <button onClick={() => page > 1 && setPage(page - 1)}
                  className='border rounded-full p-1 hover:text-azure hover:border-azure'>
            <FiChevronLeft size={16}/>
          </button>
          <span className='font-semibold text-sm'>{page}</span>
          <button onClick={() => !isLastPage && setPage(page + 1)}
                  className='border rounded-full p-1 hover:text-azure hover:border-azure'>
            <FiChevronRight size={16}/>
          </button>
        </div>
        <div className='flex items-center w-max gap-5'>
          <label className='w-max text-sm'>{t("GLOBAL.PAGINATION_TITLE")}</label>
          <select id="sizeOfPage" defaultValue={10} onChange={(e) => setSizeOfPage(e.target.value)}
                  className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>

      <Modal isOpen={isAddUserModalOpen} close={closeAddUserModal} title="Ajouter un Utilisateur">
        <AddUserModal close={closeAddUserModal}/>
      </Modal>
      <Modal isOpen={isEditUserModalOpen} close={closeEditUserModal} title="Modifier l'utilisateur">
        <UpdateUserModal close={closeEditUserModal} userData={editUserModalData} />
      </Modal>
      <Modal isOpen={isDeleteUserModalOpen} close={closeDeleteUserModal} title="Supprimer un Utilisateur" >
        <DeleteUserModal close={closeDeleteUserModal} userId={deleteUserModalData} getUsers={fetchUsers} />
      </Modal>
    </div>
  );
}

export default MasterUsers;
