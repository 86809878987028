import "../styles/loading.css";
import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { loginEvent } from '../Context/events';
import logo from '../assets/brome-logo.png';
import { login } from "../services/authService";

export default function LoginModal({close}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPassFocused, setIsPassFocused] = useState(false);
  const [isPassHidden, setIsPassHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [errorCode, setErrorCode] = useState("");

  const submitLogin = async ()=>{
    if(email.length === 0 || pass.length === 0 ){
      setErrorCode("ALL_REQUIRED");
      return null
    };
    setLoading(true);

    const data = {
      username: email,
      password: pass
    }

    login(data)
    .then((res)=>{
      localStorage.setItem('accessToken', res.accessToken);
      if (res.refreshToken) {
        localStorage.setItem('refreshToken', res.refreshToken);
      }
      close();
      loginEvent.emit("login", true);
    }).catch((err)=>{
      setErrorCode(err.response.data.message);
      console.error('Error signing in:', err);
      setLoading(false);
    });

  }

  return (
    <div className='fixed w-[100vw] h-[100vh] z-50' >
      <div className='bg-white customShadow absolute z-10 top-[50%] p-6 left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-lg' >
        {
          loading ?
          <div className='relative min-h-[40vh] min-w-[40vw] flex flex-col items-center justify-center' >
            <div className='loading' > <div className='box' /> </div>
          </div>
          :
          <div className='flex flex-col items-center justify-center relative' >
            <div className="flex items-center justify-between w-full mb-10" >
              <h1 className='font-semibold text-xl' >Se connecter</h1>
              <div className="h-8" >
                <img src={logo} className="w-auto h-full object-contain" alt='brome' />
              </div>
            </div>
            { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
            <div className={cx('flex flex-col w-80 relative transition-all duration-300 mb-2')} >
              {
                email.length > 0 ?
                <label className={cx('transition-all duration-300 absolute text-xs top-2 text-gray-600 z-30' )} >
                  {t("LOGIN.EMAIL")}
                </label>
                :
                <label className={cx('transition-all duration-300 absolute text-gray-600',{'text-xs top-2':(isEmailFocused), 'text-sm top-8': !isEmailFocused} )} >
                  {t("LOGIN.EMAIL")}
                </label>
              }
              <input
                type='text'
                className={cx('border-b py-2 pt-8 text-sm w-full relative z-20 bg-transparent transition-all duration-300', {'border-azure':isEmailFocused})}
                onFocus={()=> setIsEmailFocused(true) }
                onBlur={()=> setIsEmailFocused(false) }
                onChange={(e)=> setEmail(e.target.value) }
                value={email}
              />
            </div>
            <div className='flex flex-col w-80 relative' >
              {
                pass.length > 0 ?
                <label className={cx('transition-all duration-300 absolute text-xs top-2 text-gray-600 z-30' )} >
                  {t("GLOBAL.PASSWORD")}
                </label>
                :
                <label className={cx('transition-all duration-300 absolute text-gray-600',{'text-xs top-2':(isPassFocused), 'text-sm top-8': !isPassFocused} )} >
                  {t("GLOBAL.PASSWORD")}
                </label>
              }
              <input
                type={ isPassHidden ? "password" : "text"}
                className={cx('border-b py-2 pt-8 pr-10 text-sm w-full relative z-20 bg-transparent transition-all duration-300', {'border-azure':isPassFocused})}
                onFocus={()=> setIsPassFocused(true) }
                onBlur={()=> setIsPassFocused(false) }
                onChange={(e)=> setPass(e.target.value) }
                value={pass}
              />
              {
                isPassHidden
                ? <FiEye className='absolute top-8 right-2 z-30 cursor-pointer' onClick={()=> setIsPassHidden(false) } />
                : <FiEyeOff className='absolute top-8 right-2 z-30 cursor-pointer' onClick={()=> setIsPassHidden(true) } />
              }
            </div>
            <button onClick={()=> submitLogin() } className='bg-azure hover:bg-indigo text-white w-2/3 rounded-lg mt-14 py-2 font-semibold ' >
              {t("LOGIN.BUTTON")}
            </button>
          </div>
        }
      </div>
      <div className='bg-black opacity-20 fixed w-full h-full' />
    </div>
  )
}
