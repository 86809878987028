import React, { createContext, useState } from 'react';

export const DragContext = createContext();

export const DragProvider = ({ children }) => {
  const [draggedShape, setDraggedShape] = useState(null);
  const [draggedShapeType, setDraggedShapeType] = useState(null);
  const [draggedShapeFamily, setDraggedShapeFamily] = useState(null);
  const [selectedShape, setSelectedShape] = useState({});

  const values = { 
    draggedShape, 
    setDraggedShape, 
    selectedShape, 
    setSelectedShape,
    draggedShapeType,
    setDraggedShapeType,
    draggedShapeFamily,
    setDraggedShapeFamily
  } 

  return (
    <DragContext.Provider value={values}>
      {children}
    </DragContext.Provider>
  );
};