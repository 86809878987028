import React from 'react';
import { FiX } from 'react-icons/fi';

export default function FullModal({children, isOpen, close, title}) {
  if(isOpen){
    return (
      <div className='fixed w-full h-screen top-0 left-0 bg-transparent z-50' >
        <div
          className='absolute block w-[90%] h-[90%] top-[5%] left-[5%] bg-white z-10 p-4 rounded-xl flex-wrap'
        >
          {/*<div className='flex items-center justify-between mb-4' >*/}
          {/*  <p className='font-bold text-sm text-gray-500' >{title}</p>*/}
          {/*  <FiX onClick={()=> close() } size={20} className='cursor-pointer' />*/}
          {/*</div>*/}
          {children}
        </div>
        <div className='absolute z-0 top-0 bg-black/50 w-full h-screen' onClick={()=> close() } />
      </div>
    )
  }else{
    return null
  }
}
