import React, {useState, useEffect, useRef, useContext} from 'react';
import { FiGlobe, FiUser, FiLogOut } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { UserContext } from '../Context/UserContext';
import { loginEvent } from '../Context/events';

import logo from '../assets/logo.png';
import visioBpmLogo from '../assets/VISIO_BPM_logo.png';

const roles = [
  {id: 1, name: "test 1", privileges: [{code: "create"}, {code: "show"}, {code: "update"}] },
  {id: 2, name: "test 2", privileges: [{code: "create"}, {code: "show"}, {code: "update"}, {code: "delete"}] },
]

export default function Root() {

  const {pathname} = useLocation();
  const {t, i18n} = useTranslation();
  const accountMenuRef = useRef(null);
  const langMenuRef = useRef(null);
  const { userPrivileges, user } = useContext(UserContext);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);

  const logOut = ()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit('login', false);
  }

  const translate = async (lang)=>{
    await i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    setIsLangMenuOpen(false);
  }

  const handleClickOutside = (event) => {
    if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
      setIsAccountMenuOpen(false);
    }
    if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
      setIsLangMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='pt-32 px-12 pb-6' >
      <div className='bg-white absolute top-5 left-[2.5rem] w-[calc(100%_-_5rem)] border rounded-full py-2 px-8 flex items-center justify-between customShadow ' >
        {/* <h1 className='text-base font-bold text-sky-700' >BPM</h1> */}
        <div className='h-14 w-[8rem]' >
          <img className='w-full h-full object-contain' src={visioBpmLogo} alt='logo-bpm' />
        </div>
        <div className='absolute top-[25px] left-1/2 translate-x-[-50%]' >
          <ul className='flex items-center gap-10 font-semibold text-sm' >
            <li >
              <Link to="/" className='flex flex-col items-center cursor-pointer group' >
                {t("HOME.NAV.folders")}
                { pathname === '/'
                  ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
                  : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
                }
              </Link>
            </li>
            <li >
              <Link to="/diagrams/all" className='flex flex-col items-center cursor-pointer group' >
                {t("HOME.NAV.DIAGRAMS")}
                { pathname === '/diagrams/all'
                  ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
                  : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
                }
              </Link>
            </li>
            { userPrivileges.includes("client_users_page") &&
              <li >
                <Link to="/users" className='flex flex-col items-center cursor-pointer group' >
                  {t("HOME.NAV.USERS")}
                  { pathname === '/users'
                    ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
                    : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
                  }
                </Link>
              </li>
            }
          </ul>
        </div>
        <ul className='flex text-gray-600 gap-4 items-center' >
          <li className='relative' >
            <div onClick={()=> setIsAccountMenuOpen(true)} className='text-xs font-semibold flex items-center gap-1.5 border hover:border-gray-100 border-white rounded-lg px-1.5 py-0.5 cursor-pointer' >
              <FiUser size={16} />
              <span style={{lineHeight: '10px'}} >{user.firstName} {user.lastName}</span>
            </div>
            { isAccountMenuOpen &&
              <div ref={accountMenuRef} className='absolute z-20 py-2 w-max bottom-[-4px] translate-y-[100%] left-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
                <ul className='flex flex-col gap-2' >
                  <li  >
                    <Link onClick={()=> setIsAccountMenuOpen(false) } to="/account" className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                      <FiUser/>
                      <span>{t("GLOBAL.ACCOUNT")}</span>
                    </Link>
                  </li>
                  <li onClick={()=> logOut() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                    <FiLogOut/>
                    <span>{t("GLOBAL.LOGOUT")}</span>
                  </li>
                </ul>
              </div>
            }
          </li>
          <li className='cursor-pointer relative' >
            <button
              onClick={() => setIsLangMenuOpen(true)}
              className='text-xs font-semibold flex items-center gap-1.5 border hover:border-gray-100 border-white rounded-lg px-1.5 py-0.5'
            >
              <FiGlobe size={16} />
              <span style={{lineHeight: '10px'}} >{i18n.language}</span>
            </button>
            {isLangMenuOpen &&
              <div ref={langMenuRef}
                   className='absolute z-20 py-2 w-max min-w-[60px] bottom-[-4px] translate-y-[100%] right-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden'>
                <ul className='flex flex-col gap-2'>
                  <li
                    onClick={()=> translate("fr") }
                    className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                    <span>FR</span>
                  </li>
                  <li
                    onClick={()=> translate("en") }
                    className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                    <span>ENG</span>
                  </li>
                </ul>
              </div>
            }
          </li>
        </ul>
      </div>
      <Outlet/>
    </div>
  )
}
