import React, {useContext, useState, useEffect} from 'react';
import { FiMinus, FiPlus, FiZoomIn } from "react-icons/fi";
import { GrCursor } from "react-icons/gr";
import { FaRegHand } from "react-icons/fa6";

import { UserContext } from '../Context/UserContext';
import cx from 'classnames';
import { getProjectById, getProjectInfosById } from '../services/projectService';

export default function Footer() {

  const { stageScale, setStageScale, setStageX, setStageY, mode, setMode, diagram, setDiagram, currDiagramId } = useContext(UserContext);

  const [info, setInfo] = useState({});

  const resetZoom = ()=>{
    setStageScale(1);
    setStageX(0);
    setStageY(0);
  }

  const zoomIn = ()=>{
    if((stageScale * 100) <= 400){
      setStageScale(stageScale+0.01);
    }

  }
  const zoomOut = ()=>{
    if( (stageScale * 100) > 26 ){
      setStageScale(stageScale-0.01);
    }
  }

  const minScale = 0.25;
  const maxScale = 4;

  const handleSliderChange = (event) => {
    setStageScale(parseFloat(event.target.value));
  };

  const getProjectInfo = ()=>{
    getProjectInfosById(currDiagramId)
    .then((res)=>{
      setInfo(res);
    })
    .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    if(currDiagramId){
      getProjectInfo();
    }
  },[currDiagramId]);

  return (
    <div className='h-6 border-t flex justify-between items-center px-3 relative' style={{zIndex: 2}} >
      <div className='flex items-center' >
        <button onClick={()=> setDiagram("d-1") } className={cx('text-xs font-semibold mr-3 pr-3 border-r', {'text-azure': diagram === 'd-1', 'text-gray-500': diagram !== 'd-1'})} >
          {info && info.processusName}
        </button>
        {/* <button onClick={()=> setDiagram("d-2") } className={cx('text-xs font-semibold mr-3 pr-3 border-r', {'text-azure': diagram === 'd-2', 'text-gray-500': diagram !== 'd-2'})} >
          Diagramme 2
        </button>
        <button className='hover:bg-gray-300 h-6 px-2' >
          <FiPlus/>
        </button> */}
      </div>
      <div className='flex items-center' >
        <button className='hover:bg-gray-300 h-6 px-2' onClick={()=> setMode("select") } >
          <GrCursor className={cx({'text-azure':mode==='select'})} />
        </button>
        <button className='hover:bg-gray-300 h-6 px-2' onClick={()=> setMode("panning") } >
          <FaRegHand className={cx({'text-azure':mode==='panning'})} />
        </button>
        <p className='text-xs block p-0 m-0 font-semibold mx-2' >{(stageScale * 100).toFixed(0)}%</p>
        <button className='hover:bg-gray-300 h-6 px-2' onClick={()=> zoomOut() } >
          <FiMinus/>
        </button>
        <div className='relative h-3 flex items-center mx-2' >
          <input
            type="range"
            min={minScale}
            max={maxScale}
            value={stageScale}
            onChange={handleSliderChange}
            step="0.01"
            className='h-1.5 w-48 relative bg-gray-400 rounded-lg appearance-none cursor-pointer'
          />
        </div>
        <button className='hover:bg-gray-300 h-6 px-2' onClick={()=> zoomIn() } >
          <FiPlus/>
        </button>
        <button className='hover:bg-gray-300 h-6 px-2' onClick={()=> resetZoom() } >
          <FiZoomIn/>
        </button>
      </div>
    </div>
  )
}
