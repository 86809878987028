import React, {useState, useEffect, useRef, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight, FiEdit, FiFile, FiInfo, FiMoreHorizontal, FiPlus, FiSearch, FiShare, FiTrash } from 'react-icons/fi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Modal from './Modal';
import { EditProjectModal, FolderContainer, NewProjectModal } from './Folders';
import { UserContext } from '../Context/UserContext';
import { EditDiagramModalV2, InfoModal, NewDiagramModal, ShareModal, SmallDiagramContainer } from './MyDiagrams';
import { createFolder, folderContent, updateFolder } from '../services/folderService';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/fr';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteProject, getProjectById, getProjectInfosById } from '../services/projectService';
import { FaFile } from 'react-icons/fa6';
import { ReactComponent as BpmFileIcon } from '../assets/bpmFile.svg';

const colorList = ["", "#1CC0FF", "#FFA6BC", "#92B4FB", "#50E3C1", "#FE9A49", "#B9E886"];

const projectsDataTest = [
  {id: 1, name: "Project name test 1", updatedAt: "06/02/2024", color: "#1CC0FF", type: "folder"},
  {id: 2, name: "Project name test 2", updatedAt: "06/02/2024", color: "", type: "folder"},
  {id: 3, name: "File name test 3", updatedAt: "06/02/2024", color: "#FFA6BC", type: "file"},
  {id: 4, name: "Project name test 4", updatedAt: "06/02/2024", color: "#B9E886", type: "folder"},
];

function ProjectContainer({project, openEditFile, fetchFolders, openInfoModal, openShareModal}){

  const {setCurrDiagramId, setCurrDiagramAccessType} = useContext(UserContext);
  const {t} = useTranslation();

  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeColor = (newColor)=>{
    project.color = newColor;
    setIsMenuOpen(false);
  }

  const clickProject = ()=>{
    setCurrDiagramId(project.project);
    setCurrDiagramAccessType(project.accessType === null ? "OWNER" : project.accessType);
    navigate(`/board/${project.project}`);
  }

  const clickEdit = ()=>{
    openEditFile(project);
  }

  const removeFolder = ()=>{
    withReactContent(Swal).fire({
      title: t("HOME.FOLDERS_VIEW.DELETE_MODAL.TITLE"),
      text: t("HOME.FOLDERS_VIEW.DELETE_MODAL.TEXT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimez-le!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProject(project.project).then((res)=>{
          fetchFolders();
          Swal.fire({
            title: "Supprimé!",
            text: "Votre élément a été supprimé.",
            icon: "success"
          })
        }).catch((err)=> console.log(err) )
      }
    })
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div onClick={()=> clickProject() } className='flex flex-col justify-between border rounded-lg customShadow p-4 cursor-pointer relative bg-white' >
      <div className='flex items-start justify-between' >
        {/* {
          project?.color
          ? <FaFile size={42} className='mb-4' strokeWidth={1} color={project?.color} />
          : <FiFile size={44} className='mb-4' strokeWidth={1} />
        } */}
        <BpmFileIcon height={42} width={42} className='mb-4' />
        <FiMoreHorizontal onClick={(e)=>{e.stopPropagation(); setIsMenuOpen(!isMenuOpen) }} size={16} className='cursor-pointer hover:text-azure' />
      </div>
      <div>
        <h4 className='font-semibold mb-1' >{project.intitule}</h4>
        <p className='text-xs text-gray-500' >Dernière mise à jour le {moment(project?.updatedAt).locale('fr').format('ll')}</p>
      </div>
      { isMenuOpen &&
        <div onClick={(e)=>{e.stopPropagation(); }} ref={menuRef} className='absolute z-20 py-2 w-max max-w-[9rem] top-9 right-2 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            <li onClick={() => clickEdit()}
                className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
              <FiEdit className='text-yellow-500'/>
              <span>{t("GLOBAL.DESCRIPTION_SHEET")}</span>
            </li>
            <li onClick={() => removeFolder() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiTrash className='text-red-600' />
              <span>{t("GLOBAL.DELETE")}</span>
            </li>
            <li onClick={()=> openShareModal(project.project) } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
              <FiShare className='text-sky-500' />
              <span>{t("GLOBAL.SHARE")}</span>
            </li>
            {/*<li onClick={()=> openInfoModal(project.project) } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >*/}
            {/*  <FiInfo />*/}
            {/*  <span>{t("GLOBAL.INFORMATIONS")}</span>*/}
            {/*</li>*/}
            {/* <li className='px-2 py-1' >
              <div className='flex flex-wrap gap-3' >
                {colorList.map((it)=>
                  it.length === 0
                  ? <div onClick={(e)=>{changeColor("") }} key={it} className="h-4 w-4 rounded-full border border-black hover:border-azure" />
                  : <div onClick={(e)=>{changeColor(it) }} key={it} className="h-4 w-4 rounded-full border border-white hover:border-azure" style={{backgroundColor: it}} />
                )}
              </div>
            </li> */}
          </ul>
        </div>
      }
    </div>
  )
}

export default function Folder() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {idFolder} = useParams();
  const { setShapes } = useContext(UserContext);

  const [viewType, setViewType] = useState("grid");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [isEditFolderModalOpen, setIsEditFolderModalOpen] = useState(false);
  const [editFolderModalData, setEditFolderModalData] = useState({});
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareModalData, setShareModalData] = useState("");

  const [isNewDiagramModalOpen, setIsNewDiagramModalOpen] = useState(false);
  const [isEditDiagramModalOpen, setIsEditDiagramModalOpen] = useState(false);
  const [editDiagramModalData, setEditDiagramModalData] = useState({});

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalData, setInfoModalData] = useState({});

  const [folderData, setFolderData] = useState({});

  const openNewFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewFolderModalOpen(true);
  }
  const closeNewFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewFolderModalOpen(false);
    fetchFolderData();
  }

  const openEditFolderModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditFolderModalData(data);
    setIsEditFolderModalOpen(true);
  }
  const closeEditFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditFolderModalOpen(false);
    setEditFolderModalData({});
    fetchFolderData();
  }

  const openNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewDiagramModalOpen(true);
  }
  const closeNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewDiagramModalOpen(false);
  }

  const openEditDiagramModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setEditDiagramModalData(data);
    setIsEditDiagramModalOpen(true);
  }
  const closeEditDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditDiagramModalOpen(false);
    setEditDiagramModalData({});
  }

  const openInfoModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setInfoModalData(data);
    setIsInfoModalOpen(true);
  }
  const closeInfoModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsInfoModalOpen(false);
    setInfoModalData({});
  }

  const openShareModal = (id)=>{
    setShareModalData(id);
    setIsShareModalOpen(true);
  }
  const closeShareModal = ()=>{
    setIsShareModalOpen(false);
    setShareModalData("");
  }

  const fetchFolderData = ()=>{
    folderContent(idFolder)
    .then((res)=>{
      setFolderData(res);
    })
    .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    fetchFolderData();
  },[location]);

  return (
    <div >
      <div className='flex items-center justify-between mb-8' >
        <div className='flex items-center gap-3' >
          <button onClick={()=> navigate(-1) } className='group p-2 border rounded-full hover:border-azure' >
            <FiChevronLeft size={16} className='text-gray-500 group-hover:text-azure' />
          </button>
          <h1 className='text-xl font-bold ' >{folderData?.folderName}</h1>
        </div>
        <div className='flex gap-2' >
          <div className='px-2.5 py-1.5 rounded-lg border text-sm flex items-center gap-2 bg-white' >
            <FiSearch size={16} className='text-gray-500' />
            <input
              type='text'
              placeholder={t("GLOBAL.SEARCH")}
              className='bg-transparent'
              value={search}
              onChange={(e)=> setSearch(e.target.value) }
            />
          </div>
          <button onClick={()=> openNewDiagramModal() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
            <FiPlus size={16} strokeWidth={2.5} />
            {t("HOME.FOLDERS_VIEW.NEW_PROCESS_BTN")}
          </button>
          <button onClick={()=> openNewFolderModal() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
            <FiPlus size={16} strokeWidth={2.5} />
            {t("HOME.FOLDERS_VIEW.NEW_FOLDER_BTN")}
          </button>
        </div>
      </div>
      <div className='bg-white p-4 border rounded-lg min-h-[70vh] flex flex-col justify-between' >
        <div className='grid grid-cols-4 gap-4' >
          { folderData?.folders &&
            folderData?.folders.map((it)=>
              <FolderContainer
                key={it.id}
                project={it}
                openEditFolder={openEditFolderModal}
                fetchFolders={fetchFolderData}
              />
            )
          }
          { folderData?.projects &&
            folderData?.projects.map((it)=>
              <ProjectContainer
                key={it.id}
                project={it}
                openEditFile={openEditDiagramModal}
                fetchFolders={fetchFolderData}
                openInfoModal={openInfoModal}
                openShareModal={openShareModal}
              />
              // <SmallDiagramContainer
              //   key={it.id}
              //   item={it}
              //   changeShapes={setShapes}
              //   openShareModal={openShareModal}
              //   openInfoModal={openInfoModal}
              //   fetchMyProjects={fetchFolderData}
              //   // deleteModal={openDeleteDiagramModal}
              // />
            )
          }
        </div>
        {/* <div className='mt-4 flex items-center justify-between w-full' >
          <div className='flex gap-3 items-center' >
            <button onClick={()=> page > 1 && setPage(page-1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
              <FiChevronLeft size={16} />
            </button>
            <span className='font-semibold text-sm' >{page}</span>
            <button onClick={()=> !isLastPage && setPage(page+1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
              <FiChevronRight size={16} />
            </button>
          </div>
          <div className='flex items-center w-max gap-5' >
            <label className='w-max text-sm' >{t("GLOBAL.PAGINATION_TITLE")}</label>
            <select id="sizeOfPage" defaultValue={10} onChange={(e)=> setSizeOfPage(e.target.value) } className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
              <option value={10} >10</option>
              <option value={15} >15</option>
              <option value={20} >20</option>
            </select>
          </div>
        </div> */}
      </div>
      <Modal isOpen={isNewFolderModalOpen} close={closeNewFolderModal} title={t("HOME.FOLDERS_VIEW.NEW_FOLDER_TITLE")} >
        <NewProjectModal close={closeNewFolderModal} idFolder={idFolder} />
      </Modal>
      <Modal isOpen={isEditFolderModalOpen} close={closeEditFolderModal} title={t("GLOBAL.EDIT")} >
        <EditProjectModal oldData={editFolderModalData} close={closeEditFolderModal} />
      </Modal>
      <Modal isOpen={isNewDiagramModalOpen} title={t("HOME.NEW_BTN")} close={closeNewDiagramModal} >
        <NewDiagramModal setShapes={setShapes} navigate={navigate} close={closeNewDiagramModal} origine="folder" parentFolder={idFolder} />
      </Modal>
      <Modal isOpen={isEditDiagramModalOpen} title={t("GLOBAL.DESCRIPTION_SHEET")} close={closeEditDiagramModal} >
        <EditDiagramModalV2 data={editDiagramModalData} close={closeEditDiagramModal} parentFolder={idFolder} fetchMyProjects={fetchFolderData} />
      </Modal>
      <Modal isOpen={isInfoModalOpen} close={closeInfoModal} title={t("GLOBAL.INFORMATIONS")} >
        <InfoModal close={closeInfoModal} projectId={infoModalData} />
      </Modal>
      <Modal isOpen={isShareModalOpen} close={closeShareModal} title={t("HOME.SHARE_MODAL.TITLE")} >
        <ShareModal close={closeShareModal} projectId={shareModalData} />
      </Modal>
    </div>
  )
}
