import './App.css';
import React, {useState, useEffect, Suspense} from 'react';
import {
  createHashRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { UserProvider } from './Context/UserContext';

import Root from './Components/Root';
import Home from './Components/Home';
import Board from './Components/Board';
import Footer from './Components/Footer';
import Login from './Components/Login';
import Account from './Components/Account';
import MyDiagrams from './Components/MyDiagrams';
import Users from './Components/Users';
import Folders from './Components/Folders';
import Folder from './Components/Folder';
import LoginModal from './Components/LoginModal';

import MasterRoot from "./Components/Master/MasterRoot";
import MasterDashboard from "./Components/Master/MasterDashboard";
import MasterClients from "./Components/Master/MasterClients";
import MasterUsers from "./Components/Master/MasterUsers";
import MasterNewClient from "./Components/Master/MasterNewClient";
import MasterEditClient from "./Components/Master/MasterEditClient";

import { loginEvent } from './Context/events';
import {checkToken, getMyInfo} from './services/accountService';
import MasterAccount from "./Components/Master/MasterAccount";
import MasterShowClient from "./Components/Master/MasterShowClient";
import {useTranslation} from "react-i18next";

const router = (isLogged, isMaster) => createHashRouter([
  isMaster
  ?
  {
    path: "/",
    element: isLogged ? <MasterRoot/> : <Navigate to="/login" />,
    children: [
      {
        path: "/",
        element: <MasterDashboard/>
      },{
        path: "/clients",
        element: <MasterClients/>
      },{
        path: "/new-client",
        element: <MasterNewClient/>
      },{
        path: "/edit-client/:clientId",
        element: <MasterEditClient/>
      },{
        path: "/show-client/:clientId",
        element: <MasterShowClient/>
      },{
        path: "/users",
        element: <MasterUsers/>
      },{
        path: "/account",
        element: <MasterAccount/>
      },
    ]
  }
  :
  {
    path: "/",
    element: isLogged ? <Root/> : <Navigate to="/login" />,
    children: [
      {
        path: "/",
        element: <Folders/>
      },{
        path: "/:idFolder",
        element: <Folder/>
      },{
        path: "/diagrams/:idProject",
        element: <MyDiagrams/>
      },{
        path: "/users",
        element: <Users/>
      },{
        path: "/account",
        element: <Account/>
      }
    ]
  },{
    path: "/board/:id",
    element: isLogged ? <Board/> : <Navigate to="/login" />,
  },{
    path: "/login",
    element: isLogged ? <Navigate to="/" /> : <Login/>,
  }
]);

function App() {

  const { i18n } = useTranslation();

  const [isLogged, setIsLogged] = useState(null);
  const [isMaster, setIsMaster] = useState(null);
  const [isAskToLoginModalOpen, setIsAskToLoginModalOpen] = useState(false);

  const closeAskToLoginModal = ()=>{
    setIsAskToLoginModalOpen(false);
  }

  useEffect(() => {
    const lang = localStorage.getItem('language');
    if (lang) {
      i18n.changeLanguage(lang);
    }else{
      localStorage.setItem('language', "fr");
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(isLogged){
        checkToken()
          .then(()=>{})
          .catch(err => setIsAskToLoginModalOpen(true));
      }
    }, 300000);

    return () => clearInterval(intervalId);
  }, [isLogged]);

  const isUserLogged = ()=>{
    if (localStorage.getItem('accessToken')) {
      checkToken()
      .then((res)=> setIsLogged(true) )
      .catch((err)=> setIsLogged(false) );
    } else if (localStorage.getItem('refreshToken')) {
      checkToken()
      .then((res)=> setIsLogged(true) )
      .catch((err)=> setIsLogged(false) );
    } else {
      setIsLogged(false);
    }
  }

  const isUserMaster = ()=>{
    if (localStorage.getItem('accessToken')) {
      getMyInfo()
        .then((res)=>{
          const username = res.username;
          if (username.split('_')[0] === "master"){
            setIsMaster(true);
          }else{
            setIsMaster(false);
          }
        })
        .catch((err)=> console.log(err) )
    }
  }

  useEffect(() => {
    const handleLoginEvent = (state) => {
      setIsLogged(state);
    };
    const handleMasterEvent = (state)=>{
      setIsMaster(state);
    }

    loginEvent.on('login', handleLoginEvent);
    loginEvent.on('master', handleMasterEvent);

    return () => {
      loginEvent.removeListener('login', handleLoginEvent);
      loginEvent.removeListener('master', handleMasterEvent);
    };
  }, [loginEvent]);

  useEffect(()=>{
    isUserLogged();
    isUserMaster();
  },[]);

  if(isLogged === null){
    return(
      <Suspense fallback="loading" >
        <div className="min-h-[90vh] relative flex items-center justify-center" >
          <div className='loading' >
            <div className='box' />
          </div>
        </div>
      </Suspense>
    )
  }

  if(isLogged && isMaster === null){
    return(
      <Suspense fallback="loading">
        <div className="min-h-[90vh] relative flex items-center justify-center">
          <div className='loading'>
            <div className='box'/>
          </div>
        </div>
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      {
        isLogged
          ? isAskToLoginModalOpen && <LoginModal close={closeAskToLoginModal}/>
          : null
      }
      <AnimatePresence>
        <div className="App" >
          <UserProvider isLogged={isLogged} >
            <RouterProvider router={router(isLogged, isMaster)} />
          </UserProvider>
        </div>
      </AnimatePresence>
    </Suspense>
  );
}

export default App;
