import React, {useEffect, useState, useRef, useContext, useCallback} from 'react';
import { Stage, Layer, Line, Rect, Transformer, Text, Group, Arrow, Circle, Image as KonvaImage } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { renderToStaticMarkup } from 'react-dom/server';

import { FiCircle, FiClock, FiPlusSquare, FiMail, FiTriangle, FiPlus, FiUser, FiSettings, FiRewind, FiX } from 'react-icons/fi';
import { TbRectangle, TbPentagon, TbScript, TbHandStop, TbArrowBigRight, TbNorthStar } from "react-icons/tb";
import { CgArrowLongRightE } from "react-icons/cg";
import { LuSquareEqual } from "react-icons/lu";
import { PiTableFill, PiWaveTriangleBold } from "react-icons/pi";

import { DragContext } from '../Context/DragContext';
import { UserContext } from '../Context/UserContext';

import { getProjectById, getProjectCurrVersion } from '../services/projectService';
import { parseXML } from './Header';


const getCursorPosition = (e)=>{
  const stage = e.target.getStage();
  // Get the raw pointer position
  const pointerPosition = stage.getPointerPosition();
  // Adjusting for scale and position (offset)
  const scale = stage.scaleX(); // assuming scaleX and scaleY are the same
  const position = stage.position();
  const transformedPointerPosition = {
    x: (pointerPosition.x - position.x) / scale,
    y: (pointerPosition.y - position.y) / scale
  };

  return transformedPointerPosition;
}

function truncateText(text, maxLength) {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function Rectangle({ shape, i, setSelectedId }) {

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'Task':
        return null
      case 'UserTask':
        return <FiUser size={20} color='#2EBED6' />
      case 'ServiceTask':
        return <FiSettings size={20} color='#2EBED6' />
      case 'ReceiveTask':
        return <FiMail size={20} color='#2EBED6' />
      case 'SendTask':
        return <FiMail size={20} color='#C2E4EF' fill='#2EBED6' />
      case 'ScriptTask':
        return <TbScript size={20} color='#2EBED6' />
      case 'ManualTask':
        return <TbHandStop size={20} color='#2EBED6'/>
      case 'BusinessRuleTask':
        return <PiTableFill size={20} color='#2EBED6' />
      default:
        break;
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  return (
    <Group
      key={i}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable={false}
      onClick={(e) => setSelectedId(e, shape.id)}
    >
      <Rect
        id={shape.id}
        x={0}
        y={0}
        width={shape.width}
        height={shape.height}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        // cornerRadius={shape.cornerRadius}
        cornerRadius={7}
        fill={shape.bgColor}
        rotation={shape.rotation}
      />
      <Text
        text={truncateText(shape.name, 12)}
        x={shape.width / 2}
        y={shape.height / 2}
        fontSize={12}
        align='center'
        verticalAlign='middle'
        offsetY={6}
        offsetX={shape.width / 2}
        listening={false}
        width={shape.width}
      />
      {iconImage && (
        <KonvaImage
          image={iconImage}
          x={2}
          y={2}
          width={14}
          height={14}
          listening={false}
        />
      )}
    </Group>
  );
}

function MyCircle({ shape, index, setSelectedId }) {

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  const getShapeIcon = ()=>{

    if(shape.family === "StartEvent"){
      switch (shape.shapeType) {
        case 'StartEvent':
          return null
        case 'TimerEventDefinition':
          return <FiClock size={20} color='#7BA23D' />
        case 'MessageEventDefinition':
          return <FiMail size={20} color='#7BA23D' />
        case 'SignalEventDefinition':
          return <FiTriangle size={20} color='#7BA23D' />
        case 'ConditionalEventDefinition':
          return <LuSquareEqual size={20} color='#7BA23D' />
        case 'ParallelMultipleStartEvent':
          return <FiPlus size={20} color='#7BA23D' />
        case 'MultipleStartEvent':
          return <TbPentagon size={20} color='#7BA23D' />
        default:
          break;
      }
    }else{
      switch (shape.shapeType) {
        case 'EndEvent':
          return null
        case 'TerminateEventDefinition':
          return <FiCircle size={20} fill='#D6323A' color='#D6323A' />
        case 'MessageEventDefinition':
          return <FiMail size={20} color='#D6323A' />
        case 'SignalEventDefinition':
          return <FiTriangle size={18} fill='#D6323A' color='#D6323A' />
        case 'CompensateEventDefinition':
          return <FiRewind size={18} fill='#D6323A' color='#D6323A' />
        case 'EscalationEventDefinition':
          return <FiTriangle size={18} fill='#D6323A' color='#D6323A' />
        case 'ErrorEventDefinition':
          return <PiWaveTriangleBold size={18} strokeWidth={30} fill='#D6323A' color='#D6323A' />
        case 'CancelEventDefinition':
          return <FiX size={20} strokeWidth={5} fill='#D6323A' color='#D6323A' />
        case 'MultipleEndEvent':
          return <TbPentagon size={20} fill='#D6323A' color='#D6323A' />
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  return (
    <Group
      key={index}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.radius*2}
      height={shape.radius*2}
      draggable={false}
      onClick={(e) =>{ setSelectedId(e, shape.id)}}
    >
      <Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
      />
      {iconImage && (
        <KonvaImage
          image={iconImage}
          x={ - iconImage.width / 2}
          y={ - iconImage.height / 2}
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
      )}
    </Group>
  );
}

function IntermediateCircle({ shape, index, setSelectedId }) {

  const groupRef = useRef();

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'IntermediateCatchEvent':
        return null
      case 'TimerEventDefinition':
        return <FiClock size={18} color='#F9BF00' />
      case 'MessageEventDefinition':
        return <FiMail size={18} color='#F9BF00' />
      case 'SignalEventDefinition':
        return <FiTriangle size={16} color='#F9BF00' />
      case 'LinkEventDefinition':
        return <TbArrowBigRight size={18} color='#F9BF00' />
      case 'CompensateEventDefinition':
        return <FiRewind size={18} color='#F9BF00' />
      case 'EscalationEventDefinition':
        return <TbPentagon size={18} color='#F9BF00' />
      case 'ConditionalEventDefinition':
        return <LuSquareEqual size={18} color='#F9BF00' />
      case 'IntermediateParallelMultipleEvent':
        return <FiPlus size={18} strokeWidth={5} color='#F9BF00' />
      case 'IntermediateMultipleEvent':
        return <TbPentagon size={18} color='#F9BF00' />
      default:
        break;
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  return (
    <Group
      ref={groupRef}
      key={index}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.radius*2}
      height={shape.radius*2}
      draggable={false}
      onClick={(e) => setSelectedId(e, shape.id)}
    >
      <Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
      />
      <Circle
        id={shape.id}
        x={0}
        y={0}
        radius={shape.radius - 4}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill="transparent"
        listening={false}
      />
      {iconImage && (
        <KonvaImage
          image={iconImage}
          x={0 - iconImage.width / 2}
          y={0 - iconImage.height / 2}
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
      )}
    </Group>
  );
}

function Gateway({ shape, setSelectedId }){

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  const getShapeIcon = ()=>{
    switch (shape.shapeType) {
      case 'ExclusiveGateway':
        return null
      case 'InclusiveGateway':
        return <FiCircle size={20} color='#F9BF00' />
      case 'ParallelGateway':
        return <FiPlus strokeWidth={4} size={20} color='#F9BF00' />
      case 'EventBasedGateway':
        return <TbPentagon size={20} color='#F9BF00' />
      case 'ComplexGateway':
        return <TbNorthStar size={20} color='#F9BF00' />
      default:
        break;
    }
  }

  useEffect(() => {
    if(getShapeIcon()){
      const svgString = renderToStaticMarkup(getShapeIcon());
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svg);

      setIconURL(blobURL);
    }
  }, []);

  return(
    <Group
      key={shape.id}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable={false}
      onClick={(e) => setSelectedId(e, shape.id)}
    >
      <Rect
        id={shape.id}
        width={shape.width}
        height={shape.height}
        x={0}
        y={0}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        fill={shape.bgColor}
        rotation={45}
      />
      {iconImage && (
        <KonvaImage
          image={iconImage}
          x={0 - iconImage.width / 2}
          y={0 + iconImage.height / 2 }
          width={iconImage.width}
          height={iconImage.height}
          listening={false}
        />
      )}
    </Group>
  )
}

function DivideLine({it, setSelectedId, selectedIds }){

  const textRef_part1 = useRef();
  const [textWidthPart1, setTextWidthPart1] = useState(60);

  let text_part1 = it.name;
  let yPosition_part1 = it.height/2 + textWidthPart1/2;
  let sliceValue = 0.1604* (it.height - 1.645);

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    if (textRef_part1.current) {
      setTextWidthPart1(textRef_part1.current.getTextWidth());
    }
  }, [it]);

  return(
    <Group
      key={it.id}
      id={it.id}
      x={it.x}
      y={it.y}
      draggable={false}
      // listening={false}
    >
      <Rect
        x={0}
        y={0}
        width={it.width}
        height={it.height}
        stroke="#000"
        fill='#fff'
        draggable={false}
        // listening={false}
        onClick={(e) =>{ setSelectedId(e, it.id)}}
      />
      <Text
        ref={textRef_part1}
        text={truncateText(text_part1.slice(0, sliceValue), sliceValue-1)}
        x={text_part1.length >= 40 ? 8 : 12 }
        y={yPosition_part1}
        fontSize={12}
        rotation={-90}
        fontStyle='bold'
        listening={false}
        fill={selectedIds.includes(it.id) ? "#01A1FF" : "#000"}
      />
    </Group>
  )
}

function Process({shape, shapes, setSelectedId, selectedIds}){

  const textRef_part1 = useRef();
  const textRef_part2 = useRef();
  const groupRef = useRef();
  const rect1Ref = useRef(null);
  const transformerRef = useRef();

  const [textWidthPart1, setTextWidthPart1] = useState(60);
  const [textWidthPart2, setTextWidthPart2] = useState(60);
  const [selectedBand, setSelectedBand] = useState(null);

  let text_part1 = shape.name;
  let text_part2 = shape.name;

  let yPosition_part1 = shape.height/2 + textWidthPart1/2;
  let yPosition_part2 = shape.height/2 + textWidthPart2/2

  useEffect(() => {
    if (transformerRef.current) {
      transformerRef.current.rotateEnabled(false);
      transformerRef.current.nodes([groupRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [shapes]);

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  useEffect(() => {
    if (textRef_part1.current) {
      setTextWidthPart1(textRef_part1.current.getTextWidth());
    }
    if (textRef_part2.current) {
      setTextWidthPart2(textRef_part2.current.getTextWidth());
    }
  }, [shape]);

  const lines = [];
  const createDividingLines = () => {
    for (let i = 0; i < shape.divideLine; i++) {
      const yPosition = i * (shape.height / shape.divideLine);
      lines.push(
        <Line
          key={i}
          points={[50, yPosition, shape.width, yPosition]}
          stroke={i === selectedBand ? "red" : "#000"}
          strokeWidth={2}
        />
      );
    }
    return lines;
  };

  const texts = [];
  const createTextElements = () => {
    const segmentHeight = shape.height / shape.divideLine;

    for (let i = 0; i < shape.divideLine; i++) {
      const yPosition = (i * segmentHeight) + (segmentHeight / 2) + 20;

      texts.push(
        <Text
          key={`text-${i}`}
          x={60}
          y={yPosition}
          text={`Bande ${i + 1}`}
          fontSize={12}
          rotation={-90}
          fontStyle='bold'
          listening={true}
          fill={i === selectedBand ? "red" : "#000"}
          onClick={()=>{ i === selectedBand ? setSelectedBand(null) : setSelectedBand(i) }}
          offsetY={12 / 2}
        />
      );
    }
    return texts;
  };

  return(
    <React.Fragment>
      <Group
        ref={groupRef}
        id={shape.id}
        x={shape.x}
        y={shape.y}

        draggable={false}
      >
        <Rect
          ref={rect1Ref}
          x={0}
          y={0}
          width={50}
          height={shape.height}
          stroke="#000"
          fill='#fff'
          onClick={(e) =>{ setSelectedId(e, shape.id); console.log(shape.x, shape.y, shape.width, shape.height)}}
        />
        <Rect
          x={50}
          y={0}
          width={shape.width -50}
          height={shape.height}
          stroke="#000"
          fill='#fff'
          draggable={false}
          listening={false}
        />
        <Text
          ref={textRef_part1}
          text={text_part1.slice(0, 50) }
          x={text_part1.length >= 50 ? 8 : 12 }
          y={yPosition_part1}
          fontSize={12}
          rotation={-90}
          fontStyle='bold'
          listening={false}
        />
        { text_part1.length >= 50 &&
          <Text
            ref={textRef_part2}
            text={truncateText(text_part2.slice(50, 100), 49)}
            x={28}
            y={yPosition_part2}
            fontSize={12}
            rotation={-90}
            fontStyle='bold'
            listening={false}
          />
        }
        {/* {shape.divideLine > 1 && createDividingLines()}
        {shape.divideLine > 1 && createTextElements()} */}
      </Group>
        {
          shapes.map((it)=>
            it.type === "divideLine" && <DivideLine key={it.id} it={it} setSelectedId={setSelectedId} selectedIds={selectedIds} />
          )
        }
      { selectedIds.includes(shape.id) && <Transformer ref={transformerRef} enabledAnchors={[]} /> }
    </React.Fragment>
  )
}

function CustomArrowV2({ shape, onShapeClick, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable={false}
      id={shape.id}
      onClick={handleClick}
    >
      <Arrow
        id={shape.id}
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable={false}
      />}
    </Group>
  )
}

function DashedCustomArrow({ shape, onShapeClick, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable={false}
      id={shape.id}
      onClick={handleClick}
    >
      <Arrow
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        dash={[10, 5]}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        stroke="#000"
        strokeWidth={1}
        fill="#fff"
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable={false}
      />}
    </Group>
  )
}

function DashedCustomLine({ shape, onShapeClick, selectedIds }){

  const groupRef = useRef();
  const arrowRef = useRef(null);
  const anchorOneRef = useRef(null);
  const anchorTwoRef = useRef(null);
  const middleAnchorRef = useRef(null);

  const handleClick = (e) => {
    onShapeClick(e, shape.id);
  };

  useEffect(() => {
    if (anchorOneRef.current) {
      anchorOneRef.current.x(shape.points[0]);
      anchorOneRef.current.y(shape.points[1]);
    }
    if (anchorTwoRef.current) {
      anchorTwoRef.current.x(shape.points[6]);
      anchorTwoRef.current.y(shape.points[7]);
    }
    if (middleAnchorRef.current) {
      middleAnchorRef.current.x(shape.points[4]);
      middleAnchorRef.current.y(shape.points[5]);
    }
  }, [shape]);

  return(
    <Group
      ref={groupRef}
      draggable={false}
      id={shape.id}
      onClick={handleClick}
    >
      <Line
        ref={arrowRef}
        points={shape.points}
        fill="black"
        stroke="black"
        strokeWidth={2}
        dash={[10, 5]}
        onMouseEnter={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'pointer';
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage().container();
          container.style.cursor = 'default';
        }}
      />
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorOneRef}
        x={shape.points[0]}
        y={shape.points[1]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={anchorTwoRef}
        x={shape.points[6]}
        y={shape.points[7]}
        radius={4}
        fill="red"
        draggable={false}
      />}
      { selectedIds.includes(shape.id) && <Circle
        ref={middleAnchorRef}
        x={shape.points[4]}
        y={shape.points[5]}
        radius={4}
        fill="red"
        draggable={false}
      />}
    </Group>
  )
}

function SubProcess({ shape, i, setSelectedId }){

  const [iconURL, setIconURL] = useState(null);
  const [iconImage] = useImage(iconURL);

  useEffect(() => {
    const svgString = renderToStaticMarkup( <FiPlusSquare size={20} color='#2EBED6'/>);
    const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svg);

    setIconURL(blobURL);
  }, []);

  return (
    <Group
      key={i}
      id={shape.id}
      x={shape.x}
      y={shape.y}
      width={shape.width}
      height={shape.height}
      draggable={false}
      onClick={(e) => setSelectedId(e, shape.id)}
    >
      <Rect
        id={shape.id}
        x={0}
        y={0}
        width={shape.width}
        height={shape.height}
        stroke={shape.borderColor}
        strokeWidth={shape.borderWidth}
        // cornerRadius={shape.cornerRadius}
        cornerRadius={3}
        fill={shape.bgColor}
        rotation={shape.rotation}
      />
      {iconImage && (
        <KonvaImage
          image={iconImage}
          x={(shape.width/2) - (iconImage.width/2)}
          y={shape.height - iconImage.height + 2}
          width={20}
          height={20}
          listening={false}
        />
      )}
    </Group>
  );
}

export default function CanvaStageRead({shapes, setShapes}) {

  const transformerRef = useRef();
  const mainContainerRef = useRef(null);
  const { setSelectedShape } = useContext(DragContext);
  const {
    isFullLeftBar, isFullRightBar,
    stageScale, setStageScale,
    stageX, setStageX,
    stageY, setStageY,
    mode, setMode,
    stageRef, currDiagramId, currDiagramAccessType
  } = useContext(UserContext);

  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [boardWidth, setBoardWidth] = useState("");
  const [dimensions, setDimensions] = useState({ width: window.innerWidth*0.7, height: window.innerHeight*0.7 });
  const [selectedIds, setSelectedIds] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isDraggingGird, setIsDraggingGird] = useState(false);

  const [selectionRect, setSelectionRect] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const handleShapeClick = (e, id) => {
    const isSelected = selectedIds.includes(id);
    if (e.evt.shiftKey || e.evt.ctrlKey) {
      setSelectedIds(isSelected ? selectedIds.filter(sid => sid !== id) : [...selectedIds, id]);
    } else {
      if(!isSelected){
        setSelectedIds([id]);
      }
    }
  };

  const handleStageDragMove = (e) => {
    if (!isDraggingGird) {
      const stage = e.target.getStage();
      setStageX(stage.x());
      setStageY(stage.y());
    }
  };

  const handleStageDragEnd = (e) => {
    if (!isDraggingGird) {
      const stage = e.target.getStage();
      setStageX(stage.x());
      setStageY(stage.y());
    }
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if(newScale > 0.24 && newScale < 4 ){
      setStageScale(newScale);
      setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
      setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
    }
  };

  const handleMouseDown = (e) => {
    if(mode === 'panning' || isResizing) return;
    if (mode === 'select') {
      if (e.target === e.target.getStage()) {
        setSelectedIds([]);
      }
      setIsSelecting(true);
      const pos = getCursorPosition(e);
      setSelectionRect({ x: pos.x, y: pos.y, width: 0, height: 0 });
    }
  };

  const handleMouseMove = (e) => {
    if(mode === 'panning' || isResizing) return;
    if(mode === 'select' && isSelecting ){
      const point = getCursorPosition(e);
      const newSelectionRect = {
        x: selectionRect.x,
        y: selectionRect.y,
        width: point.x - selectionRect.x,
        height: point.y - selectionRect.y,
      };
      setSelectionRect(newSelectionRect);
    }
  };

  const handleMouseUp = () => {
    if(mode === 'panning') return;
    if (mode === 'select' && isSelecting) {
      setIsSelecting(false);
      // Normalize the selection rectangle
      const normalizedSelectionRect = {
        x: Math.min(selectionRect.x, selectionRect.x + selectionRect.width),
        y: Math.min(selectionRect.y, selectionRect.y + selectionRect.height),
        width: Math.abs(selectionRect.width),
        height: Math.abs(selectionRect.height),
      };
      selectShapes(normalizedSelectionRect);
      setSelectionRect({ x: 0, y: 0, width: 0, height: 0 });
      setSelectedShape({});
    }
  };
  const isOverlapping = (rect, shape) => {
    return !(rect.x > shape.x + shape.width ||
             rect.x + rect.width < shape.x ||
             rect.y > shape.y + shape.height ||
             rect.y + rect.height < shape.y);
  };
  const isLineOverlapping = (rect, line) => {
    const [x1, y1, x2, y2] = line.points;

    const isEndPointInside = (x, y) => {
      return x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height;
    };

    if (isEndPointInside(x1, y1) || isEndPointInside(x2, y2)) {
      return true;
    }
    return false;
  };

  const selectShapes = (selectionRect) => {
    const newSelectedIds = shapes.filter(shape => {
      let isShapeOverlapping;
      if(shape.type === 'process') return;
      if (shape.type === 'arrow') {
        isShapeOverlapping = isLineOverlapping(selectionRect, shape);
      } else {
        isShapeOverlapping = isOverlapping(selectionRect, shape);
      }

      return isShapeOverlapping;
    }).map(shape => shape.id);
    setSelectedIds(newSelectedIds);
    setShapes([...shapes]);
  };

  useEffect(() => {
    if (selectedIds.length > 0 && transformerRef.current) {
      const stage = transformerRef.current.getStage();

      const selectedShapes = selectedIds.length > 1
        ?
          selectedIds.map(id => shapes.find(shape => shape.id === id && (shape.type !== 'process' ) ))
          .filter(shape => shape)
          .map(shape => stage.findOne(`#${shape.id}`))
        :
          selectedIds.map(id => shapes.find(shape => shape.id === id && (shape.type !== 'arrow' && shape.type !== 'process' )))
          .filter(shape => shape)
          .map(shape => stage.findOne(`#${shape.id}`))

      transformerRef.current.nodes(selectedShapes);

      if (selectedIds.length > 1) {
        transformerRef.current.enabledAnchors([]);
        transformerRef.current.rotateEnabled(false);
      } else {
        transformerRef.current.enabledAnchors([]);
        transformerRef.current.rotateEnabled(false);
      }

    } else if (transformerRef.current) {
      transformerRef.current.nodes([]);
    }
  }, [selectedIds, shapes]);

  const handleResize = () => {
    setTimeout(() => {
      setDimensions({
        width: mainContainerRef?.current === null ? window.innerWidth*0.7 : mainContainerRef.current.offsetWidth,
        height: mainContainerRef.current === null ? window.innerHeight*0.7 : mainContainerRef.current.offsetHeight,
      });
    }, 300);
  };

  useEffect(() => {
    if(mainContainerRef){
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [boardWidth, mainContainerRef]);

  const getBoardWidth = ()=>{
    if(currDiagramAccessType === "READ"){
      if (isFullRightBar){
        return 'w-[calc(100%_-_16rem)]';
      }else{
        return 'w-[calc(100%_-_1.5rem)]';
      }
    }
    if(isFullLeftBar && isFullRightBar){
      return 'w-[calc(100%_-_24rem)]';
    }else if (isFullLeftBar){
      return 'w-[calc(100%_-_9.5rem)]';
    }else if (isFullRightBar){
      return 'w-[calc(100%_-_20rem)]';
    }else{
      return 'w-[calc(100%_-_5.5rem)]';
    }
  }
  useEffect(()=>{
    setBoardWidth(getBoardWidth());
  },[isFullLeftBar, isFullRightBar]);

  useEffect(()=>{
    if(mode === 'panning'){
      setIsDraggingGird(true);
    }else{
      setIsDraggingGird(false);
    }
  },[mode]);

  useEffect(()=>{
    if(selectedIds.length === 1 ){
      const getSelectedShape = shapes.find(shape => selectedIds.includes(shape.id) );
      setSelectedShape(getSelectedShape);
    }else{
      setSelectedShape({});
    }
  },[selectedIds]);

  const getProjectInfo = ()=>{
    setIsFetchLoading(true)
    getProjectCurrVersion(currDiagramId)
    .then((res)=>{
      parseXML(res, setShapes);
    })
    .catch((err)=> console.log(err) )
    .finally(()=> setIsFetchLoading(false) )
  }
  useEffect(()=>{
    if(currDiagramId){
      getProjectInfo();
      handleResize();
    }
  },[currDiagramId]);

  if(isFetchLoading){
    return(
      <div className={`${boardWidth} h-[90vh] relative`}  >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${boardWidth} border-t bg-white select-none`}
      ref={mainContainerRef}
      onDragOver={(e) => e.preventDefault()}
    >

      <Stage
        ref={stageRef}
        width={dimensions.width}
        height={dimensions.height}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onWheel={handleWheel}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}
        draggable={isDraggingGird}
        onDragMove={handleStageDragMove}
        onDragEnd={handleStageDragEnd}
        className='z-0'
        style={{ cursor: isDraggingGird ? 'grab' : 'default', position: 'relative' }}
      >
        <Layer>
          {shapes?.map((shape, i) =>{
            switch (shape.type) {
              case 'process':
                return(
                  <Process
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    shapes={shapes}
                    setSelectedId={handleShapeClick}
                    selectedIds={selectedIds}
                  />
                )
            }
          })}
        </Layer>
        <Layer>
          {shapes?.map((shape, i) =>{
            switch (shape.type) {
              case 'arrow':
                if(shape.shapeType.includes("Association")) {
                  return (
                    <React.Fragment key={i}>
                      <DashedCustomLine
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        onShapeClick={(e, id) => {
                          handleShapeClick(e, id);
                        }}
                        selectedIds={selectedIds}
                      />
                    </React.Fragment>
                  );
                }
                else if(shape.shapeType.includes("Sequence")){
                  return (
                    <CustomArrowV2
                      id={shape.id}
                      key={shape.id}
                      shape={shape}
                      onShapeClick={(e, id) => {
                        handleShapeClick(e, id);
                      }}
                      selectedIds={selectedIds}
                    />
                  );
                }else{
                  return (
                    <React.Fragment key={i}>
                      <DashedCustomArrow
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        onShapeClick={(e, id) => {
                          handleShapeClick(e, id);
                        }}
                        selectedIds={selectedIds}
                      />
                    </React.Fragment>
                  );
                }
            }
          })}
        </Layer>
        <Layer>
          {shapes?.map((shape, i) =>{
            switch (shape.type) {
              case 'rectangle':
                if(shape.family.includes("Task")){
                  return(
                    <React.Fragment key={shape.id} >
                      <Rectangle
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        i={i}
                        setSelectedId={handleShapeClick}
                      />
                    </React.Fragment>
                  );
                }else{
                  return(
                    <React.Fragment key={shape.id} >
                      <SubProcess
                        id={shape.id}
                        key={shape.id}
                        shape={shape}
                        i={i}
                        setSelectedId={handleShapeClick}
                      />
                    </React.Fragment>
                  );
                }
              case 'circle':
                if(shape.family.includes("Intermediate")){
                  return(
                    <IntermediateCircle
                      id={shape.id}
                      key={shape.id}
                      shape={shape}
                      index={i}
                      setSelectedId={handleShapeClick}
                    />
                  )
                }
                return (
                  <MyCircle
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    index={i}
                    setSelectedId={handleShapeClick}
                  />
                );
              case "square":
                return(
                  <Gateway
                    id={shape.id}
                    key={shape.id}
                    shape={shape}
                    index={i}
                    setSelectedId={handleShapeClick}
                  />
                )
            }
          })}
          <Transformer
            ref={transformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              return newBox;
            }}
          />
          { !isResizing &&
            isSelecting ? (
              <Rect
                x={selectionRect.x}
                y={selectionRect.y}
                width={selectionRect.width}
                height={selectionRect.height}
                fill="rgba(0,0,255,0.3)"
              />
          ) : null}
        </Layer>

      </Stage>
    </div>
  )
}
