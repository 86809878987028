import React, {useContext, useRef, useState, useEffect} from 'react';
import { FiChevronDown, FiChevronsLeft, FiChevronsRight, FiCircle, FiSidebar,  } from 'react-icons/fi';
import { TbRectangle } from "react-icons/tb";
import { CgArrowLongRightE } from "react-icons/cg";
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { DragContext } from '../Context/DragContext';
import { UserContext } from '../Context/UserContext';


import {
  BpmnStartEvent,
  BpmnStartEventTimer,
  BpmnStartEventMessage,
  BpmnStartEventSignal,
  BpmnStartEventCondition,
  BpmnStartEventParallelMultiple,
  BpmnStartEventMultiple,

  BpmnTask,
  BpmnUserTask,
  BpmnServiceTask,
  BpmnReceiveTask,
  BpmnSendTask,
  BpmnScriptTask,
  BpmnManualTask,
  BpmnBusinessRuleTask,

  BpmnSubprocessCollapsed,

  BpmnGateway,
  BpmnGatewayOr,
  BpmnGatewayParallel,
  BpmnGatewayEventbased,
  BpmnGatewayComplex,

  BpmnIntermediateEvent,
  BpmnIntermediateEventCatchTimer,
  BpmnIntermediateEventCatchMessage,
  BpmnIntermediateEventCatchSignal,
  BpmnIntermediateEventCatchLink,
  BpmnIntermediateEventCatchCompensation,
  BpmnIntermediateEventCatchEscalation,
  BpmnIntermediateEventCatchCondition,
  BpmnIntermediateEventCatchParallelMultiple,
  BpmnIntermediateEventCatchMultiple,

  BpmnEndEvent,
  BpmnEndEventTerminate,
  BpmnEndEventMessage,
  BpmnEndEventSignal,
  BpmnEndEventCompensation,
  BpmnEndEventEscalation,
  BpmnEndEventError,
  BpmnEndEventCancel,
  BpmnEndEventMultiple,

  BpmnConnection,

  BpmnParticipant,
  BpmnLaneDivideTwo,

  BpmnDataObject,
  BpmnDataStore,
  BpmnTextAnnotation,

  DashedLine,
  DashedConnectionLine
} from '../assets/SVGIcons';


export default function LeftSideBar() {

  const { t } = useTranslation();
  const { isFullLeftBar, setIsFullLeftBar } = useContext(UserContext);
  const { draggedShape, setDraggedShape, setDraggedShapeType, draggedShapeFamily, setDraggedShapeFamily } = useContext(DragContext);
  const startEventMenuRef =  useRef(null);
  const tasksMenuRef = useRef(null);
  const subProcessMenuRef = useRef(null)
  const gatewayMenuRef = useRef(null);
  const intermediateEventRef = useRef(null);
  const endEventMenuRef = useRef(null);

  const [isStartEventMenuOpen, setIsStartEventMenuOpen] = useState(false);
  const [isTasksMenuOpen, setIsTasksMenuOpen] = useState(false);
  const [isSubProcessMenuOpen, setIsSubProcessMenuOpen] = useState(false);
  const [isGatewayMenuOpen, setIsGatewayMenuOpen] = useState(false);
  const [isIntermediateEventMenuOpen, setIsIntermediateEventMenuOpen] = useState(false);
  const [isEndEventMenuOpen, setIsEndEventMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuMaxHeight, setMenuMaxHeight] = useState('auto');

  const startEvents = [
    {id: 1, name: t("NAV.LEFT.START_EVENTS.START_EVENT"), family: "StartEvent", shapeType: "StartEvent", icon: <BpmnStartEvent width="18" height="18" fill="#CCE0C6" stroke='#7BA23D' />},
    {id: 2, name: t("NAV.LEFT.START_EVENTS.START_EVENT_TIMER"), family: "StartEvent", shapeType: "TimerEventDefinition", icon: <BpmnStartEventTimer width="20" height="20" fill="#CCE0C6" stroke='#7BA23D' />},
    {id: 3, name: t("NAV.LEFT.START_EVENTS.START_EVENT_MESSAGE"), family: "StartEvent", shapeType: "MessageEventDefinition", icon: <BpmnStartEventMessage width="20" height="20" fill="#CCE0C6" stroke='#7BA23D' />},
    {id: 4, name: t("NAV.LEFT.START_EVENTS.START_EVENT_SIGNAL"), family: "StartEvent", shapeType: "SignalEventDefinition", icon: <BpmnStartEventSignal width="18" height="18" fill="#CCE0C6" stroke='#7BA23D' />},
    {id: 5, name: t("NAV.LEFT.START_EVENTS.START_EVENT_CONDITIONAL"), family: "StartEvent", shapeType: "ConditionalEventDefinition", icon: <BpmnStartEventCondition width="18" height="18" fill="#CCE0C6" stroke='#7BA23D' />},
    // {id: 6, name: t("NAV.LEFT.START_EVENTS.START_EVENT_PARALLEL"), family: "StartEvent", shapeType: "ParallelMultipleStartEvent", icon: <BpmnStartEventParallelMultiple width="18" height="18" fill="#CCE0C6" stroke='#7BA23D' />},
    // {id: 7, name: t("NAV.LEFT.START_EVENTS.START_EVENT_MULTIPLE"), family: "StartEvent", shapeType: "MultipleStartEvent", icon: <BpmnStartEventMultiple width="18" height="18" fill="#CCE0C6" stroke='#7BA23D' />},
  ]

  const tasks = [
    {id: 1, name: t("NAV.LEFT.TASKS.TASK"), family: "Task", shapeType: "Task", icon: <BpmnTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 2, name: t("NAV.LEFT.TASKS.TASK_USER"), family: "UserTask", shapeType: "UserTask", icon: <BpmnUserTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 3, name: t("NAV.LEFT.TASKS.TASK_SERVICE"), family: "ServiceTask", shapeType: "ServiceTask", icon: <BpmnServiceTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 4, name: t("NAV.LEFT.TASKS.TASK_RECEIVE"), family: "ReceiveTask", shapeType: "ReceiveTask", icon: <BpmnReceiveTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 5, name: t("NAV.LEFT.TASKS.TASK_SEND"), family: "SendTask", shapeType: "SendTask", icon: <BpmnSendTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 6, name: t("NAV.LEFT.TASKS.TASK_SCRIPT"), family: "ScriptTask", shapeType: "ScriptTask", icon: <BpmnScriptTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 7, name: t("NAV.LEFT.TASKS.TASK_MANUAL"), family: "ManualTask", shapeType: "ManualTask", icon: <BpmnManualTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 8, name: t("NAV.LEFT.TASKS.TASK_BUSINESSRULE"), family: "BusinessRuleTask", shapeType: "BusinessRuleTask", icon: <BpmnBusinessRuleTask width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
  ]

  const subProcessors = [
    {id: 1, name: t("NAV.LEFT.SUB_PROCESS.SUB_PROCESS"), family: "SubProcess", shapeType: "SubProcess", icon: <BpmnSubprocessCollapsed width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
    {id: 1, name: t("NAV.LEFT.SUB_PROCESS.CALL_ACTIVITY"), family: "CallActivity", shapeType: "CallActivity", icon: <BpmnSubprocessCollapsed width="18" height="18" fill="#C2E4EF" stroke='#2EBED6' />},
  ]

  const gateways = [
    {id: 1, name: t("NAV.LEFT.GATEWAYS.GATEWAY_EXCLUSIVE"), family: "ExclusiveGateway", shapeType: "ExclusiveGateway", icon: <BpmnGateway width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />},
    {id: 2, name: t("NAV.LEFT.GATEWAYS.GATEWAY_INCLUSIVE"), family: "InclusiveGateway", shapeType: "InclusiveGateway", icon: <BpmnGatewayOr width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />},
    {id: 3, name: t("NAV.LEFT.GATEWAYS.GATEWAY_PARALLEL"), family: "ParallelGateway", shapeType: "ParallelGateway", icon: <BpmnGatewayParallel width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />},
    {id: 4, name: t("NAV.LEFT.GATEWAYS.GATEWAY_EVENTBASED"), family: "EventBasedGateway", shapeType: "EventBasedGateway", icon: <BpmnGatewayEventbased width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />},
    {id: 5, name: t("NAV.LEFT.GATEWAYS.GATEWAY_COMPLEX"), family: "ComplexGateway", shapeType: "ComplexGateway", icon: <BpmnGatewayComplex width="18" height="18" fill="#fef6d9" stroke='#F9BF00' />},
  ]

  const intermediateEvents = [
    { id: 1, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE"), family: "IntermediateCatchEvent", shapeType: "IntermediateCatchEvent", icon: <BpmnIntermediateEvent width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 2, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_TIMER"), family: "IntermediateCatchEvent", shapeType: "TimerEventDefinition", icon: <BpmnIntermediateEventCatchTimer width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 3, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_MESSAGE"), family: "IntermediateCatchEvent", shapeType: "MessageEventDefinition", icon: <BpmnIntermediateEventCatchMessage width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 4, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_SIGNAL"), family: "IntermediateCatchEvent", shapeType: "SignalEventDefinition", icon: <BpmnIntermediateEventCatchSignal width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 5, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_LINK"), family: "IntermediateCatchEvent", shapeType: "LinkEventDefinition", icon: <BpmnIntermediateEventCatchLink width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 6, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_COMPENSATE"), family: "IntermediateCatchEvent", shapeType: "CompensateEventDefinition", icon: <BpmnIntermediateEventCatchCompensation width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 7, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_ESCALATION"), family: "IntermediateCatchEvent", shapeType: "EscalationEventDefinition", icon: <BpmnIntermediateEventCatchEscalation width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    { id: 8, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_CONDITIONAL"), family: "IntermediateCatchEvent", shapeType: "ConditionalEventDefinition", icon: <BpmnIntermediateEventCatchCondition width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    // { id: 9, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_PARALLEL"), family: "IntermediateCatchEvent", shapeType: "IntermediateParallelMultipleEvent", icon: <BpmnIntermediateEventCatchParallelMultiple width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
    // { id: 10, name: t("NAV.LEFT.INTERMEDIATE_EVENTS.INTERMEDIATE_MULTIPLE"), family: "IntermediateCatchEvent", shapeType: "IntermediateMultipleEvent", icon: <BpmnIntermediateEventCatchMultiple width="18" height="18" fill="#F9BF00" stroke='#F9BF00' /> },
  ];

  const endEvents = [
    { id: 1, name: t("NAV.LEFT.END_EVENTS.END_EVENT"), family: "EndEvent", shapeType: "EndEvent", icon: <BpmnEndEvent width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 2, name: t("NAV.LEFT.END_EVENTS.END_EVENT_TERMINATE"), family: "EndEvent", shapeType: "TerminateEventDefinition", icon: <BpmnEndEventTerminate width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 3, name: t("NAV.LEFT.END_EVENTS.END_EVENT_MESSAGE"), family: "EndEvent", shapeType: "MessageEventDefinition", icon: <BpmnEndEventMessage width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 4, name: t("NAV.LEFT.END_EVENTS.END_EVENT_SIGNAL"), family: "EndEvent", shapeType: "SignalEventDefinition", icon: <BpmnEndEventSignal width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 5, name: t("NAV.LEFT.END_EVENTS.END_EVENT_COMPENSATE"), family: "EndEvent", shapeType: "CompensateEventDefinition", icon: <BpmnEndEventCompensation width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 6, name: t("NAV.LEFT.END_EVENTS.END_EVENT_ESCALATION"), family: "EndEvent", shapeType: "EscalationEventDefinition", icon: <BpmnEndEventEscalation width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 7, name: t("NAV.LEFT.END_EVENTS.END_EVENT_ERROR"), family: "EndEvent", shapeType: "ErrorEventDefinition", icon: <BpmnEndEventError width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    { id: 8, name: t("NAV.LEFT.END_EVENTS.END_EVENT_CANCEL"), family: "EndEvent", shapeType: "CancelEventDefinition", icon: <BpmnEndEventCancel width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
    // { id: 9, name: t("NAV.LEFT.END_EVENTS.END_EVENT_MULTIPLE"), family: "EndEvent", shapeType: "MultipleEndEvent", icon: <BpmnEndEventMultiple width="18" height="18" fill="#D6323A" stroke='#2EBED6' /> },
  ];

  const onDragStart = (event, shape, shapeType, family) => {
    setDraggedShape(shape);
    setDraggedShapeType(shapeType);
    setDraggedShapeFamily(family)
    // event.currentTarget.classList.add('dragging');

    const dragImage = document.getElementById('drag-image');
    if (dragImage) {
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    }
  };
  const onDragEnd = (event) => {
    event.currentTarget.classList.remove('dragging');
    setIsStartEventMenuOpen(false);
    setIsTasksMenuOpen(false);
    setIsSubProcessMenuOpen(false);
    setIsGatewayMenuOpen(false);
    setIsIntermediateEventMenuOpen(false);
    setIsEndEventMenuOpen(false);
  };

  const openMenu = (event, type)=>{
    const x = event.clientX;
    const y = event.clientY - 120;
    setMenuPosition({ x, y });

    const buttonRect = event.currentTarget.getBoundingClientRect();
    const availableHeight = window.innerHeight - buttonRect.bottom;
    setMenuMaxHeight(availableHeight);

    switch (type) {
      case "start":
        setIsStartEventMenuOpen(true);
        break;
      case "task":
        setIsTasksMenuOpen(true);
        break;
      case "subProcess":
        setIsSubProcessMenuOpen(true);
        break;
      case "gateway":
        setIsGatewayMenuOpen(true)
        break;
      case "intermediate":
        setIsIntermediateEventMenuOpen(true)
        break;
      case "end":
        setIsEndEventMenuOpen(true);
        break;
      default:
        break;
    }

  }

  const handleClickOutside = (event) => {
    if (startEventMenuRef.current && !startEventMenuRef.current.contains(event.target)) {
      setIsStartEventMenuOpen(false);
    }
    if (tasksMenuRef.current && !tasksMenuRef.current.contains(event.target)) {
      setIsTasksMenuOpen(false);
    }
    if (subProcessMenuRef.current && !subProcessMenuRef.current.contains(event.target)) {
      setIsSubProcessMenuOpen(false);
    }
    if (gatewayMenuRef.current && !gatewayMenuRef.current.contains(event.target)) {
      setIsGatewayMenuOpen(false);
    }
    if (intermediateEventRef.current && !intermediateEventRef.current.contains(event.target)) {
      setIsIntermediateEventMenuOpen(false);
    }
    if (endEventMenuRef.current && !endEventMenuRef.current.contains(event.target)) {
      setIsEndEventMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={cx('border-r border-t select-none transition-all relative duration-300',{'w-32':isFullLeftBar, 'w-16': !isFullLeftBar} )} >
    {/* <div className={cx('border-r border-t h-full select-none transition-all relative duration-300')} > */}
      <div className='flex justify-between items-center p-2' >
        <p className='text-xs font-semibold' >{t("NAV.LEFT.TITLE")}</p>
        {
          isFullLeftBar
          ? <FiChevronsLeft className='cursor-pointer' onClick={()=> setIsFullLeftBar(false) } />
          : <FiChevronsRight className='cursor-pointer' onClick={()=> setIsFullLeftBar(true) } />
        }
      </div>
      {/* <div ref={dragImageRef} style={{ position: 'relative'}}>
        {draggedShape === 'circle' ? (
          <FiCircle size={45} fill='#CCE0C6' color='#7BA23D' strokeWidth={1} className='mb-4' />
        ) : (
          <TbRectangle size={120} fill='#C2E4EF' color='#2EBED6' strokeWidth={0.5} />
        )}
      </div> */}

      <div className='overflow-y-auto overflow-x-hidden p-2 h-[calc(100%_-_2rem)]' >
        <div className={cx('grid gap-4', { 'grid-cols-2': isFullLeftBar, 'grid-cols-1 gap-3': !isFullLeftBar })} >
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-1 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'circle', 'StartEvent', 'StartEvent')} onDragEnd={onDragEnd} draggable={true} >
              <BpmnStartEvent width="28" height="28" fill="#CCE0C6" stroke='#7BA23D' />
            </div>
            <div onClick={(e)=> openMenu(e, "start")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-0.5 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'rectangle', 'Task', "Task")} onDragEnd={onDragEnd} draggable >
              <BpmnTask width="28" height="28" fill="#C2E4EF" stroke='#2EBED6' />
            </div>
            <div onClick={(e)=> openMenu(e, "task")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-1 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'rectangle', 'SubProcess', "SubProcess")} onDragEnd={onDragEnd} draggable >
              <BpmnSubprocessCollapsed width="28" height="28" fill="#C2E4EF" stroke='#2EBED6' />
            </div>
            <div onClick={(e)=> openMenu(e, "subProcess")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-1 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'square', 'ExclusiveGateway', 'ExclusiveGateway')} onDragEnd={onDragEnd} draggable={true} >
              <BpmnGateway width="28" height="28" fill="#fef6d9" stroke='#F9BF00' />
            </div>
            <div onClick={(e)=> openMenu(e, "gateway")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-1 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'circle', 'IntermediateCatchEvent', 'IntermediateCatchEvent')} onDragEnd={onDragEnd} draggable={true} >
              <BpmnIntermediateEvent width="28" height="28" fill="#F9BF00" stroke='#FEEDB7' />
            </div>
            <div onClick={(e)=> openMenu(e, "intermediate")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
          <div className='flex justify-between gap-1 cursor-pointer relative items-center rounded-md pl-1 py-1 hover:bg-gray-200' >
            <div onDragStart={(e) => onDragStart(e, 'circle', 'EndEvent', 'EndEvent')} onDragEnd={onDragEnd} draggable={true} >
              <BpmnEndEvent width="28" height="28" fill="#D6323A" stroke='#FEEDB7' />
            </div>
            <div onClick={(e)=> openMenu(e, "end")} className='hover:bg-gray-300 h-full flex items-center justify-center rounded-r-md' >
              <FiChevronDown size={12} />
            </div>
          </div>
        </div>
        <div className='border-b my-3' />
        <div className={cx('grid', { 'grid-cols-2': isFullLeftBar, 'grid-cols-1 gap-3': !isFullLeftBar })} >
          <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, 'arrow', 'SequenceFlow')} onDragEnd={onDragEnd} draggable >
            <BpmnConnection width="28" height="28" />
          </div>
          <div className='flex justify-center cursor-pointer mb-3' onDragStart={(e) => onDragStart(e, 'arrow', 'Association')} onDragEnd={onDragEnd} draggable >
            <DashedLine/>
          </div>
          <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, 'arrow', 'MsgFlow')} onDragEnd={onDragEnd} draggable >
            <DashedConnectionLine/>
          </div>
        </div>
        <div className='border-b my-3' />
        <div className={cx('grid', { 'grid-cols-2': isFullLeftBar, 'grid-cols-1 gap-3': !isFullLeftBar })} >
          <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, 'process', 'process')} onDragEnd={onDragEnd} draggable >
            <BpmnParticipant width="28" height="28" />
          </div>
          <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, 'divideLine', 'divideLine')} onDragEnd={onDragEnd} draggable >
            <BpmnLaneDivideTwo width="28" height="28" />
          </div>
        </div>
        <div className='border-b my-3' />
        <div className={cx('grid', { 'grid-cols-2': isFullLeftBar, 'grid-cols-1 gap-3': !isFullLeftBar })} >
        <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, "TextAnnotation", "TextAnnotation")} onDragEnd={onDragEnd} draggable >
            <BpmnTextAnnotation width="28" height="28" />
          </div>
          {/* <div className='flex justify-center cursor-pointer' onDragStart={(e) => onDragStart(e, 'process', 'process')} onDragEnd={onDragEnd} draggable >
            <BpmnDataObject width="28" height="28" />
          </div> */}
          {/* <div className='flex justify-center cursor-pointer' >
            <BpmnDataStore width="28" height="28" />
          </div> */}
        </div>
      </div>

      { isStartEventMenuOpen &&
        <div
          ref={startEventMenuRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              startEvents.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'circle', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable={true}
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
      { isTasksMenuOpen &&
        <div
          ref={tasksMenuRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              tasks.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'rectangle', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
      { isSubProcessMenuOpen &&
        <div
          ref={subProcessMenuRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              subProcessors.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'rectangle', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
      { isGatewayMenuOpen &&
        <div
          ref={gatewayMenuRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              gateways.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'square', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable={true}
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
      { isIntermediateEventMenuOpen &&
        <div
          ref={intermediateEventRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              intermediateEvents.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'circle', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable={true}
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
      { isEndEventMenuOpen &&
        <div
          ref={endEventMenuRef}
          style={{top: `${menuPosition.y}px`, left: `${menuPosition.x}px`, maxHeight: `${menuMaxHeight}px`}}
          className='absolute z-20 py-2 w-max bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
          <ul className='flex flex-col gap-2' >
            {
              endEvents.map((it)=>
                <li
                  key={it.id}
                  onDragStart={(e) => onDragStart(e, 'circle', it.shapeType, it.family)} onDragEnd={onDragEnd} draggable={true}
                  className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'
                >
                  {it.icon}
                  <span>{it.name}</span>
                </li>
              )
            }
          </ul>
        </div>
      }
    </div>
  )
}
