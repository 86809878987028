import React, {useState, useEffect, useRef, useContext} from 'react';
import { FiGlobe, FiUser, FiLogOut, FiPlus, FiUpload, FiChevronUp, FiMoreHorizontal, FiSearch, FiGrid, FiList, FiChevronDown, FiEdit, FiTrash, FiShare, FiCheckCircle, FiXOctagon, FiChevronLeft, FiChevronRight, FiShare2, FiX, FiAlertCircle } from "react-icons/fi";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { useNavigate, Link } from 'react-router-dom';
import cx from 'classnames';
import Modal from './Modal';
import { useForm } from "react-hook-form";

import moment from 'moment';
import 'moment/locale/fr';

import {
  getAllUsers,
  getUserByPage,
  createUser,
  getAllRoles,
  updateUser,
  deleteUser,
  getUserById
} from '../services/userService';
import { UserContext } from '../Context/UserContext';
import {
  createTenantUser,
  deleteTenantUser, getAllTenantRoles,
  getTenantUserById,
  getUserByTenantByPage,
  updateTenantUser
} from "../services/tenantUsers";

function NewUserModal({user}){

  const rolesInputRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item)=>{
    if(selectedRoles.includes(item.id)){
      setSelectedRoles(selectedRoles.filter(it=> it !== item.id ));
      setSelectedRolesObj(selectedRolesObj.filter(it=> it !== item ));
    }else{
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: data.password,
      roles: selectedRoles,
      tenant: user.tenantId,
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    createTenantUser(formData)
    .then((response)=> setUserCreated(true) )
    .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
    .finally(()=> setLoading(false) );

  }

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllTenantRoles()
    .then((data)=> setAllRoles(data) )
    .catch((err)=> console.log(err) )
  },[])

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex items-center justify-between gap-3 mb-3' >
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.LAST_NAME")} *</label>
              { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.LAST_NAME")}
              {...register("lastName", { required: true })}
            />
          </div>
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.FIRST_NAME")} *</label>
              { errors.firstName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.FIRST_NAME")}
              {...register("firstName", { required: true })}
            />
          </div>
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col w-max'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm w-max'>
              <label className="text-gray-500">
                {user.tenantCode + "_"}
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("GLOBAL.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.EMAIL")} *</label>
              {errors.email ?
              errors.email.type === "required"
                ? <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>
                : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.PASSWORD")} *</label>
            {errors.password &&
              <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("GLOBAL.PASSWORD")}
            // {...register("password", { required: true })}
            {...register("password", {
              required: "required",
              minLength: {
                value: 8,
                message: "Le mot de passe doit contenir au moins 8 caractères"
              },
              maxLength: {
                value: 16,
                message: "Le mot de passe ne doit pas dépasser 16 caractères"
              },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
              }
            })}
          />
          <div className='px-3.5' >
            <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
          </div>
        </div>
        <div className='flex flex-col mb-3 relative' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.ROLE")} *</label>
            { ( isUserSubmit && selectedRoles.length === 0) ? <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef} >
            <div onClick={()=> setIsRolesPickerOpen(!isRolesPickerOpen) } className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')} >
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                    ? <span className='text-gray-500' >{t("GLOBAL.ROLE")} *</span>
                    : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                ? <FiChevronUp size={18} color='#000' className='cursor-pointer' onClick={()=> setIsRolesPickerOpen(false) }  />
                : <FiChevronDown size={18} color='#000' className='cursor-pointer' onClick={()=> setIsRolesPickerOpen(true) }  />
              }
            </div>
            {
              isRolesPickerOpen &&
              <div className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]' >
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("GLOBAL.SEARCH")}
                      value={roleInput}
                      onChange={(e)=> onTypeRole(e.target.value) }
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it)=>
                      <li key={it.id} onClick={()=> addOrRomoveRole(it) } className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})} >
                        {it.name}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='flex justify-between mt-6' >

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled} onChange={()=> setEnabled(!enabled) } />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("HOME.USERS_VIEW.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative' >
              <div className='loader'/>
            </div>
            :
            <input
              onClick={()=> setIsUserSubmit(true) }
              type="submit"
              value={t("GLOBAL.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
            />
          }
        </div>
      </form>
    </div>
  )
}

function UpdateUserModal({userData, user}){

  const rolesInputRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userUpdated, setUserUpdated] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item)=>{
    if(selectedRoles.includes(item.id)){
      setSelectedRoles(selectedRoles.filter(it=> it !== item.id ));
      setSelectedRolesObj(selectedRolesObj.filter(it=> it.id !== item.id ));
    }else{
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userNewData = {
      id: userData.id,
      email: data.email,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: isEditPassword ? data.password : null,
      roles: selectedRoles,
      tenant: user.tenantId,
    }

    formData.append('userDto', new Blob([JSON.stringify(userNewData)], { type: 'application/json' }));

    updateTenantUser(formData, userData.id)
      .then((response)=> setUserUpdated(true) )
      .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
      .finally(()=> setLoading(false) );
  }

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllTenantRoles()
    .then((data)=> setAllRoles(data) )
    .catch((err)=> console.log(err) )
  },[])

  useEffect(()=>{
    if(userInfo && Object.keys(userInfo).length > 0){
      setValue('lastName', userInfo.lastName);
      setValue('firstName', userInfo.firstName);
      setValue('email', userInfo.email);
      setValue('userName', userInfo.username.split('_')[1]);
      setSelectedRolesObj(userInfo.roles);
      setSelectedRoles(userInfo.roles.map(it=>it.id));
      setEnabled(userInfo.enabled);
      validateEmail(userInfo.email);
    }
  },[userInfo]);

  useEffect(() => {
    if(userData && Object.keys(userData).length > 0){
      getTenantUserById(userData.id)
        .then((data)=> setUserInfo(data) )
        .catch((err)=> console.log(err) );
    }
  }, [userData]);

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userUpdated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_UPDATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex items-center justify-between gap-3 mb-3' >
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.LAST_NAME")} *</label>
              { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.LAST_NAME")}
              {...register("lastName", { required: true })}
            />
          </div>
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.FIRST_NAME")} *</label>
              { errors.firstName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.FIRST_NAME")}
              {...register("firstName", { required: true })}
            />
          </div>
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col w-max'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm w-max'>
              <label className="text-gray-500">
                {user.tenantCode + "_"}
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("GLOBAL.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.EMAIL")} *</label>
              {errors.email ?
              errors.email.type === "required"
                ? <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label>
                : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3 relative'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("GLOBAL.ROLE")} *</label>
            {(isUserSubmit && selectedRoles.length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef}>
            <div onClick={() => setIsRolesPickerOpen(!isRolesPickerOpen)}
                 className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                      ? <span className='text-gray-500'>{t("GLOBAL.ROLE")} *</span>
                      : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                  ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                 onClick={() => setIsRolesPickerOpen(false)}/>
                  : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                   onClick={() => setIsRolesPickerOpen(true)}/>
              }
            </div>
            {
              isRolesPickerOpen &&
              <div
                className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("GLOBAL.SEARCH")}
                      value={roleInput}
                      onChange={(e)=> onTypeRole(e.target.value) }
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it)=>
                      <li key={it.id} onClick={()=> addOrRomoveRole(it) } className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})} >
                        {it.name}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='border p-2 rounded-lg' >
          <div className='flex items-center justify-between' >
            <p className='text-gray-500 text-sm font-semibold' >{t("GLOBAL.EDIT")} {t("GLOBAL.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={()=> setIsEditPassword(!isEditPassword) } >
              <FiX className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})} />
            </div>
          </div>
          <motion.div
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: {opacity: 1, height: 'auto', display: 'flex'},
              closed: {opacity: 0, height: 0, display: 'none'}
            }}
            transition={{ duration: 0.5 }}
            className={cx('flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label onClick={()=> console.log(errors.password) } className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.PASSWORD")}</label>
              { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("GLOBAL.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", {
                required: isEditPassword ? "required" : false,
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}
            />
            <div className='px-3.5' >
              <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
            </div>
          </motion.div>
        </div>
        <div className='flex justify-between mt-6' >

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled} onChange={()=> setEnabled(!enabled) } />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("HOME.USERS_VIEW.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative' >
              <div className='loader'/>
            </div>
            :
            <input
              onClick={()=> setIsUserSubmit(true) }
              type="submit"
              value={t("GLOBAL.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
            />
          }
        </div>
      </form>
    </div>
  )
}

function NewRoleModal({data}){

  const rolesInputRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([{id: 1, name: "test 1"}, {id: 2, name: "test 2"}]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);

  const addOrRomoveRole = (item)=>{
    if(selectedRoles.includes(item.id)){
      setSelectedRoles(selectedRoles.filter(it=> it !== item.id ));
      setSelectedRolesObj(selectedRolesObj.filter(it=> it !== item ));
    }else{
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    console.log(data);
  }

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles])

  useEffect(()=>{
    if(data && Object.keys(data).length > 0){
      // setName(data.name);
      // setDescription(data.description);
      // setType(data.type)
    }
  },[data])

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    console.log(errors);
  },[errors])

  return(
    <div className='max-w-[50vw]' >
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex flex-col w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.NAME")} *</label>
            { errors.name && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("GLOBAL.LAST_NAME")}
            {...register("name", { required: true })}
          />
        </div>
        <div className='flex flex-col w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.DESCRIPTION")} *</label>
            { errors.description && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("GLOBAL.FIRST_NAME")}
            {...register("description", { required: true })}
          />
        </div>
        <div className='flex flex-col mb-3 relative' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("HOME.USERS_VIEW.ROLES_TABLE.PRIVILEGES")} *</label>
            { ( isUserSubmit && selectedRoles.length === 0) ? <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef} >
            <div onClick={()=> setIsRolesPickerOpen(!isRolesPickerOpen) } className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')} >
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                    ? <span className='text-gray-500' >{t("HOME.USERS_VIEW.ROLES_TABLE.PRIVILEGES")} *</span>
                    : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                ? <FiChevronUp size={18} color='#000' className='cursor-pointer' onClick={()=> setIsRolesPickerOpen(false) }  />
                : <FiChevronDown size={18} color='#000' className='cursor-pointer' onClick={()=> setIsRolesPickerOpen(true) }  />
              }
            </div>
            {
              isRolesPickerOpen &&
              <div className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]' >
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("GLOBAL.SEARCH")}
                      value={roleInput}
                      onChange={(e)=> onTypeRole(e.target.value) }
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it)=>
                      <li key={it.id} onClick={()=> addOrRomoveRole(it) } className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-azure text-white': selectedRoles.includes(it.id)})} >
                        {it.name}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='flex justify-end mt-6' >
          <input
            onClick={()=> setIsUserSubmit(true) }
            type="submit"
            value={t("GLOBAL.SAVE")}
            className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure'
          />
        </div>
      </form>
    </div>
  )
}

export function DeleteUserModal({close, userId, getUsers}){

  const {t} = useTranslation();

  const submit = ()=>{
    deleteTenantUser(userId)
    .then((res)=>{
      getUsers();
      close();
    })
    .catch((err)=> console.log(err) );
  }

  return(
    <div className='flex flex-col items-center' >
      <FiAlertCircle size={64} color='#D6323A' />
      <p className='mt-4 font-bold text-lg' >{t("HOME.USERS_VIEW.DELETE_MODAL.TITLE")}</p>

      <div className='w-full mt-6 flex items-center justify-between px-10' >
        <button onClick={()=> close() } className='hover:text-azure hover:border-azure flex items-center gap-2 px-2.5 py-1.5 rounded-lg border text-xs font-semibold text-gray-600' >
          {t("GLOBAL.CANCEL")}
        </button>
        <button onClick={()=> submit() } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
          {t("GLOBAL.DELETE")}
        </button>
      </div>
    </div>
  )
}

export default function Users() {

  const {t} = useTranslation();
  const { userPrivileges, user } = useContext(UserContext);
  const [menuType, setMenuType] = useState("users");
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] = useState(false);
  const [updateUserModalData, setUpdateUserModalData] = useState({});
  const [isNewRoleModalOpen, setIsNewRoleModalOpen] = useState(false);
  const [newRoleModalData, setNewRoleModalData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({});
  const [isLastPage, setIsLastPage] = useState(false);

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");

  const getUsers = ()=>{
    getUserByTenantByPage(page, sizeOfPage, searchInput)
    .then((data)=>{
      setUsers(data.content);
      setIsLastPage(data.last);
      console.log(data);
    })
    .catch((err)=> console.log(err) );
  }

  const openNewUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewUserModalOpen(true);
  }
  const closeNewUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewUserModalOpen(false);
    getUsers();
  }

  const openUpdateUserModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsUpdateUserModalOpen(true);
    setUpdateUserModalData(data);
  }
  const closeUpdateUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setUpdateUserModalData({});
    setIsUpdateUserModalOpen(false);
    getUsers();
  }

  const openNewRoleModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setNewRoleModalData(data);
    setIsNewRoleModalOpen(true);
  }
  const closeNewRoleModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewRoleModalOpen(false);
    setNewRoleModalData({});
  }

  const openDeleteUserModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setDeleteModalData(data);
    setIsDeleteModalOpen(true);
  }
  const closeDeleteUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsDeleteModalOpen(false);
    setDeleteModalData({});
  }

  useEffect(()=>{
    getUsers();
  },[page, sizeOfPage, searchInput]);

  const usersTab = ()=>(
    <div className="overflow-hidden border border-gray-200 rounded-lg mt-5 bg-white">
      <table className="min-w-full border-collapse" id='my-applications-table' >
        <thead className="bg-gray-100">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.LAST_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.FIRST_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.EMAIL")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.CREATED_AT")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.ROLE")}</th>
            <th/>
          </tr>
        </thead>
        <tbody className="text-gray-800 text-sm ">
          {
            users.map((it)=>
              <tr key={it.id} className={cx('border', {'text-gray-400': !it.enabled})} >
                <td className="text-left py-3 px-2">{it.lastName}</td>
                <td className="text-left py-3 px-2">{it.firstName}</td>
                <td className="text-left py-3 px-2">{it.email}</td>
                <td className="text-left py-3 px-2">{moment(it.createdAt).calendar()}</td>
                <td className="text-left py-3 px-2">{it.roles.map(role=> role.name).join(', ')}</td>
                <td className='w-20' >
                  <div className='flex items-center gap-4' >
                    { userPrivileges.includes("client_users_update") &&
                      <FiEdit onClick={()=> openUpdateUserModal(it) } size={16} className='hover:text-azure cursor-pointer text-yellow-500' />
                    }
                    { userPrivileges.includes("client_users_delete") &&
                      <FiTrash onClick={()=> openDeleteUserModal(it.id) } size={16} className='hover:text-red-700 cursor-pointer text-red-600' />
                    }
                  </div>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  )

  const rolesTab = ()=>(
    <div className="overflow-hidden border border-gray-200 rounded-lg mt-5">
      <table className="min-w-full border-collapse" id='my-applications-table' >
        <thead className="bg-gray-200">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("HOME.USERS_VIEW.ROLES_TABLE.ROLE_NAME")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.DESCRIPTION")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("GLOBAL.CREATED_AT")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("HOME.USERS_VIEW.ROLES_TABLE.PRIVILEGES")}</th>
            <th/>
          </tr>
        </thead>
        <tbody className="text-gray-700 text-sm ">
          <tr >
            <td className="text-left py-3 px-2">
              <Link className='hover:underline cursor-pointer hover:text-brome' >
                ttest
              </Link>
            </td>
            <td className="text-left py-3 px-2">test</td>
            <td className="text-left py-3 px-2">test</td>
            <td className="text-left py-3 px-2">test</td>
            <td className='w-20' >
              <div className='flex items-center gap-2' >
                <FiEdit size={16} className='hover:text-azure cursor-pointer' onClick={()=> openNewUserModal({}) } />
                <FiTrash size={16} className='hover:text-red-700 cursor-pointer' />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  return(
    <div>
      <div className='flex items-center justify-between' >
        <h1 className='text-xl font-bold ' >
          {
            menuType === "users"
            ? t("HOME.USERS_VIEW.USERS_TITLE")
            : t("HOME.USERS_VIEW.ROLES_TITLE")
          }
        </h1>
        <div className='flex gap-2' >
          {/* <div className='flex border justify-between rounded-lg py-0.5 px-1 gap-1 relative text-xs font-semibold w-48' >
            <div className={cx('bg-azure transition-all duration-300 h-[calc(100%_-_0.5rem)] top-1 w-[45%] absolute rounded-lg', {'left-1': menuType === 'users', 'left-[53%]': menuType === 'roles'})} style={{zIndex: -1}} />
            <button onClick={()=> setMenuType("users") } className={cx('px-1 py-1.5 rounded-lg w-[45%]',{'text-white ': menuType === 'users', 'hover:bg-transparent': menuType === 'roles'})} >
              {t("HOME.USERS_VIEW.USERS_TITLE")}
            </button>
            <button onClick={()=> setMenuType("roles") } className={cx('p-1.5 rounded-lg w-[45%]',{'text-white': menuType === 'roles', 'hover:bg-transparent': menuType === 'users'})} >
              {t("HOME.USERS_VIEW.ROLES_TITLE")}
            </button>
          </div> */}
          <div className='px-2.5 py-1.5 rounded-lg border text-sm flex items-center gap-2 bg-white' >
            <FiSearch size={16} className='text-gray-500' />
            <input
              type='text'
              placeholder={t("GLOBAL.SEARCH")+' ...'}
              className='bg-transparent'
              onChange={(event)=> setSearchInput(event.target.value) }
            />
          </div>
          {
            menuType === "users" ?
            userPrivileges.includes("client_users_create") &&
            <button onClick={()=> openNewUserModal({}) } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
              <FiPlus size={16} strokeWidth={2.5} />
              {t("HOME.NEW_USER_BTN")}
            </button>
            :
            <button onClick={()=> openNewRoleModal({}) } className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg' >
              <FiPlus size={16} strokeWidth={2.5} />
              {t("HOME.NEW_ROLE_BTN")}
            </button>
          }
        </div>
      </div>
      { menuType === "users" ? usersTab() : rolesTab() }
      <div className='mt-4 flex items-center justify-between' >
        <div className='flex gap-3 items-center' >
          <button onClick={()=> page > 1 && setPage(page-1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
            <FiChevronLeft size={16} />
          </button>
          <span className='font-semibold text-sm' >{page}</span>
          <button onClick={()=> !isLastPage && setPage(page+1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
            <FiChevronRight size={16} />
          </button>
        </div>
        <div className='flex items-center w-max gap-5' >
          <label className='w-max text-sm' >{t("GLOBAL.PAGINATION_TITLE")}</label>
          <select id="sizeOfPage" defaultValue={10} onChange={(e)=> setSizeOfPage(e.target.value) } className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
            <option value={10} >10</option>
            <option value={15} >15</option>
            <option value={20} >20</option>
          </select>
        </div>
      </div>
      <Modal isOpen={isDeleteModalOpen} close={closeDeleteUserModal} title={t("HOME.USERS_VIEW.DELETE_MODAL.MODAL_TITLE")} >
        <DeleteUserModal close={closeDeleteUserModal} userId={deleteModalData} getUsers={getUsers} />
      </Modal>
      <Modal
        isOpen={isNewUserModalOpen}
        title={t("HOME.USERS_VIEW.NEWUSER_MODAL_TITLE")}
        close={closeNewUserModal} >
        <NewUserModal user={user} />
      </Modal>
      <Modal
        isOpen={isUpdateUserModalOpen}
        title={t("HOME.USERS_VIEW.EDIT_NEWUSER_MODAL_TITLE")}
        close={closeUpdateUserModal} >
        <UpdateUserModal userData={updateUserModalData} user={user} />
      </Modal>
      <Modal
        isOpen={isNewRoleModalOpen}
        title={Object.keys(newRoleModalData).length > 0 ? t("HOME.USERS_VIEW.NEWROLE_MODAL_TITLE") : t("HOME.USERS_VIEW.EDIT_NEWROLE_MODAL_TITLE")}
        close={closeNewRoleModal} >
        <NewRoleModal data={newRoleModalData} />
      </Modal>
    </div>
  )
}
