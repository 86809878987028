import React from "react";
import { Html } from "react-konva-utils";

function getStyle(width, height, shape) {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  const baseStyle = {
    width: `${width}px`,
    height: `${height}px`,
    border: "none",
    padding: "1px 3px",
    margin: "0px",
    background: "transparent",
    outline: "none",
    resize: "none",
    color: shape.fontColor,
    fontSize: `${shape.fontSize}px` ,
    fontFamily: "sans-serif",
    fontWeight: shape.fontWeight,
    fontStyle: shape.fontStyle,
    textDecoration: shape.textDecoration,
    position: "absolute",
  };
  if (isFirefox) {
    return baseStyle;
  }
  return {
    ...baseStyle,
    margintop: "-4px"
  };
}

export default function EditableTextKonvaV2({x, y, width, height, onChange, text, setIsEditing, shape}) {

  const style = getStyle(width, height, shape);

  return (
    <Html groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
      <textarea
        value={text}
        onChange={(e)=> onChange(e.target.value) }
        style={style}
        onClick={()=> setIsEditing(true) }
        onBlur={()=> setIsEditing(false) }
      />
    </Html>
  );

}
