import React, {useContext, useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import LeftSideBar from './LeftSideBar';
import CanvaStage from './CanvaStage';
import RightSideBar from './RightSideBar';

import Header from './Header';
import Footer from './Footer';

import { DragProvider } from '../Context/DragContext';
import { UserContext } from '../Context/UserContext';

import CanvaStageRead from './CanvaStageRead';

export default function Board() {

  const {id} = useParams();
  const { shapes, setShapes, currDiagramAccessType, currDiagramId, setCurrDiagramId, setIsFullRightBar } = useContext(UserContext);
  // const [shapes, setShapes] = useState([]);
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [isRightSideBarFocused, setIsRightSideBarFocuses] = useState(false);
  const [projectRessources, setProjectRessources] = useState([]);
  const [barType, setBarType] = useState("");

  const openBar = (type)=>{
    setIsFullRightBar(true);
    setBarType(type);
  }
  const closeBar = ()=>{
    setIsFullRightBar(false);
    setBarType("");
  }

  useEffect(()=>{
    if(id !== currDiagramId){
      setCurrDiagramId(id);
    }
  },[id])

  return (
    <DragProvider>
      <Header
        projectRessources={projectRessources}
        setProjectRessources={setProjectRessources}
        setUndoHistory={setUndoHistory}
        setRedoHistory={setRedoHistory}
        undoHistory={undoHistory}
      />
      <div className='flex h-[calc(100%_-_7.6rem)] select-none' >
        { currDiagramAccessType !== "READ" && <LeftSideBar/>}
        {
          currDiagramAccessType === "READ" ?
          <CanvaStageRead
            shapes={shapes}
            setShapes={setShapes}
          />
          :
          <CanvaStage
            shapes={shapes}
            setShapes={setShapes}
            undoHistory={undoHistory}
            setUndoHistory={setUndoHistory}
            redoHistory={redoHistory}
            setRedoHistory={setRedoHistory}
            isRightSideBarFocused={isRightSideBarFocused}
            openBar={openBar}
          />
        }
        <RightSideBar
          shapes={shapes}
          setShapes={setShapes}
          undoHistory={undoHistory}
          setUndoHistory={setUndoHistory}
          redoHistory={redoHistory}
          setRedoHistory={setRedoHistory}
          setIsRightSideBarFocuses={setIsRightSideBarFocuses}
          projectRessources={projectRessources}
          setProjectRessources={setProjectRessources}
          barType={barType}
          openBar={openBar}
          closeBar={closeBar}
        />
      </div>
      <Footer/>
    </DragProvider>
  )
}
