import axios from "axios";
import { loginEvent } from "../Context/events";

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  if (!refreshToken) {
    console.error('No refresh token');
    return null;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/account/refresh`,
      { refreshToken },
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }
    );

    const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
    localStorage.setItem('accessToken', newAccessToken);
    if (newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
    }
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        localStorage.setItem('accessToken', newAccessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // loginEvent.emit("login", false);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
