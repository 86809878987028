import React from 'react';
import { FiX } from 'react-icons/fi';

export default function Modal({children, isOpen, close, title}) {

  if(isOpen){
    return (
      <div className='fixed w-full h-screen top-0 left-0 bg-transparent z-50' >
        <div 
          className='absolute block top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-10 p-4 w-max min-w-[40%] max-w-screen-md max-h-[calc(100%_-_4rem)] rounded-xl flex-wrap' 
        >
          <div className='flex items-center justify-between mb-4' >
            <p className='font-bold text-sm text-gray-500' >{title}</p>
            <FiX onClick={()=> close() } size={20} className='cursor-pointer' />
          </div>
          {children}
        </div>
        <div className='absolute z-0 top-0 bg-black/50 w-full h-screen' onClick={()=> close() } />
      </div>
    )
  }else{
    return (
      null
    )
  
}

}