import React, {useContext, useRef, useEffect, useState} from 'react';
import {FiChevronDown, FiChevronUp, FiEdit2, FiMaximize2, FiPlusCircle, FiTrash, FiX} from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../Context/UserContext';
import { DragContext } from '../Context/DragContext';
import Modal from './Modal';
import { getProjectById, getProjectCurrVersion, updateProject } from '../services/projectService';

function ResourceModal({data, close, projectRessources, setProjectRessources}){

  const { currDiagramId } = useContext(UserContext);
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async ()=>{
    setLoading(true);
    setProjectRessources(oldArray => [...oldArray, {id: new Date().getTime(), nom: name, description, ressourceType: type}]);
    setLoading(false);
    close();
  }

  const updateResource = async ()=>{
    setLoading(true);
    const newData = projectRessources.map(resource => {
      if (resource.id === data.id) {
        return { id: resource.id, nom: name, description, ressourceType: type};
      }
      return resource;
    });
    setProjectRessources(newData);
    setLoading(false);
    close();
  }

  useEffect(()=>{
    if(data && Object.keys(data).length > 0){
      setName(data.nom);
      setDescription(data.description);
      setType(data.ressourceType)
    }
  },[data]);

  if(loading){
    return(
      <div className='relative h-40' >
        <div className='loader' />
      </div>
    )
  }

  return(
    <div>
      <div className='flex flex-col mb-3' >
        <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.NAME.TITLE")}</label>
        <input
          name='name'
          type='text'
          placeholder={t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.NAME.PLACEHOLDER")}
          className='border rounded-lg px-2 py-1 text-sm'
          value={name}
          onChange={(e)=> setName(e.target.value) }
        />
      </div>
      <div className='flex flex-col mb-3' >
        <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.DESCRIPTION.TITLE")}</label>
        <textarea
          name='description'
          rows={3}
          placeholder={t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.DESCRIPTION.TITLE")}
          className='border rounded-lg px-2 py-1 text-sm'
          value={description}
          onChange={(e)=> setDescription(e.target.value) }
        />
      </div>
      <div>
        <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.TYPE.TITLE")}</label>
        <div className='flex px-2 gap-10 items-center mt-1' >
          <div className='flex items-center gap-1' >
            <input
              type='radio'
              name='type'
              value="ROLE"
              checked={type === "ROLE"}
              onChange={(e)=> setType(e.target.value) }
            />
            <label className='text-sm' >{t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.TYPE.ROLE")}</label>
          </div>
          <div className='flex items-center gap-1' >
            <input
              type='radio'
              name='type'
              value="ENTITY"
              checked={type === "ENTITY"}
              onChange={(e)=> setType(e.target.value) }
            />
            <label className='text-sm' >{t("NAV.RIGHT.RESOURCES.NEW_RESOURCE.TYPE.ENTITE")}</label>
          </div>
        </div>
      </div>
      <div className='flex justify-end mt-6' >
        {
          data && Object.keys(data).length > 0 ?
          <button onClick={()=> updateResource() } className='border border-azure font-semibold rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure' >
            {t("GLOBAL.SAVE")}
          </button>
          :
          <button onClick={()=> submit() } className='border border-azure font-semibold rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-azure' >
            {t("GLOBAL.SAVE")}
          </button>
        }
      </div>
    </div>
  )
}

function FullCommentModal({currShape, handleInputChange, close}){
  const {t} = useTranslation();
  return(
    <div>
      <textarea
        name='description'
        rows={4}
        placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.DESCRIPTION.PLACEHOLDER")}
        className='border rounded-lg px-2 py-1 text-sm w-full'
        value={currShape.description}
        onChange={handleInputChange}
      />
      <div className="mt-5 flex items-center justify-end " >
        <button onClick={()=> close() } className="bg-azure text-white font-semibold text-sm px-6 py-2.5 rounded-lg" >
          {t("GLOBAL.SAVE")}
        </button>
      </div>
    </div>
  )
}

export default function RightSideBar({shapes, setShapes, undoHistory, setUndoHistory, redoHistory, setRedoHistory, setIsRightSideBarFocuses, projectRessources, setProjectRessources, barType, openBar, closeBar}) {

  const {t} = useTranslation();
  const {isFullRightBar, setIsFullRightBar, currDiagramId, currDiagramAccessType} = useContext(UserContext);
  const {selectedShape, setSelectedShape} = useContext(DragContext);

  const [currShape, setCurrShape] = useState({name: "", description: "", participants: {}, evaluable: {}, consulte: {}, informe: {} });

  const [isToggleSideBarActionCompleted, setIsToggleSideBarActionCompleted] = useState(false);
  // const [barType, setBarType] = useState("");
  const [isResourcesModalOpen, setIsResourcesModalOpen] = useState(false);
  const [resourcesModalData, setResourcesModalData] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [isFullCommentModalOpen, setIsFullCommentModalOpen] = useState(false);

  const [isParticipantsMenuOpen, setIsParticipantsMenuOpen] = useState(false);
  const [isEvaluableMenuOpen, setIsEvaluableMenuOpen] = useState(false);
  const [isConsulteMenuOpen, setIsConsulteMenuOpen] = useState(false);
  const [isInformeMenuOpen, setIsInformeMenuOpen] = useState(false);
  const [isProcessMenuOpen, setIsProcessMenuOpen] = useState(false);

  const [participantsInputSearch, setParticipantsInputSearch] = useState("");
  const [evaluableInputSearch, setEvaluableInputSearch] = useState("");
  const [consulteInputSearch, setConsulteInputSearch] = useState("");
  const [informeInputSearch, setInformeInputSearch] = useState("");
  const [processInputSearch, setProcessInputSearch] = useState("");

  const updateShapes = (updatedShapes) => {
    // Capture current state before the update
    setUndoHistory([...undoHistory, shapes]);
    // Clear the redo history on new action
    setRedoHistory([]);
    // Update shapes
    setShapes(updatedShapes);
  };

  // const openBar = (type)=>{
  //   setIsFullRightBar(true);
  //   setBarType(type);
  // }
  // const closeBar = ()=>{
  //   setIsFullRightBar(false);
  //   setBarType("");
  // }

  const openResourcesModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setResourcesModalData(data);
    setIsResourcesModalOpen(true);
  }
  const closeResourcesModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsResourcesModalOpen(false);
    setResourcesModalData({});
  }

  const openCommentModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsFullCommentModalOpen(true);
  }
  const closeCommentModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsFullCommentModalOpen(false);
  }

  const removeResource = (ressource)=>{
    setProjectRessources(projectRessources.filter((it)=> it.id !== ressource.id ))
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedSelectedShape = {
      ...selectedShape,
      [name]: value,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
  };

  const handleParticipantsInputChange = (value)=>{
    setParticipantsInputSearch(value);
    if(value.length < 2){
      setIsParticipantsMenuOpen(false);
    }else{
      setIsParticipantsMenuOpen(true);
    }
  }
  const handleEvaluableInputChange = (value)=>{
    setEvaluableInputSearch(value);
    if(value.length < 2){
      setIsEvaluableMenuOpen(false);
    }else{
      setIsEvaluableMenuOpen(true);
    }
  }
  const handleConsulteInputChange = (value)=>{
    setConsulteInputSearch(value);
    if(value.length < 2){
      setIsConsulteMenuOpen(false);
    }else{
      setIsConsulteMenuOpen(true);
    }
  }
  const handleInformeInputChange = (value)=>{
    setInformeInputSearch(value);
    if(value.length < 2){
      setIsInformeMenuOpen(false);
    }else{
      setIsInformeMenuOpen(true);
    }
  }
  const handleProcessInputChange = (value)=>{
    setProcessInputSearch(value);
    if(value.length < 2){
      setIsProcessMenuOpen(false);
    }else{
      setIsProcessMenuOpen(true);
    }
  }

  const selectResource = (type, value)=>{
    const updatedSelectedShape = {
      ...selectedShape,
      [type]: value,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);

    switch (type) {
      case "participants":
        setIsParticipantsMenuOpen(false);
        setParticipantsInputSearch(value.nom);
        break;
      case "evaluable":
        setIsEvaluableMenuOpen(false);
        setEvaluableInputSearch(value.nom);
      break;
      case "consulte":
        setIsConsulteMenuOpen(false);
        setConsulteInputSearch(value.nom);
        break;
      case "informe":
        setIsInformeMenuOpen(false);
        setInformeInputSearch(value.nom);
      break;
      default:
        break;
    }
  }

  const fetchProjectData = ()=>{
    getProjectById(currDiagramId)
    .then((res)=>{ setProjectInfo(res); setProjectRessources(res.ressources) })
    .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    if(currDiagramId){
      fetchProjectData();
    }
  },[currDiagramId]);

  useEffect(()=>{
    if(!isFullRightBar){
      setSelectedShape({});
      setTimeout(() => {
        setIsToggleSideBarActionCompleted(true);
      }, 350);
    }else{
      setTimeout(() => {
        setIsToggleSideBarActionCompleted(false);
      }, 150);
    }
  },[isFullRightBar]);

  useEffect(()=>{
    if(Object.keys(selectedShape).length > 0){
      setCurrShape({
        name: selectedShape.name,
        description: selectedShape.description,
        participants: selectedShape.participants,
        evaluable: selectedShape.evaluable,
        consulte: selectedShape.consulte,
        informe: selectedShape.informe,
      });

      setParticipantsInputSearch(selectedShape.participants?.nom);
      setEvaluableInputSearch(selectedShape.evaluable?.nom);
      setConsulteInputSearch(selectedShape.consulte?.nom);
      setInformeInputSearch(selectedShape.informe?.nom);
    }else{
      setCurrShape({name: "", description: "", participants: {}, evaluable: {}, consulte: {}, informe: {}});

      setParticipantsInputSearch("");
      setEvaluableInputSearch("");
      setConsulteInputSearch("");
      setInformeInputSearch("");
    }
  },[selectedShape]);

  const propertiesBar = ()=>(
    <div>
      <div className='flex flex-col mb-3' >
        <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.NAME.TITLE")}</label>
        <input
          name='name'
          type='text'
          placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.NAME.PLACEHOLDER")}
          className='border rounded-lg px-2 py-1 text-sm'
          value={currShape.name}
          onChange={handleInputChange}
          onFocus={()=> setIsRightSideBarFocuses(true) }
          onBlur={()=> setIsRightSideBarFocuses(false) }
          disabled={currDiagramAccessType === "READ"}
        />
      </div>
      <div className='flex flex-col mb-3'>
        <div className="flex items-center justify-between mb-0.5" >
          <label className='text-xs ml-2.5'>{t("NAV.RIGHT.PROPERTIES.FIELDS.DESCRIPTION.TITLE")}</label>
          <button onClick={()=> openCommentModal() } >
            <FiMaximize2 size={14} className="text-gray-500" />
          </button>
        </div>
        <textarea
          name='description'
          rows={3}
          placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.DESCRIPTION.PLACEHOLDER")}
          className='border rounded-lg px-2 py-1 text-sm'
          value={currShape.description}
          onChange={handleInputChange}
          onFocus={() => setIsRightSideBarFocuses(true)}
          onBlur={() => setIsRightSideBarFocuses(false)}
          disabled={currDiagramAccessType === "READ"}
        />
      </div>
      {selectedShape.type !== "divideLine" &&
        <div>
          <div className='flex flex-col mb-3 relative' >
          <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.PARTICIPANT")}</label>
          <div className='w-full relative' >
            <input
              name='participants'
              type='text'
              placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.PARTICIPANT")}
              className='border rounded-lg px-2 py-1 pr-6 text-sm w-full'
              value={participantsInputSearch}
              onChange={(e)=> handleParticipantsInputChange(e.target.value)}
              onFocus={()=> setIsRightSideBarFocuses(true) }
              onBlur={()=> setIsRightSideBarFocuses(false) }
              disabled={currDiagramAccessType === "READ"}
            />
            {
              isParticipantsMenuOpen
              ? <FiChevronUp size={16} onClick={()=> setIsParticipantsMenuOpen(false) } className='absolute right-2 top-2 cursor-pointer' />
              : <FiChevronDown size={16} onClick={()=> setIsParticipantsMenuOpen(true) } className='absolute right-2 top-2 cursor-pointer' />
            }
          </div>
          {
            ( currDiagramAccessType !== "READ" && isParticipantsMenuOpen) ?
            <div className='absolute top-12 w-full bg-white border rounded-lg py-2 text-xs z-10' >
              {
                projectRessources.map((it)=>
                  <p key={it.id} className='cursor-pointer hover:bg-gray-100 px-2 py-1' onClick={()=> selectResource("participants", {id: it.id, nom: it.nom}) } >{it.nom}</p>
                )
              }
            </div>
            : null
          }
        </div>

        <div className='flex flex-col mb-3 relative' >
          <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.EVALUABLE")}</label>
          <div className='w-full relative' >
            <input
              name='evaluable'
              type='text'
              placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.EVALUABLE")}
              className='border rounded-lg px-2 py-1 pr-6 text-sm w-full'
              value={evaluableInputSearch}
              onChange={(e)=> handleEvaluableInputChange(e.target.value)}
              onFocus={()=> setIsRightSideBarFocuses(true) }
              onBlur={()=> setIsRightSideBarFocuses(false) }
              disabled={currDiagramAccessType === "READ"}
            />
            {
              isEvaluableMenuOpen
              ? <FiChevronUp size={16} onClick={()=> setIsEvaluableMenuOpen(false) } className='absolute right-2 top-2 cursor-pointer' />
              : <FiChevronDown size={16} onClick={()=> setIsEvaluableMenuOpen(true) } className='absolute right-2 top-2 cursor-pointer' />
            }
          </div>
          {
            (currDiagramAccessType !== "READ" && isEvaluableMenuOpen) ?
            <div className='absolute top-12 w-full bg-white border rounded-lg py-2 text-xs z-10' >
              {
                projectRessources.map((it)=>
                  <p key={it.id} className='cursor-pointer hover:bg-gray-100 px-2 py-1' onClick={()=> selectResource("evaluable", {id: it.id, nom: it.nom}) } >{it.nom}</p>
                )
              }
            </div>
            : null
          }
        </div>

        <div className='flex flex-col mb-3 relative' >
          <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.CONSULTE")}</label>
          <div className='w-full relative' >
            <input
              name='consulte'
              type='text'
              placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.CONSULTE")}
              className='border rounded-lg px-2 py-1 pr-6 text-sm w-full'
              value={consulteInputSearch}
              onChange={(e)=> handleConsulteInputChange(e.target.value)}
              onFocus={()=> setIsRightSideBarFocuses(true) }
              onBlur={()=> setIsRightSideBarFocuses(false) }
              disabled={currDiagramAccessType === "READ"}
            />
            {
              isConsulteMenuOpen
              ? <FiChevronUp size={16} onClick={()=> setIsConsulteMenuOpen(false) } className='absolute right-2 top-2 cursor-pointer' />
              : <FiChevronDown size={16} onClick={()=> setIsConsulteMenuOpen(true) } className='absolute right-2 top-2 cursor-pointer' />
            }
          </div>
          {
            ( currDiagramAccessType !== "READ" && isConsulteMenuOpen) ?
            <div className='absolute top-12 w-full bg-white border rounded-lg py-2 text-xs z-10' >
              {
                projectRessources.map((it)=>
                  <p key={it.id} className='cursor-pointer hover:bg-gray-100 px-2 py-1' onClick={()=> selectResource("consulte", {id: it.id, nom: it.nom}) } >{it.nom}</p>
                )
              }
            </div>
            : null
          }
        </div>

        <div className='flex flex-col mb-3 relative' >
          <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.INFORME")}</label>
          <div className='w-full relative' >
            <input
              name='informe'
              type='text'
              placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.INFORME")}
              className='border rounded-lg px-2 py-1 pr-6 text-sm w-full'
              value={informeInputSearch}
              onChange={(e)=> handleInformeInputChange(e.target.value)}
              onFocus={()=> setIsRightSideBarFocuses(true) }
              onBlur={()=> setIsRightSideBarFocuses(false) }
              disabled={currDiagramAccessType === "READ"}
            />
            {
              isInformeMenuOpen
              ? <FiChevronUp size={16} onClick={()=> setIsInformeMenuOpen(false) } className='absolute right-2 top-2 cursor-pointer' />
              : <FiChevronDown size={16} onClick={()=> setIsInformeMenuOpen(true) } className='absolute right-2 top-2 cursor-pointer' />
            }
          </div>
          {
            ( currDiagramAccessType !== "READ" && isInformeMenuOpen) ?
            <div className='absolute top-12 w-full bg-white border rounded-lg py-2 text-xs z-10' >
              {
                projectRessources.map((it)=>
                  <p key={it.id} className='cursor-pointer hover:bg-gray-100 px-2 py-1' onClick={()=> selectResource("informe", {id: it.id, nom: it.nom}) } >{it.nom}</p>
                )
              }
            </div>
            : null
          }
        </div>
      </div>
      }
      {
        selectedShape.linkedProcessId?.length >= 0 &&
        <div className='flex flex-col mb-3 relative' >
          <label className='text-xs mb-0.5 ml-2.5' >{t("NAV.RIGHT.PROPERTIES.FIELDS.PROCESS")}</label>
          <div className='w-full relative' >
            <input
              name='process'
              type='text'
              placeholder={t("NAV.RIGHT.PROPERTIES.FIELDS.PROCESS")}
              className='border rounded-lg px-2 py-1 pr-6 text-sm w-full'
              value={processInputSearch}
              onChange={(e)=> handleProcessInputChange(e.target.value)}
              onFocus={()=> setIsRightSideBarFocuses(true) }
              onBlur={()=> setIsRightSideBarFocuses(false) }
              disabled={currDiagramAccessType === "READ"}
            />
            {
              isProcessMenuOpen
              ? <FiChevronUp size={16} onClick={()=> setIsProcessMenuOpen(false) } className='absolute right-2 top-2 cursor-pointer' />
              : <FiChevronDown size={16} onClick={()=> setIsProcessMenuOpen(true) } className='absolute right-2 top-2 cursor-pointer' />
            }
          </div>
          {
            ( currDiagramAccessType !== "READ" && isProcessMenuOpen) ?
            <div className='absolute top-12 w-full bg-white border rounded-lg py-2 text-xs z-10' >
              <p className='cursor-pointer hover:bg-gray-100 px-2 py-1' >test</p>
              <p className='cursor-pointer hover:bg-gray-100 px-2 py-1' >test</p>
            </div>
            : null
          }
        </div>
      }
    </div>
  )

  const resourcesBar = ()=>(
    <div className='text-xs flex flex-col gap-3 ' >
      {
        Object.keys(projectRessources).length > 0 && projectRessources.map((it)=>
          <div key={it.id} className='flex items-center justify-between' >
            <p className='text-gray-600' >{it.nom}</p>
            <div className='flex gap-2' >
              <FiEdit2 size={14} onClick={()=> openResourcesModal(it) } className='hover:text-azure cursor-pointer' />
              <FiTrash size={14} onClick={()=> removeResource(it) } className='hover:text-red-600 cursor-pointer' />
            </div>
          </div>
        )
      }
      <FiPlusCircle onClick={()=> openResourcesModal({}) } size={18} className='text-azure hover:text-white hover:bg-azure rounded-full cursor-pointer self-end' />
    </div>
  )

  return (
    <div className={cx('border-r border-t p-2 h-full select-none transition-all duration-300',{'w-64':isFullRightBar, 'w-6': !isFullRightBar} )} >
      {
        isFullRightBar ?
        <div className='flex justify-between items-center mb-4' >
          <p className='text-xs font-semibold' >{ barType === "properties" ? t("NAV.RIGHT.PROPERTIES.TITLE") : t("NAV.RIGHT.RESOURCES.TITLE")}</p>
          { !isToggleSideBarActionCompleted && <FiX className='cursor-pointer' onClick={()=> closeBar() } />}
        </div>
        : isToggleSideBarActionCompleted &&
        <div >
          <p className='text-xs font-semibold rotate-90 cursor-pointer' onClick={()=> openBar("properties") } >{t("NAV.RIGHT.PROPERTIES.TITLE")}</p>
          {
            currDiagramAccessType !== "READ" &&
            <p className='text-xs font-semibold rotate-90 cursor-pointer mt-16' onClick={()=> openBar("resources") } >{t("NAV.RIGHT.RESOURCES.TITLE")}</p>
          }
        </div>
      }
      {
        ( isFullRightBar && !isToggleSideBarActionCompleted) ?
        barType === "properties"
          ? ( Object.keys(selectedShape).length > 0 ? propertiesBar() : t("NAV.RIGHT.PROPERTIES.NONE") )
          : resourcesBar()
        : null
      }

      <Modal isOpen={isResourcesModalOpen} title={t("NAV.RIGHT.RESOURCES.TITLE")} close={closeResourcesModal} >
        <ResourceModal
          data={resourcesModalData}
          close={closeResourcesModal}
          projectRessources={projectRessources}
          setProjectRessources={setProjectRessources}
        />
      </Modal>
      <Modal isOpen={isFullCommentModalOpen} title={t("NAV.RIGHT.PROPERTIES.FIELDS.DESCRIPTION.TITLE")} close={closeCommentModal} >
        <FullCommentModal close={closeCommentModal} currShape={currShape} handleInputChange={handleInputChange} />
      </Modal>
    </div>
  )
}
