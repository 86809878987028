import React, {useState, useEffect, useRef, useContext} from 'react';
import { useForm } from "react-hook-form";
import { FiUser, FiLock } from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import moment from 'moment';
import 'moment/locale/fr';

import { loginEvent } from '../Context/events';
import { getMyInfo, updateMyAccount } from '../services/accountService';
import { UserContext } from '../Context/UserContext';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deleteFolder} from "../services/folderService";

export default function Account() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getUserData, user} = useContext(UserContext);

  const [currTab, setCurrTab] = useState(0);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const signOut = ()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit("login", false);
  }

  const submit = (data) =>{
    setIsUpdateLoading(true);

    const formData = new FormData();

    const userData = {
      email: user.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: true,
      password: null,
      roles: user.roles
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    updateMyAccount(formData)
    .then((res)=>{
      console.log(res);
      getUserData();
    })
    .catch((err)=> console.log(err) )
    .finally(()=> setIsUpdateLoading(false) )
  }

  const submitPassword = (data) =>{
    setIsUpdateLoading(true);

    const formData = new FormData();

    const userData = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      enabled: true,
      password: data.password,
      roles: user.roles
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    updateMyAccount(formData)
    .then((res)=>{
      getUserData();
      withReactContent(Swal).fire({
        title: t("GLOBAL.UPDATED_SUCCESS"),
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "lightgray",
        cancelButtonColor: "lightgray",
        confirmButtonText: t("GLOBAL.BACK")
      }).then((result) => {
        if (result.isConfirmed) {
          setValue('password', '');
        }
      })
    })
    .catch((err)=> console.log(err) )
    .finally(()=> setIsUpdateLoading(false) )
  }

  useEffect(()=>{
    if(Object.keys(user).length > 0){
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
    }
  },[user]);

  if(isFetchLoading){
    return(
      <div className='min-h-[80vh] w-full flex items-center justify-center relative z-50 bg-white' >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return(
    <div className='px-4 lg:px-0 flex gap-8'  >
      <div className='w-1/4 relative' >
        <h1 className='font-semibold bg-gray-100 absolute top-0 left-3 px-2 translate-y-[-50%]' >{t("GLOBAL.ACCOUNT")}</h1>
        <div className='border rounded-xl p-3 pt-5' >
          <ul className='flex flex-col gap-2' >
            <li onClick={()=> setCurrTab(0) } className={cx('flex items-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-200 cursor-pointer', {'bg-azure text-white hover:text-black': currTab === 0})} >
              <FiUser/>
              <span className='text-sm font-semibold' >{t("HOME.ACCOUNT.NAV.PROFILE")}</span>
            </li>
            <li onClick={()=> setCurrTab(1) } className={cx('flex items-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-200 cursor-pointer', {'bg-azure text-white hover:text-black': currTab === 1})} >
              <FiLock/>
              <span className='text-sm font-semibold' >{t("HOME.ACCOUNT.NAV.PASSWORD")}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className='w-3/4' >
        <div className='flex items-center justify-between gap-3 border-b pb-2' >
          <div className='flex items-center gap-3' >
            <div className='h-12 w-12 border rounded-full flex items-center justify-center' >
              <FiUser size={28} className='text-gray-500' />
            </div>
            <div>
              <p className='font-bold' >{user.firstName} {user.lastName}</p>
              <p className='text-gray-500 text-xs' >{user.roles?.map((it)=> it.name).join(', ')}</p>
            </div>
          </div>
          {/* <div onClick={()=> signOut() } className='flex items-center gap-2 border p-1 rounded-xl cursor-pointer text-gray-500 hover:border-[#D6323A] hover:text-[#D6323A]' >
            <FiLogOut/>
            <span className='text-xs font-semibold' >{t("COMMON.SIGNOUT")}</span>
          </div> */}
        </div>
        {
          isUpdateLoading ?
          <div>
            <div className='loader' />
          </div>
          :
          currTab === 0 ?
            <form onSubmit={handleSubmit(submit)} className='flex flex-col w-full mt-6' >
              <div className='w-1/2' >
                <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.FIRST_NAME")}</label>
                  { errors.firstName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
                </div>
                <input
                  className='border rounded-lg px-3 py-2 text-sm w-full mb-4 hover:border-brome'
                  placeholder={t("GLOBAL.FIRST_NAME")}
                  {...register("firstName", { required: true })}
                />

                <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.LAST_NAME")}</label>
                  { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
                </div>
                <input
                  className='border rounded-lg px-3 py-2 text-sm w-full mb-4 hover:border-brome'
                  placeholder={t("GLOBAL.LAST_NAME")}
                  {...register("lastName", { required: true})}
                />

                <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.EMAIL")}</label>
                  { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
                </div>
                <input
                  className='border rounded-lg px-3 py-2 text-sm w-full mb-4 hover:border-brome '
                  placeholder={ user?.email ? user.email : t("GLOBAL.EMAIL")}
                  disabled
                />
              </div>
              <div className='flex justify-end' >
                <input
                  disabled={isUpdateLoading}
                  type="submit"
                  value={t("GLOBAL.SAVE")}
                  className='text-sm font-semibold bg-azure px-5 min-w-36 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg'
                />
              </div>
            </form>
          :
            <div className='flex flex-col w-full mt-6' >
              <h4 className='text-gray-500 font-semibold text-lg pl-1' >{t("HOME.ACCOUNT.CHANGE_PASSWORD_TITLE")}</h4>
              <form onSubmit={handleSubmit(submitPassword)} className='flex flex-col w-full mt-3' >
                <div className='w-1/2' >
                  <div className='px-2.5 mb-0.5 flex items-center justify-between' >
                    {/* <label onClick={()=> console.log(errors.password) } className='text-xs text-gray-500 font-semibold' >{t("GLOBAL.PASSWORD")}</label> */}
                    { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("GLOBAL.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-3 py-2 text-sm w-full'
                    placeholder={t("GLOBAL.PASSWORD")}
                    // {...register("password", { required: false })}
                    {...register("password", {
                      required: "required",
                      minLength: {
                        value: 8,
                        message: "Le mot de passe doit contenir au moins 8 caractères"
                      },
                      maxLength: {
                        value: 16,
                        message: "Le mot de passe ne doit pas dépasser 16 caractères"
                      },
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                        message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                      }
                    })}
                  />
                  <div className='px-3.5' >
                    <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
                  </div>
                </div>
                <div className='flex justify-end' >
                  <input
                    disabled={isUpdateLoading}
                    type="submit"
                    value={t("GLOBAL.SAVE")}
                    className='text-sm font-semibold bg-azure px-5 min-w-36 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg'
                  />
                </div>
              </form>
            </div>
        }
      </div>
      {/* <ErrorModal isOpen={isErrorModalOpen} close={closeErrorModal} title={t(`${errorCode}`)} /> */}
    </div>
  )
}
