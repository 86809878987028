import React, { useState, useRef, useEffect, useContext } from 'react';
import styles from '../styles/header.module.scss';
import BpmnModdle from 'bpmn-moddle';
import cx from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import isEqual from 'fast-deep-equal';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { Document, Packer, Paragraph, TextRun, HeadingLevel, Media, AlignmentType, ImageRun, Header as DocxHeader } from "docx";
import { ReactComponent as BpmFileIcon } from '../assets/bpmFile.svg';
import {
  FiFile,
  FiFolder,
  FiGlobe,
  FiSettings,
  FiUser,
  FiExternalLink,
  FiDownload,
  FiRotateCcw,
  FiRotateCw,
  FiChevronDown,
  FiBold,
  FiItalic,
  FiUnderline,
  FiLogOut,
  FiUpload,
  FiSave,
  FiChevronRight,
  FiChevronLeft,
  FiPlus, FiX, FiHome
} from "react-icons/fi";
import { MdOutlineFormatColorText, MdFormatColorFill, MdTextDecrease, MdTextIncrease } from "react-icons/md";
import { BsFiletypeXml, BsFileEarmarkWord } from "react-icons/bs";
import { GithubPicker, SketchPicker, TwitterPicker } from 'react-color';

import { undoEvent, redoEvent, loginEvent } from '../Context/events';
import { UserContext } from '../Context/UserContext';
import Modal from './Modal';
import { getProjectById, getProjectCurrVersion, getProjectInfosById, updateProject } from '../services/projectService';
import { DragContext } from '../Context/DragContext';
import bromeLogo from '../assets/brome-logo.png';
import FullModal from "./FullModal";
import {folderContent, getAllFolders} from "../services/folderService";
import {FolderContainer, NewProjectModal} from "./Folders";
import {NewDiagramModal} from "./MyDiagrams";

function findParentProcessId(element, tagName) {
  if(element.tagName === "bpmn:textAnnotation"){
    let parent = element.parentNode;
    let namespaceURI = "http://www.omg.org/spec/BPMN/20100524/MODEL";
    let participantElements = parent.getElementsByTagNameNS(namespaceURI, 'participant');
    if (participantElements.length > 0) {
      let processRef = participantElements[0].getAttribute('processRef');
      return processRef;
    } else {
      return null
    }
  }else{
    let parent = element.parentNode;
    while (parent && parent.nodeName !== tagName) {
      parent = parent.parentNode;
    }
    return parent ? parent.getAttribute('id') : null;
  }
}

const convertToBpmnElementsV2 = (customShapes) => {
  const bpmnShapes = [];
  const bpmnFlows = [];
  let bpmnProcesses = [];

  customShapes.forEach(shape => {
    const baseShape = {
      id: shape.id,
      x: shape.x,
      y: shape.y,
      width: shape.width,
      height: shape.height,
      type: `bpmn:${shape.shapeType}`,
      famillyType: shape.family,
      shapeType: shape.shapeType,
      name: shape.name,
      description: shape.description,
      radius: shape.radius,
      borderWidth: shape.borderWidth,
      borderColor: shape.borderColor,
      bgColor: shape.bgColor,
      processId: shape.processId,
      eventDefinitions: "",
      participants: shape.participants,
      evaluable: shape.evaluable,
      consulte: shape.consulte,
      informe: shape.informe,
      fontWeight: shape.fontWeight,
      fontStyle: shape.fontStyle,
      textDecoration: shape.textDecoration,
      fontSize: shape.fontSize,
      fontColor: shape.fontColor
    };
    console.log(shape)
    if(shape.shapeType !== shape.family){
      baseShape.type = `bpmn:${shape.family}`;
      baseShape.eventDefinitions = `bpmn:${shape.shapeType}`;
    }
    if(shape.shapeType === "process"){
      // bpmnProcess = baseShape;
      baseShape.divideLine = shape.divideLine;
      const existingProcessIndex = bpmnProcesses.findIndex(p => p.id === shape.id);
      if (existingProcessIndex === -1) {
        bpmnProcesses.push(baseShape);
      } else {
        // Update existing process if necessary
        bpmnProcesses[existingProcessIndex] = {...bpmnProcesses[existingProcessIndex], ...baseShape};
      }
    }else if(shape.type !== 'arrow') {
      bpmnShapes.push(baseShape);
    }

    if (shape.type === 'arrow') {
      const flow = {
        id: shape.id,
        sourceRef: shape.from, // Determine source
        targetRef: shape.to, // Determine target
        name: shape.name,
        description: shape.description,
        processId: shape.processId,
        famillyType: shape.type,
        shapeType: shape.shapeType,
        waypoints: shape.points.map((point, index) => ({ x: shape.points[index], y: shape.points[index + 1] })).filter((_, idx) => idx % 2 === 0)
      };
      bpmnFlows.push(flow);
    }
  });

  bpmnProcesses.forEach(process => {
    process.shapes = bpmnShapes.filter(shape => shape.processId === process.id);
    process.flows = bpmnFlows.filter(flow => flow.processId === process.id);
  });
  return { process: bpmnProcesses, shapes: bpmnShapes, flows: bpmnFlows };
};

const convertToBpmnElements = (customShapes) => {
  const bpmnShapes = [];
  const bpmnFlows = [];
  let bpmnProcess = null;
  customShapes.forEach(shape => {
    const baseShape = {
      id: shape.id,
      x: shape.x,
      y: shape.y,
      width: shape.width,
      height: shape.height,
      type: `bpmn:${shape.shapeType}`,
      famillyType: shape.type,
      shapeType: shape.shapeType,
      name: shape.name,
      description: shape.description,
      radius: shape.radius,
      borderWidth: shape.borderWidth,
      borderColor: shape.borderColor,
      bgColor: shape.bgColor,
      processId: shape.processId,
      eventDefinitions: "",
    };
    if(shape.shapeType !== shape.family){
      baseShape.type = `bpmn:${shape.family}`;
      baseShape.eventDefinitions = shape.shapeType
    }
    if(shape.shapeType === "process"){
      bpmnProcess = baseShape;
    }else if(shape.type !== 'arrow') {
      bpmnShapes.push(baseShape);
    }

    if (shape.type === 'arrow') {
      const flow = {
        id: shape.id,
        sourceRef: shape.from, // Determine source
        targetRef: shape.to, // Determine target
        name: shape.name,
        description: shape.description,
        processId: shape.processId,
        famillyType: shape.type,
        shapeType: shape.shapeType,
        waypoints: shape.points.map((point, index) => ({ x: shape.points[index], y: shape.points[index + 1] })).filter((_, idx) => idx % 2 === 0)
      };
      bpmnFlows.push(flow);
    }
  });

  return { process: bpmnProcess, shapes: bpmnShapes, flows: bpmnFlows };
};

export function parseXML(xmlString, changeShapes) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");

  const bpmnDefinitions_1 = xmlDoc.getElementsByTagName("bpmn:definitions")[0];

  const bpmnShapeTags = [
    'bpmn:process',
    'bpmn:subProcess',
    'bpmn:task', 'bpmn:serviceTask', 'bpmn:receiveTask', 'bpmn:sendTask', 'bpmn:scriptTask', 'bpmn:manualTask', 'bpmn:businessRuleTask', 'bpmn:userTask',
    'bpmn:startEvent', 'bpmn:endEvent',
    'bpmn:exclusiveGateway', 'bpmn:inclusiveGateway', 'bpmn:parallelGateway', 'bpmn:eventBasedGateway', 'bpmn:complexGateway',
    'bpmn:intermediateThrowEvent', 'bpmn:intermediateCatchEvent',
    'bpmn:sequenceFlow',
    'bpmn:textAnnotation',
    'bpmn:laneSet', 'bpmn:lane',
  ];
  const shapeTags = [
    'process',
    'subProcess',
    'task', 'serviceTask', 'receiveTask', 'sendTask', 'scriptTask', 'manualTask', 'businessRuleTask', 'userTask',
    'startEvent', 'endEvent',
    'exclusiveGateway', 'inclusiveGateway', 'parallelGateway', 'eventBasedGateway', 'complexGateway',
    'intermediateThrowEvent', 'intermediateCatchEvent',
    'sequenceFlow',
    'textAnnotation'
  ];
  let shapes = [];

  if(bpmnDefinitions_1.getAttribute('targetNamespace') !== 'http://bpmn.io/schema/bpmn'){
    shapeTags.forEach(tag => {
      const elements = xmlDoc.getElementsByTagName(tag);
      for (let element of elements) {
        let shape = {
          id: element.getAttribute('id'),
          name: element.getAttribute('name'),
          processId: findParentProcessId(element, 'process')
        };

        if(tag === 'process'){
          const bpmnShapes = xmlDoc.getElementsByTagName('BPMNShape');
          for (let bpmnShape of bpmnShapes) {
            if (bpmnShape.getAttribute('bpmnElement') === shape.id){
              const bounds = bpmnShape.getElementsByTagName('Bounds')[0];
              shape.x= bounds.getAttribute('x') ? bounds.getAttribute('x') : "";
              shape.y= bounds.getAttribute('y') ? bounds.getAttribute('y') : "";
              shape.description= bounds.getAttribute('description') ? bounds.getAttribute('description') : "";
              shape.participants= {nom: bounds.getAttribute('participantsName') ? bounds.getAttribute('participantsName') : "", id: bounds.getAttribute('participantsId') ? bounds.getAttribute('participantsId') : ""};
              shape.evaluable= {nom: bounds.getAttribute('evaluableName') ? bounds.getAttribute('evaluableName') : "", id: bounds.getAttribute('evaluableId') ? bounds.getAttribute('evaluableId') : ""};
              shape.consulte= {nom: bounds.getAttribute('consulteName') ? bounds.getAttribute('consulteName') : "", id: bounds.getAttribute('consulteId') ? bounds.getAttribute('consulteId') : ""};
              shape.informe= {nom: bounds.getAttribute('informeName') ? bounds.getAttribute('informeName') : "", id: bounds.getAttribute('informeId') ? bounds.getAttribute('informeId') : ""};
            }
          }
          shape.type= 'process';
          shape.shapeType= 'process';
          shape.x = 0;
          shape.y = 0;
          shape.width = 700;
          shape.height = 400;
          shape.cornerRadius= 10;
          shape.borderWidth= 1;
          shape.borderColor= '#000';
          shape.bgColor= 'transparent';
          shape.name= "Processus";
          shape.divideLine= 1
        } else if (tag === 'subProcess' ){
          shape.type = 'rectangle';
          shape.shapeType = 'SubProcess';
          shape.family = 'SubProcess';
        }
        else if (tag === 'sequenceFlow') {
          // Handle sequence flows differently
          const bpmnEdges = xmlDoc.getElementsByTagName('BPMNEdge');
          for (let edge of bpmnEdges) {
            if (edge.getAttribute('bpmnElement') === shape.id) {
              // Extract waypoints for the sequence flow
              shape.name = edge.getAttribute('name');
              shape.from = element.getAttribute('sourceRef');
              shape.to = element.getAttribute('targetRef');
              shape.points = [];
              // shape.processId= edge.getAttribute('processId');
              shape.type= "arrow";
              shape.shapeType= edge.getAttribute('shapeType') ? edge.getAttribute('shapeType') : "Sequence" ;
              shape.description= edge.getAttribute('description');

              let diWaypointLength = edge.getElementsByTagName('di:waypoint').length;
              let waypointLength = edge.getElementsByTagName('waypoint').length;
              const waypoints = diWaypointLength > waypointLength ? edge.getElementsByTagName('di:waypoint') : edge.getElementsByTagName('waypoint');

              for (let waypoint of waypoints) {
                shape.points.push(parseFloat(waypoint.getAttribute('x')), parseFloat(waypoint.getAttribute('y')));
              }
              break;
            }
          }
        }else{
          // Find corresponding 'BPMNShape' element
          const bpmnShapes = xmlDoc.getElementsByTagName('BPMNShape');
          for (let bpmnShape of bpmnShapes) {
            if (bpmnShape.getAttribute('bpmnElement') === shape.id) {
              const bounds = bpmnShape.getElementsByTagName('Bounds')[0];
              shape.x = parseFloat(bounds.getAttribute('x'));
              shape.y = parseFloat(bounds.getAttribute('y'));
              shape.width = parseFloat(bounds.getAttribute('width'));
              shape.height = parseFloat(bounds.getAttribute('height'));

              if (['startEvent', 'intermediateThrowEvent', 'endEvent'].includes(tag)) {
                const eventDefinitionTags = [
                  'messageEventDefinition', 'timerEventDefinition', 'signalEventDefinition', 'conditionalEventDefinition', 'errorEventDefinition',
                  'terminateEventDefinition', 'compensateEventDefinition', 'escalationEventDefinition', 'cancelEventDefinition', 'multipleEndEvent',
                  'linkEventDefinition', 'intermediateParallelMultipleEvent', 'intermediateMultipleEvent'
                ];
                for (let defTag of eventDefinitionTags) {
                  const eventDef = element.getElementsByTagName(defTag);
                  console.log(defTag, eventDef);
                  if (eventDef.length > 0) {
                    shape.shapeType = defTag.charAt(0).toUpperCase() + defTag.substring(1);;
                    break; // Break after finding the first event definition
                  }else{
                    shape.shapeType= tag.charAt(0).toUpperCase() + tag.substring(1);
                  }
                }
              }

              switch (tag) {
                case "startEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'StartEvent';
                  shape.family = 'StartEvent';
                  shape.radius= parseFloat(bounds.getAttribute('height'))/2;
                  break;
                case "task":
                case "userTask":
                case "serviceTask":
                case "receiveTask":
                case "sendTask":
                case "scriptTask":
                case "manualTask":
                case "businessRuleTask":
                  shape.type= 'rectangle';
                  shape.shapeType= tag.charAt(0).toUpperCase() + tag.substring(1);
                  shape.family = tag.charAt(0).toUpperCase() + tag.substring(1);
                  break;
                case "endEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'EndEvent';
                  shape.family = 'EndEvent';
                  shape.radius= parseFloat(bounds.getAttribute('height'))/2;
                  break;
                case "intermediateThrowEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'IntermediateCatchEvent';
                  shape.family = 'IntermediateCatchEvent';
                  shape.radius= parseFloat(bounds.getAttribute('height'))/2;
                  break;
                case "exclusiveGateway":
                  shape.type= 'square';
                  shape.shapeType= tag.charAt(0).toUpperCase() + tag.substring(1);
                  shape.family = tag.charAt(0).toUpperCase() + tag.substring(1);
                  break;
                default:
                  break;
              }
              // shape.radius= parseFloat(bounds.getAttribute('radius'));
              shape.borderWidth= parseFloat(bounds.getAttribute('borderWidth')) ? parseFloat(bounds.getAttribute('borderWidth')) : 2 ;
              shape.borderColor= bounds.getAttribute('borderColor') ? bounds.getAttribute('borderColor') : "#000";
              shape.bgColor= bounds.getAttribute('bgColor');
              // shape.processId= bounds.getAttribute('processId');
              // shape.type= bounds.getAttribute('famillyType');
              // shape.shapeType= bounds.getAttribute('shapeType') ? bounds.getAttribute('shapeType') : "";
              shape.description= bounds.getAttribute('description') ? bounds.getAttribute('description') : "";
              shape.participants= {nom: bounds.getAttribute('participantsName') ? bounds.getAttribute('participantsName') : "", id: bounds.getAttribute('participantsId') ? bounds.getAttribute('participantsId') : ""};
              shape.evaluable= {nom: bounds.getAttribute('evaluableName') ? bounds.getAttribute('evaluableName') : "", id: bounds.getAttribute('evaluableId') ? bounds.getAttribute('evaluableId') : ""};
              shape.consulte= {nom: bounds.getAttribute('consulteName') ? bounds.getAttribute('consulteName') : "", id: bounds.getAttribute('consulteId') ? bounds.getAttribute('consulteId') : ""};
              shape.informe= {nom: bounds.getAttribute('informeName') ? bounds.getAttribute('informeName') : "", id: bounds.getAttribute('informeId') ? bounds.getAttribute('informeId') : ""};
              break; // Exit the loop once the matching shape is found
            }
          }
        }
        shapes.push(shape);
      }
    });

  }else{
    bpmnShapeTags.forEach(tag => {
      const elements = xmlDoc.getElementsByTagName(tag);
      for (let element of elements) {
        let shape = {
          id: element.getAttribute('id'),
          name: element.getAttribute('name'),
          processId: findParentProcessId(element, "bpmn:process"),
        };
        if(tag === 'bpmn:process'){
          const bpmnShapes = xmlDoc.getElementsByTagName('bpmndi:BPMNShape');
          for (let bpmnShape of bpmnShapes) {
            if (bpmnShape.getAttribute('bpmnElement') === shape.id){
              const bounds = bpmnShape.getElementsByTagName('dc:Bounds')[0];
              shape.x= bounds.getAttribute('x') ? bounds.getAttribute('x') : "";
              shape.y= bounds.getAttribute('y') ? bounds.getAttribute('y') : "";
              shape.width = bounds.getAttribute('width') ? bounds.getAttribute('width') : 700;
              shape.height = bounds.getAttribute('height') ? bounds.getAttribute('height') : 400;
              shape.divideLine = bounds.getAttribute('divideLine') ? parseInt(bounds.getAttribute('divideLine')) : 1;
              shape.description= bounds.getAttribute('description') ? bounds.getAttribute('description') : "";
              shape.participants= {nom: bounds.getAttribute('participantsName') ? bounds.getAttribute('participantsName') : "", id: bounds.getAttribute('participantsId') ? bounds.getAttribute('participantsId') : ""};
              shape.evaluable= {nom: bounds.getAttribute('evaluableName') ? bounds.getAttribute('evaluableName') : "", id: bounds.getAttribute('evaluableId') ? bounds.getAttribute('evaluableId') : ""};
              shape.consulte= {nom: bounds.getAttribute('consulteName') ? bounds.getAttribute('consulteName') : "", id: bounds.getAttribute('consulteId') ? bounds.getAttribute('consulteId') : ""};
              shape.informe= {nom: bounds.getAttribute('informeName') ? bounds.getAttribute('informeName') : "", id: bounds.getAttribute('informeId') ? bounds.getAttribute('informeId') : ""};
            }
          }
          shape.type= 'process';
          shape.shapeType= 'process';
          // shape.width = 700;
          // shape.height = 400;
          shape.cornerRadius= 10;
          shape.borderWidth= 1;
          shape.borderColor= '#000';
          shape.bgColor= 'transparent';
          // shape.divideLine= 1
        }
        if (tag === 'bpmn:lane') {
          shape.type = 'divideLine';
          shape.shapeType = 'Lane';
          shape.participants = [];
        } else if (tag === 'bpmn:laneSet') {
          return null;
          // shape.type = 'laneSet';
          // shape.shapeType = 'LaneSet';
          // shape.lanes = [];
          // const laneElements = element.getElementsByTagName('bpmn:lane');
          // for (let lane of laneElements) {
          //   shape.lanes.push({
          //     id: lane.getAttribute('id'),
          //     name: lane.getAttribute('name')
          //   });
          // }
        }
        if(tag === "bpmn:textAnnotation"){
          shape.description = element.getElementsByTagName('bpmn:text').length > 0 ? element.getElementsByTagName('bpmn:text')[0].textContent : "";
          shape.type = 'TextAnnotation';
          shape.shapeType = 'TextAnnotation';
          shape.family = 'TextAnnotation';
        }
        if (tag === 'bpmn:subProcess' ){
          shape.type = 'rectangle';
          shape.shapeType = 'SubProcess';
          shape.family = 'SubProcess';
        }
        if (tag === 'bpmn:sequenceFlow') {
          // Handle sequence flows differently
          const bpmnEdges = xmlDoc.getElementsByTagName('bpmndi:BPMNEdge');
          for (let edge of bpmnEdges) {
            if (edge.getAttribute('bpmnElement') === shape.id) {
              // Extract waypoints for the sequence flow
              shape.name = edge.getAttribute('name');
              shape.from = element.getAttribute('sourceRef');
              shape.to = element.getAttribute('targetRef');
              shape.points = [];
              // shape.processId= edge.getAttribute('processId');
              shape.type= "arrow";
              shape.shapeType= edge.getAttribute('shapeType') ? edge.getAttribute('shapeType') : "Sequence" ;
              shape.description= edge.getAttribute('description');
              const waypoints = edge.getElementsByTagName('di:waypoint');
              for (let waypoint of waypoints) {
                shape.points.push(parseFloat(waypoint.getAttribute('x')), parseFloat(waypoint.getAttribute('y')));
              }
              break;
            }
          }
        } else{
          // Find corresponding 'bpmndi:BPMNShape' element
          const bpmnShapes = xmlDoc.getElementsByTagName('bpmndi:BPMNShape');
          for (let bpmnShape of bpmnShapes) {
            if (bpmnShape.getAttribute('bpmnElement') === shape.id) {
              const bounds = bpmnShape.getElementsByTagName('dc:Bounds')[0];
              shape.x = parseFloat(bounds.getAttribute('x'));
              shape.y = parseFloat(bounds.getAttribute('y'));
              shape.width = parseFloat(bounds.getAttribute('width'));
              shape.height = parseFloat(bounds.getAttribute('height'));

              if (['bpmn:startEvent', 'bpmn:intermediateThrowEvent', 'bpmn:intermediateCatchEvent', 'bpmn:endEvent'].includes(tag)) {
                const eventDefinitionTags = [
                  'bpmn:messageEventDefinition', 'bpmn:timerEventDefinition', 'bpmn:signalEventDefinition', 'bpmn:conditionalEventDefinition', 'bpmn:errorEventDefinition',
                  'bpmn:terminateEventDefinition', 'bpmn:compensateEventDefinition', 'bpmn:escalationEventDefinition', 'bpmn:cancelEventDefinition', 'bpmn:multipleEndEvent',
                  'bpmn:linkEventDefinition', 'bpmn:intermediateParallelMultipleEvent', 'bpmn:intermediateMultipleEvent'
                ];
                for (let defTag of eventDefinitionTags) {
                  const eventDef = element.getElementsByTagName(defTag);
                   if (eventDef.length > 0) {
                    let type = defTag.replace("bpmn:", "");
                    shape.shapeType = type.charAt(0).toUpperCase() + type.substring(1);
                    break;
                  }
                   else{
                    let type = tag.replace("bpmn:", "");
                    shape.shapeType= type.charAt(0).toUpperCase() + type.substring(1);
                  }
                }
              }

              switch (tag) {
                case "bpmn:startEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'StartEvent';
                  shape.family = 'StartEvent';
                  break;
                case "bpmn:task":
                case "bpmn:userTask":
                case "bpmn:serviceTask":
                case "bpmn:receiveTask":
                case "bpmn:sendTask":
                case "bpmn:scriptTask":
                case "bpmn:manualTask":
                case "bpmn:businessRuleTask":
                  shape.type= 'rectangle';
                  var type = tag.replace("bpmn:", "");
                  shape.shapeType= type.charAt(0).toUpperCase() + type.substring(1);
                  shape.family = type.charAt(0).toUpperCase() + type.substring(1);
                  break;
                case "bpmn:endEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'EndEvent';
                  shape.family = 'EndEvent';
                  break;
                case "bpmn:intermediateCatchEvent":
                  shape.type= 'circle';
                  // shape.shapeType= 'intermediateCatchEvent';
                  shape.family = 'IntermediateCatchEvent';
                  break;
                case "bpmn:exclusiveGateway":
                case "bpmn:inclusiveGateway":
                case "bpmn:parallelGateway":
                case "bpmn:eventBasedGateway":
                case "bpmn:complexGateway":
                  shape.type= 'square';
                  var type = tag.replace("bpmn:", "");
                  shape.shapeType= type.charAt(0).toUpperCase() + type.substring(1);
                  shape.family = type.charAt(0).toUpperCase() + type.substring(1);
                  break;
                default:
                  break;
              }
              shape.radius= parseFloat(bounds.getAttribute('radius')) ? parseFloat(bounds.getAttribute('radius')) : parseFloat(bounds.getAttribute('width'))/2 ;
              shape.borderWidth= parseFloat(bounds.getAttribute('borderWidth')) ? parseFloat(bounds.getAttribute('borderWidth')) : 2 ;
              shape.borderColor= bounds.getAttribute('borderColor') ? bounds.getAttribute('borderColor') : "#000";
              shape.bgColor= bounds.getAttribute('bgColor');
              // shape.processId= bounds.getAttribute('processId');
              // shape.type= bounds.getAttribute('famillyType');
              // shape.shapeType= bounds.getAttribute('shapeType') ? bounds.getAttribute('shapeType') : "";
              tag !== "bpmn:textAnnotation" && (shape.description= bounds.getAttribute('description') ? bounds.getAttribute('description') : "");
              shape.participants= {nom: bounds.getAttribute('participantsName') ? bounds.getAttribute('participantsName') : "", id: bounds.getAttribute('participantsId') ? bounds.getAttribute('participantsId') : ""};
              shape.evaluable= {nom: bounds.getAttribute('evaluableName') ? bounds.getAttribute('evaluableName') : "", id: bounds.getAttribute('evaluableId') ? bounds.getAttribute('evaluableId') : ""};
              shape.consulte= {nom: bounds.getAttribute('consulteName') ? bounds.getAttribute('consulteName') : "", id: bounds.getAttribute('consulteId') ? bounds.getAttribute('consulteId') : ""};
              shape.informe= {nom: bounds.getAttribute('informeName') ? bounds.getAttribute('informeName') : "", id: bounds.getAttribute('informeId') ? bounds.getAttribute('informeId') : ""};

              shape.fontWeight= bounds.getAttribute('fontWeight') ? bounds.getAttribute('fontWeight') : "";
              shape.fontStyle= bounds.getAttribute('fontStyle') ? bounds.getAttribute('fontStyle') : "";
              shape.textDecoration= bounds.getAttribute('textDecoration') ? bounds.getAttribute('textDecoration') : "";
              shape.fontSize= parseFloat(bounds.getAttribute('fontSize')) ? parseFloat(bounds.getAttribute('fontSize')) : 12 ;
              shape.fontColor = bounds.getAttribute('fontColor') ? bounds.getAttribute('fontColor') : "#000";
              break; // Exit the loop once the matching shape is found
            }
          }
        }
        shapes.push(shape);
      }
    });
  }
  changeShapes(shapes)
}

export async function shapesToXMLV2(customShapes){
  const { process: processArr, shapes, flows } = convertToBpmnElementsV2(customShapes);

  console.log(processArr);

  if (!processArr) {
    console.error('No process defined');
    return;
  }

  let rootElements = [];
  let participants = [];
  let laneSets = {};

  const moddle = new BpmnModdle();

  const collaboration = moddle.create('bpmn:Collaboration', {
    id: 'Collaboration_1'
  });

  const bpmnPlane = moddle.create('bpmndi:BPMNPlane', {
    id: 'BPMNPlane_1',
    bpmnElement: collaboration,
    planeElement: []
  });

  // shapes.filter(shape => shape.type === 'bpmn:Lane').forEach(shape => {
  //   let laneSet = laneSets[shape.processId];
  //   if (!laneSet) {
  //     laneSet = moddle.create('bpmn:LaneSet', { id: `LaneSet_${shape.processId}` });
  //     laneSets[shape.processId] = laneSet;
  //   }
  //   const lane = moddle.create('bpmn:Lane', {
  //     id: shape.id,
  //     name: shape.name || ""
  //   });
  //   laneSet.get('lanes').push(lane);
  // });
  shapes.filter(shape => shape.type === 'bpmn:Lane').forEach(shape => {
    let laneSet = laneSets[shape.processId];
    if (!laneSet) {
      laneSet = moddle.create('bpmn:LaneSet', { id: `LaneSet_${shape.processId}` });
      laneSets[shape.processId] = laneSet;
    }

    const lane = moddle.create('bpmn:Lane', {
      id: shape.id,
      name: shape.name || ""
    });
    laneSet.get('lanes').push(lane);

    console.log(shape);
    const bpmnShape = moddle.create('bpmndi:BPMNShape', {
      id: `BPMNShape_${shape.id}`,
      bpmnElement: lane,
      bounds: moddle.create('dc:Bounds', {
        x: shape.x,
        y: shape.y,
        width: shape.width,
        height: shape.height
      })
    });

    bpmnPlane.planeElement.push(bpmnShape);
  });

  shapes.filter(shape => shape.type === 'bpmn:TextAnnotation').forEach(shape => {
    const textAnnotation = moddle.create('bpmn:TextAnnotation', {
      id: shape.id,
      text: shape.description
    });

    participants.push(textAnnotation);

    const annotationShape = moddle.create('bpmndi:BPMNShape', {
      id: `BPMNShape_${shape.id}`,
      bpmnElement: textAnnotation,
      bounds: moddle.create('dc:Bounds', {
        x: shape.x,
        y: shape.y,
        width: shape.width,
        height: shape.height,
        description: shape.description,
        fontWeight: shape.fontWeight,
        fontStyle: shape.fontStyle,
        textDecoration: shape.textDecoration,
        fontSize: shape.fontSize,
        fontColor: shape.fontColor,
        bgColor: shape.bgColor,
      })
    });

    bpmnPlane.planeElement.push(annotationShape);
  });

  processArr.forEach((processObj)=>{
    const bpmnProcess = moddle.create('bpmn:Process', {
      id: processObj.id,
      name: processObj.name,
      isExecutable: true,
      flowElements: [],
      laneSets: Object.keys(laneSets).length > 0 ? [laneSets[processObj.id]] || [] : []
    });

    const participant = moddle.create('bpmn:Participant', {
      id: `Participant_${processObj.id}`,
      processRef: bpmnProcess
    });
    participants.push(participant);

    const bpmnProcessShape = moddle.create('bpmndi:BPMNShape', {
      id: `BPMNShape_${processObj.id}`,
      bpmnElement: bpmnProcess,
      bounds: moddle.create('dc:Bounds', {
        x: processObj.x,
        y: processObj.y,
        width: processObj.width,
        height: processObj.height,
        description: processObj.description,
        participantsName: processObj.participants.nom,
        participantsId: processObj.participants.id,
        evaluableName: processObj.evaluable.nom,
        evaluableId: processObj.evaluable.id,
        consulteName: processObj.consulte.nom,
        consulteId: processObj.consulte.id,
        informeName: processObj.informe.nom,
        informeId: processObj.informe.id,
        fontWeight: processObj.fontWeight,
        fontStyle: processObj.fontStyle,
        textDecoration: processObj.textDecoration,
        fontSize: processObj.fontSize,
        fontColor: processObj.fontColor,
        divideLine: processObj.divideLine
      })
    });
    bpmnPlane.planeElement.push(bpmnProcessShape);

    processObj.shapes.forEach(shape => {
      if(shape.type === 'bpmn:TextAnnotation' || shape.type === 'bpmn:Lane') return null;
      const bpmnElementProps = {
        id: shape.id,
        name: shape.name
      };

      const bpmnElement = moddle.create(shape.type, bpmnElementProps);
      bpmnProcess.flowElements.push(bpmnElement);

      if (shape.eventDefinitions && typeof shape.eventDefinitions === 'string') {
        console.log(shape.eventDefinitions)
        const eventDefinitionElement = moddle.create(`${shape.eventDefinitions}`, { id: `${shape.id}_eventDef` });
        bpmnElement.eventDefinitions = [eventDefinitionElement];
      }

      const bpmnShapeProps = {
        id: `BPMNShape_${shape.id}`,
        bpmnElement: bpmnElement,
        bounds: moddle.create('dc:Bounds', {
          x: shape.x,
          y: shape.y,
          width: shape.width,
          height: shape.height,
          radius: shape.radius,
          borderWidth: shape.borderWidth,
          borderColor: shape.borderColor,
          bgColor: shape.bgColor,
          processId: shape.processId,
          famillyType: shape.famillyType,
          shapeType: shape.shapeType,
          description: shape.description,
          participantsName: shape.participants.nom,
          participantsId: shape.participants.id,
          evaluableName: shape.evaluable.nom,
          evaluableId: shape.evaluable.id,
          consulteName: shape.consulte.nom,
          consulteId: shape.consulte.id,
          informeName: shape.informe.nom,
          informeId: shape.informe.id,
          fontWeight: shape.fontWeight,
          fontStyle: shape.fontStyle,
          textDecoration: shape.textDecoration,
          fontSize: shape.fontSize,
          fontColor: shape.fontColor
        })
      };

      const bpmnShape = moddle.create('bpmndi:BPMNShape', bpmnShapeProps);
      bpmnPlane.planeElement.push(bpmnShape);
    });

    processObj.flows.forEach(flow => {
      let sequenceFlow;
      if(flow.shapeType === "Association"){
        sequenceFlow = moddle.create('bpmn:SequenceFlow', {
          id: flow.id,
          sourceRef: bpmnProcess.flowElements.find(e => e.id === flow.sourceRef),
          targetRef: participants.find(e => e.id === flow.targetRef)
        });
      }else{
        sequenceFlow = moddle.create('bpmn:SequenceFlow', {
          id: flow.id,
          sourceRef: bpmnProcess.flowElements.find(e => e.id === flow.sourceRef),
          targetRef: bpmnProcess.flowElements.find(e => e.id === flow.targetRef)
        });
      }
      bpmnProcess.flowElements.push(sequenceFlow);

      const bpmnEdge = moddle.create('bpmndi:BPMNEdge', {
        id: `BPMNEdge_${flow.id}`,
        bpmnElement: sequenceFlow,
        waypoint: flow.waypoints.map(wp => moddle.create('dc:Point', { x: wp.x, y: wp.y })),
        name: flow.name,
        description: flow.description,
        processId: flow.processId,
        famillyType: flow.type,
        shapeType: flow.shapeType,
      });
      bpmnPlane.planeElement.push(bpmnEdge);
    });

    rootElements.push(bpmnProcess)

  })

  collaboration.participants = participants;

  const bpmnDiagram = moddle.create('bpmndi:BPMNDiagram', {
    id: 'BPMNDiagram_1',
    plane: bpmnPlane
  });

  const definitions = moddle.create('bpmn:Definitions', {
    id: 'Definitions_1',
    targetNamespace: 'http://bpmn.io/schema/bpmn',
    rootElements: [...rootElements, collaboration]
  });

  definitions.diagrams = [bpmnDiagram];

  try {
    const resultToXML = await moddle.toXML(definitions);
    console.log(resultToXML);
    return resultToXML;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function shapesToXML(customShapes){
  const { process: processObj, shapes, flows } = convertToBpmnElements(customShapes);

  if (!processObj) {
    console.error('No process defined');
    return;
  }

  const moddle = new BpmnModdle();

  const bpmnProcess = moddle.create('bpmn:Process', {
    id: processObj.id,
    name: processObj.name,
    isExecutable: true,
    flowElements: []
  });

  const collaboration = moddle.create('bpmn:Collaboration', {
    id: 'Collaboration_1'
  });

  const participant = moddle.create('bpmn:Participant', {
    id: `Participant_${processObj.id}`,
    processRef: bpmnProcess
  });

  collaboration.get('participants').push(participant);

  const definitions = moddle.create('bpmn:Definitions', {
    id: 'Definitions_1',
    targetNamespace: 'http://bpmn.io/schema/bpmn',
    rootElements: [bpmnProcess, collaboration]
  });

  const bpmnPlane = moddle.create('bpmndi:BPMNPlane', {
    id: 'BPMNPlane_1',
    bpmnElement: collaboration,
    planeElement: []
  });

  const bpmnProcessShape = moddle.create('bpmndi:BPMNShape', {
    id: `BPMNShape_${processObj.id}`,
    bpmnElement: bpmnProcess,
    bounds: moddle.create('dc:Bounds', {
      x: processObj.x,
      y: processObj.y,
      width: processObj.width,
      height: processObj.height
    })
  });
  bpmnPlane.planeElement.push(bpmnProcessShape);


  const bpmnDiagram = moddle.create('bpmndi:BPMNDiagram', {
    id: 'BPMNDiagram_1',
    plane: bpmnPlane
  });

  shapes.forEach(shape => {
    const bpmnElementProps = {
      id: shape.id,
      name: shape.name
    };

    if (shape.eventDefinitions && typeof shape.eventDefinitions === 'string') {
      const eventDefinitionElement = moddle.create(`bpmn:${shape.eventDefinitions}`, { id: `${shape.id}_eventDef` });
      bpmnElementProps.eventDefinitions = [eventDefinitionElement];
    }

    const bpmnElement = moddle.create(shape.type, bpmnElementProps);
    bpmnProcess.flowElements.push(bpmnElement);

    const bpmnShapeProps = {
      id: `BPMNShape_${shape.id}`,
      bpmnElement: bpmnElement,
      bounds: moddle.create('dc:Bounds', {
        x: shape.x,
        y: shape.y,
        width: shape.width,
        height: shape.height,
        radius: shape.radius,
        borderWidth: shape.borderWidth,
        borderColor: shape.borderColor,
        bgColor: shape.bgColor,
        processId: shape.processId,
        famillyType: shape.famillyType,
        shapeType: shape.shapeType,
        description: shape.description,
      })
    };

    const bpmnShape = moddle.create('bpmndi:BPMNShape', bpmnShapeProps);
    bpmnPlane.planeElement.push(bpmnShape);
  });

  flows.forEach(flow => {
    const sequenceFlow = moddle.create('bpmn:SequenceFlow', {
      id: flow.id,
      sourceRef: bpmnProcess.flowElements.find(e => e.id === flow.sourceRef),
      targetRef: bpmnProcess.flowElements.find(e => e.id === flow.targetRef)
    });
    bpmnProcess.flowElements.push(sequenceFlow);

    const bpmnEdge = moddle.create('bpmndi:BPMNEdge', {
      id: `BPMNEdge_${flow.id}`,
      bpmnElement: sequenceFlow,
      waypoint: flow.waypoints.map(wp => moddle.create('dc:Point', { x: wp.x, y: wp.y })),
      name: flow.name,
      description: flow.description,
      processId: flow.processId,
      famillyType: flow.type,
      shapeType: flow.shapeType,
    });
    bpmnPlane.planeElement.push(bpmnEdge);
  });

  definitions.diagrams = [bpmnDiagram];

  try {
    const resultToXML = await moddle.toXML(definitions);
    return resultToXML;
  } catch (error) {
    console.error('Error:', error);
  }
}

function GenerateDocument(customShapes, selectedProcessId, closeExportWordModal, stageRef, info, base64Logo) {

  const doc = new Document({
    sections: []
  });

  const toolNames = {
    'StartEvent': "Événement de début",
    'Task': "Tâche",
    'UserTask': "Tâche utilisateur",
    'ServiceTask': "Tâche service",
    'ReceiveTask': "Tâche réception",
    'SendTask': "Tâche envoi",
    'ScriptTask': "Tâche script",
    'ManualTask': "Tâche manuelle",
    'BusinessRuleTask': "Tâche règle d'entreprise",
    'SubProcess': "Sous-processus",
    'CallActivity': "Sous-processus",
    'ExclusiveGateway': "Branchement exclusif",
    'InclusiveGateway': "Branchement inclusif",
    'ParallelGateway': "Passerelle parallèle",
    'EventBasedGateway': "Passerelle basée sur un événement",
    'ComplexGateway': "Branchement complexe",
    'IntermediateCatchEvent': "Événement intermédiaire",
    'EndEvent': "Événement de fin",
    'divideLine': "Voie"
  };

  const createHeaderWithImage = ()=>{
    const image = new ImageRun({
      data: base64Logo,
      transformation: {
        width: 100,
        height: 32
      }
    });

    const headerParagraph = new Paragraph({
      children: [image],
    });

    const header = new DocxHeader({
      children: [headerParagraph],
    });

    return header;
  }

  const exportCroppedImage = (processId) => {
    const selectedProcess = customShapes.find(s => s.id === processId);
    if (!selectedProcess) {
      console.error('Selected process not found');
      return '';
    }

    const stage = stageRef.current;
    const scale = stage.scaleX();
    const padding = 10;

    const shapeNode = stage.findOne('#' + selectedProcess.id);
    const rect = shapeNode.getClientRect();

    const basePixelRatio = 1;
    let pixelRatio = basePixelRatio;

    if (scale < 1) {
      pixelRatio = 1 / scale;
    } else {
      pixelRatio = scale * 2;
    }

    const dataURL = stage.toDataURL({
      x: rect.x ,
      y: rect.y ,
      width: rect.width + padding,
      height: rect.height + padding,
      mimeType: 'image/png',
      quality: 2,
      pixelRatio: pixelRatio,
    });

    const maxDocxWidth = 650;
    let imgWidth = rect.width + padding;
    let imgHeight = rect.height + padding;

    if (imgWidth > maxDocxWidth) {
      const aspectRatio = imgHeight / imgWidth;
      imgWidth = maxDocxWidth;
      imgHeight = imgWidth * aspectRatio;
    }

    return {
      data: dataURL,
      transformation: {
        width: imgWidth,
        height: imgHeight,
      },
    }
  };

  if(selectedProcessId.length > 0){
    console.log(customShapes);
    let listOp = [];
    selectedProcessId.forEach((id)=>{
      const currProcesses = customShapes.filter((it)=> it.id === id )[0];
      listOp.push(
        new Paragraph({
          text: currProcesses.name,
          heading: HeadingLevel.HEADING_1,
          spacing: { after: 200 },
        }),
      )
      listOp.push(
        new Paragraph({
          children: [
            new ImageRun(exportCroppedImage(id)),
            // new ImageRun({
            //   data: exportCroppedImage(id),
            //   transformation: {
            //     width: 710 * 0.85,
            //     height: 410 * 0.85,
            //   },
            // }),
          ],
          spacing: { after: 200 },
        })
      )
      listOp.push(
        new Paragraph({
          children: [
            new TextRun({
              text: "Acteur(s) : ",
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: `Participant: ${currProcesses.participants?.nom ? currProcesses.participants?.nom : "Indéfini"} -  Évaluable: ${currProcesses.evaluable?.nom ? currProcesses.evaluable?.nom : "Indéfini"} - Consulté: ${currProcesses.consulte?.nom ? currProcesses.consulte?.nom : "Indéfini"} - Informé: ${currProcesses.informe?.nom ? currProcesses.informe?.nom : "Indéfini"}`,
              size: 22,
              font: "Arial",
            })
          ],
          spacing: { after: 200 },
        })
      )
      listOp.push(
        new Paragraph({
          children: [new TextRun({
            text: "Description du processus : ",
            bold: true,
            size: 24,
            font: "Arial",
          })],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [new TextRun({
            text: currProcesses.description,
            size: 22,
            font: "Arial",
          })],
          spacing: { after: 500 },
        }),
      )
      listOp.push(
        new Paragraph({
          children: [new TextRun({
            text: "Opérations du processus",
            bold: true,
            size: 32,
            underline: "single",
            font: "Arial",
          })],
          spacing: { after: 400 },
        }),
      )
      customShapes.map((it)=>{
        if(it.processId === id && it.family === "StartEvent" ){
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "•  ",
                  font: "Arial",
                  color: "#8B191A",
                  size: 24,
                }),
                new TextRun({
                  text: `${it.name} : `,
                  bold: true,
                  size: 24,
                  underline: 'single',
                  color: '#8B191A',
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Type : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${toolNames[it.family]}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          it?.divideLineName &&
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Voie : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${it?.divideLineName}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "Acteur(s) : ",
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `Participant: ${it.participants?.nom ? it.participants?.nom : "Indéfini"} -  Évaluable: ${it.evaluable?.nom ? it.evaluable?.nom : "Indéfini"} - Consulté: ${it.consulte?.nom ? it.consulte?.nom : "Indéfini"} - Informé: ${it.informe?.nom ? it.informe?.nom : "Indéfini"}`,
                  size: 22,
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [new TextRun({
                text: "Description de l'opération : ",
                bold: true,
                size: 24,
                font: "Arial",
              })],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [new TextRun({
                text: it.description,
                size: 22,
                font: "Arial",
              })],
              spacing: { after: 800 },
            }),
          )
        }
      })
      customShapes.map((it)=>{
        if(it.processId === id && it.type !== "arrow" && it.type !== "divideLine" && it.family !== "StartEvent" && it.family !== "EndEvent" ){
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "•  ",
                  font: "Arial",
                  color: "#8B191A",
                  size: 24,
                }),
                new TextRun({
                  text: `${it.name} : `,
                  bold: true,
                  size: 24,
                  underline: 'single',
                  color: '#8B191A',
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Type : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${toolNames[it.family]}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          it?.divideLineName &&
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Voie : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${it?.divideLineName}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "Acteur(s) : ",
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `Participant: ${it.participants?.nom ? it.participants?.nom : "Indéfini"} -  Évaluable: ${it.evaluable?.nom ? it.evaluable?.nom : "Indéfini"} - Consulté: ${it.consulte?.nom ? it.consulte?.nom : "Indéfini"} - Informé: ${it.informe?.nom ? it.informe?.nom : "Indéfini"}`,
                  size: 22,
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [new TextRun({
                text: "Description de l'opération : ",
                bold: true,
                size: 24,
                font: "Arial",
              })],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [new TextRun({
                text: it.description,
                size: 22,
                font: "Arial",
              })],
              spacing: { after: 800 },
            }),
          )
        }
      })
      customShapes.map((it)=>{
        if(it.processId === id && it.family === "EndEvent" ){
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "•  ",
                  font: "Arial",
                  color: "#8B191A",
                  size: 24,
                }),
                new TextRun({
                  text: `${it.name} : `,
                  bold: true,
                  size: 24,
                  underline: 'single',
                  color: '#8B191A',
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Type : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${toolNames[it.family]}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          it?.divideLineName &&
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Voie : ',
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `${it?.divideLineName}`,
                  size: 22,
                  font: "Arial",
                }),
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: "Acteur(s) : ",
                  bold: true,
                  size: 24,
                  font: "Arial",
                }),
                new TextRun({
                  text: `Participant: ${it.participants?.nom ? it.participants?.nom : "Indéfini"} -  Évaluable: ${it.evaluable?.nom ? it.evaluable?.nom : "Indéfini"} - Consulté: ${it.consulte?.nom ? it.consulte?.nom : "Indéfini"} - Informé: ${it.informe?.nom ? it.informe?.nom : "Indéfini"}`,
                  size: 22,
                  font: "Arial",
                })
              ],
              spacing: { after: 200 },
            })
          )
          listOp.push(
            new Paragraph({
              children: [new TextRun({
                text: "Description de l'opération : ",
                bold: true,
                size: 24,
                font: "Arial",
              })],
              spacing: { after: 200 },
            }),
            new Paragraph({
              children: [new TextRun({
                text: it.description,
                size: 22,
                font: "Arial",
              })],
              spacing: { after: 800 },
            }),
          )
        }
      })
    })

    doc.addSection({
      properties: {},
      headers: {
        default: createHeaderWithImage(),
      },
      children: [
        new Paragraph({
          text: info.processusName,
          heading: HeadingLevel.TITLE,
          alignment: AlignmentType.CENTER,
          spacing: { after: 600 },
        }),
        // new Paragraph({
        //   children: [new TextRun({
        //     text: "Table des matières",
        //     bold: true,
        //     size: 24,
        //     font: "Arial",
        //   })],
        //   alignment: AlignmentType.CENTER,
        //   spacing: { after: 400 },
        // }),
        // new TableOfContents("Sommaire", {
        //   hyperlink: true,
        //   headingStyleRange: "1-3",
        //   stylesWithLevels: [],
        // }),
        new Paragraph({
          children: [new TextRun({
            text: "Informations du processus",
            bold: true,
            size: 32,
            underline: "single",
            font: "Arial",
          })],
          spacing: { after: 400 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'Nom de créateur : ',
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: info.creatorName,
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Périmètre processus : ",
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: info.perimetre,
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'Date de création : ',
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: moment(info.dateCreation).locale('fr').format('ll'),
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'Date de modification : ',
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: info.dateModification ? moment(info.dateModification).locale('fr').format('ll') : "Indéfini",
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: 'Date de validation : ',
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: info.dateValidation ? moment(info.dateValidation).locale('fr').format('ll') : "Indéfini",
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 200 },
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Description : ",
              bold: true,
              size: 24,
              font: "Arial",
            }),
            new TextRun({
              text: info.description,
              size: 22,
              font: "Arial",
            }),
          ],
          spacing: { after: 500 },
        }),

        ...listOp,
      ],
    });

    Packer.toBlob(doc).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "my-document.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    closeExportWordModal();
  }
};

function FileRibbon({customShapes, changeShapes, stageRef, stageScale, currDiagramId, openFoldersModal}){

  const { t } = useTranslation();
  const importInputRef = useRef(null);
  const exportMenuRef = useRef(null);
  const [isExportmenuOpen, setIsExportMenuOpen] = useState(false);
  const [isExportWordModalOpen, setIsExportWordModalOpen] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState([]);
  const [info, setInfo] = useState({});

  const getProjectInfo = async ()=>{
    await getProjectInfosById(currDiagramId)
    .then((res)=>{
      setInfo(res);
    })
    .catch((err)=> console.log(err) )
  }

  const closeExportWordModal = ()=>{
    setIsExportWordModalOpen(false);
    setSelectedProcessId([]);
  }

  const onSelectProcess = (processId)=>{
    if(selectedProcessId.includes(processId)){
      setSelectedProcessId(selectedProcessId.filter(it => it !== processId));
    }else{
      setSelectedProcessId(oldArray => [...oldArray, processId]);
    }
  }

  const handleClickOutside = (event) => {
    if (exportMenuRef.current && !exportMenuRef.current.contains(event.target)) {
      setIsExportMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    if(currDiagramId){
      getProjectInfo();
    }
  },[currDiagramId]);

  const exportBpmn = async () => {
    const resultToXML = await shapesToXMLV2(customShapes);
    const blob = new Blob([resultToXML.xml], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'diagram.bpmn';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleFileChange = event => {
    const fileInput = event.target; // Get the file input element
    const file = fileInput.files[0];
    if (!file) {
        return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const xml = e.target.result;
      parseXML(xml, changeShapes);
      fileInput.value = '';
    };
    reader.readAsText(file);
  };

  const lastProcessIndex = customShapes.map((it, idx) => it.type === "process" ? idx : -1).filter(idx => idx !== -1).pop();

  async function fetchImageAsBase64(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const downloadWordFile = ()=>{
    fetchImageAsBase64('/brome-logo.png')
    .then(base64Logo => {
      GenerateDocument(customShapes, selectedProcessId, closeExportWordModal, stageRef, info, base64Logo)
    });
  }

  // const exportCroppedImage = (processId) => {
  //   let selectedProcess = customShapes.find(s => s.id === processId);
  //   const x1 = selectedProcess.x - 5,
  //     y1 = selectedProcess.y - 5,
  //     x2 = selectedProcess.x + selectedProcess.width + 5,
  //     y2 = selectedProcess.y + selectedProcess.height + 5;

  //   console.log(selectedProcess);
  //   console.log(x1, y1, x2, y2);

  //   const width = x2 - x1;
  //   const height = y2 - y1;

  //   const dataURL = stageRef.current.toDataURL({
  //     x: x1,
  //     y: y1,
  //     width: width,
  //     height: height,
  //     mimeType: 'image/png',
  //     quality: 1
  //   });

  //   return dataURL;
  // };

  return(
    <div className='flex items-center gap-3 text-sm' >
      <Link to="/" className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiHome/> {t("NAV.TOP.HOME.TITLE")}
      </Link>
      <div onClick={()=> openFoldersModal() } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiFolder/> {t("NAV.TOP.FILE.ITEMS.OPEN")}
      </div>
      <div onClick={()=> setIsExportMenuOpen(!isExportmenuOpen) } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer relative' >
        <FiExternalLink/> {t("NAV.TOP.FILE.ITEMS.EXPORT.TITLE")}
        {
          isExportmenuOpen &&
          <div ref={exportMenuRef} className='absolute z-20 py-2 w-max top-8 left-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
            <ul className='flex flex-col gap-2' >
              <li onClick={()=> exportBpmn() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                <BsFiletypeXml size={16} />
                <span>{t("NAV.TOP.FILE.ITEMS.EXPORT.BPMN")}</span>
              </li>
              <li onClick={()=> setIsExportWordModalOpen(true) } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                <BsFileEarmarkWord size={16}/>
                <span>{t("NAV.TOP.FILE.ITEMS.EXPORT.WORD")}</span>
              </li>
            </ul>
          </div>
        }
      </div>
      <input ref={importInputRef} type="file" onChange={handleFileChange} accept=".bpmn" style={{ display: 'none' }} />
      <div onClick={()=>importInputRef.current?.click()} className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiUpload/> {t("NAV.TOP.FILE.ITEMS.IMPORT")}
      </div>
      <Modal isOpen={isExportWordModalOpen} title="Exporter au format Word" close={closeExportWordModal} >
        <p className='text-sm text-gray-500 ml-2 mb-1' >Sélectionner un processus</p>
        <div className='border rounded-lg py-2' >
          {customShapes.map((it, index)=>{
            if(it.type === "process"){
              return(
                <div key={it.id} className={cx('px-2 flex items-center justify-between',{'border-b border-gray-300 mb-2 pb-2': index !== lastProcessIndex })} >
                  <div className='flex gap-2 items-center' >
                    <input
                      type='checkbox'
                      className='w-4 h-4'
                      onChange={(e)=> onSelectProcess(it.id) }
                      checked={selectedProcessId.includes(it.id)}
                    />
                    <p className='font-semibold' >{it.name}</p>
                  </div>
                  {/* <FiChevronDown size={18} cursor="pointer" /> */}
                </div>
              )
            }
          })}
        </div>
        <div className='flex justify-end mt-6' >
          <button onClick={()=> downloadWordFile() } className='bg-azure px-6 py-2 rounded-lg font-semibold text-sm text-white hover:bg-sky-800' >
            Exporter
          </button>
          {/* <button onClick={()=> exportCroppedImage(selectedProcessId[0]) } className='bg-azure px-6 py-2 rounded-lg font-semibold text-sm text-white hover:bg-sky-800' >
            Exporter
          </button> */}
        </div>
      </Modal>
    </div>
  )
}

function HomeRibbon({setUndoHistory, setRedoHistory, undoHistory, setShapes, shapes}){

  const textColorSelectRef = useRef(null);
  const bgColorSelectRef = useRef(null);
  const { selectedShape, setSelectedShape } = useContext(DragContext);
  const [isTextColorSelectOpen, setIsTextColorSelectOpen] = useState(false);
  const [isBgColorSelectOpen, setIsBgColorSelectOpen] = useState(false);

  const updateShapes = (updatedShapes) => {
    setUndoHistory([...undoHistory, shapes]);
    setRedoHistory([]);
    setShapes(updatedShapes);
  };

  const handleFontStyle = (column, value) => {
    let updatedSelectedShape = {}
    if(selectedShape[column] === value){
      updatedSelectedShape = {
        ...selectedShape,
        [column]: "",
      };
    }else{
      updatedSelectedShape = {
        ...selectedShape,
        [column]: value,
      };
    }
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
  };

  const increaseSize = ()=>{
    const updatedSelectedShape = {
      ...selectedShape,
      fontSize: parseInt(selectedShape.fontSize)+1,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
  }

  const decreaseSize = ()=>{
    const updatedSelectedShape = {
      ...selectedShape,
      fontSize: parseInt(selectedShape.fontSize)-1,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
  }

  const changeFontSize = (val)=>{
    const updatedSelectedShape = {
      ...selectedShape,
      fontSize: parseInt(val),
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
  }

  const changeTextColor = (col)=>{
    const updatedSelectedShape = {
      ...selectedShape,
      // fontColor: col.hex,
      fontColor: `rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
    // setIsTextColorSelectOpen(false);
  }
  const changeBgColor = (col)=>{
    const updatedSelectedShape = {
      ...selectedShape,
      bgColor: `rgba(${col.rgb.r}, ${col.rgb.g}, ${col.rgb.b}, ${col.rgb.a})`,
    };
    setSelectedShape(updatedSelectedShape);
    const updatedShapes = shapes.map(shape =>
      shape.id === selectedShape.id ? updatedSelectedShape : shape
    );
    updateShapes(updatedShapes);
    // setIsBgColorSelectOpen(false);
  }

  const handleClickOutside = (event) => {
    if (textColorSelectRef.current && !textColorSelectRef.current.contains(event.target)) {
      setIsTextColorSelectOpen(false);
    }
    if (bgColorSelectRef.current && !bgColorSelectRef.current.contains(event.target)) {
      setIsBgColorSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div className='flex items-center gap-3 text-sm' >
      <div onClick={()=> undoEvent.emit('undo', true) } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiRotateCcw/>
      </div>
      <div onClick={()=> redoEvent.emit('redo', true) } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiRotateCw/>
      </div>
      <div className='h-[26px] w-[1.5px] bg-gray-200' />
      <div className='flex items-center gap-0.5 p-1 rounded cursor-pointer border' >
        <input
          type='text'
          className='text-sm w-10'
          placeholder='12'
          value={selectedShape.fontSize}
          onChange={(e)=> changeFontSize(e.target.value)}
        />
        {/* <FiChevronDown/> */}
      </div>
      <div onClick={()=> decreaseSize() } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <MdTextDecrease size={18} />
      </div>
      <div onClick={()=> increaseSize() } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <MdTextIncrease size={18} />
      </div>
      <div onClick={()=> handleFontStyle("fontWeight", "bold") } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiBold strokeWidth={3} size={18} />
      </div>
      <div onClick={()=> handleFontStyle("fontStyle", "italic") } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiItalic size={18} />
      </div>
      <div onClick={()=> handleFontStyle("textDecoration", "underline") } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
        <FiUnderline size={18} />
      </div>
      <div className='relative' >
        <div onClick={()=> setIsTextColorSelectOpen(!isTextColorSelectOpen) } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
          <MdOutlineFormatColorText size={18} />
        </div>
        { isTextColorSelectOpen &&
          <div ref={textColorSelectRef} className='absolute top-10 left-0 z-30 w-max' >
            <SketchPicker
              onChangeComplete={(col)=> changeTextColor(col) }
              color={selectedShape.fontColor}
            />
          </div>
        }
      </div>
      {/* <div ref={textColorSelectRef} className='absolute top-10 left-0 z-30 w-max' >
            <GithubPicker
              onChange={(col)=> changeTextColor(col) }
              colors={[
                '#B80000', '#DB3E00', '#FCCB00', '#008B02',
                '#006B76', '#1273DE', '#004DCF', '#5300EB',
                '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5',
                '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
                '#000000', "#d3d3d3", "#ffffff"
              ]}
            />
          </div> */}
      <div className='relative' >
        <div onClick={()=> setIsBgColorSelectOpen(!isBgColorSelectOpen) } className='flex items-center gap-0.5 hover:bg-gray-100 p-1 rounded cursor-pointer' >
          <MdFormatColorFill size={18} />
        </div>
        { isBgColorSelectOpen &&
          <div ref={bgColorSelectRef} className='absolute top-10 left-0 z-20 w-max' >
            <SketchPicker
              onChangeComplete={(col)=> changeBgColor(col) }
              color={selectedShape.bgColor}
            />
          </div>
        }
      </div>
    </div>
  )
}


function ProjectContainer({project, close}){

  const {setCurrDiagramId, setCurrDiagramAccessType} = useContext(UserContext);

  const navigate = useNavigate();


  const clickProject = ()=>{
    setCurrDiagramId(project.project);
    setCurrDiagramAccessType(project.accessType === null ? "OWNER" : project.accessType);
    navigate(`/board/${project.project}`);
    close();
  }

  return(
    <div onClick={()=> clickProject() } className='flex flex-col justify-between border rounded-lg customShadow p-4 cursor-pointer relative bg-white' >
      <div className='flex items-start justify-between' >
        <BpmFileIcon height={42} width={42} className='mb-4' />
      </div>
      <div>
        <h4 className='font-semibold mb-1' >{project.intitule}</h4>
        <p className='text-xs text-gray-500' >Dernière mise à jour le {moment(project?.updatedAt).locale('fr').format('ll')}</p>
      </div>
    </div>
  )
}

function FoldersModal({close}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {setShapes} = useContext(UserContext);

  const [projects, setProjects] = useState([]);
  const [folderData, setFolderData] = useState({});
  const [currentProject, setCurrentProject] = useState(null);

  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [isNewDiagramModalOpen, setIsNewDiagramModalOpen] = useState(false);

  const openNewFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewFolderModalOpen(true);
  }
  const closeNewFolderModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewFolderModalOpen(false);
    if(currentProject){
      fetchFolderData();
    }else{
      fetchFolders();
    }
  }

  const openNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewDiagramModalOpen(true);
  }
  const closeNewDiagramModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewDiagramModalOpen(false);
    fetchFolderData();
  }

  const toProject = (id) => {
    setCurrentProject(id);
  }

  const fetchFolders = ()=>{
    getAllFolders()
      .then((res)=> setProjects(res) )
      .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    fetchFolders();
  },[]);

  const fetchFolderData = ()=>{
    folderContent(currentProject)
      .then((res)=>{
        setFolderData(res);
      })
      .catch((err)=> console.log(err) )
  }

  useEffect(()=>{
    if(currentProject){
      fetchFolderData();
    }
  },[currentProject]);

  const foldersPage = ()=> (
    <div className='grid grid-cols-4 gap-4 mt-8'>
      {projects.map((it) =>
        <FolderContainer
          key={it.id}
          project={it}
          isPreview
          toProject={toProject}
          fetchFolders={fetchFolders}
        />
      )}
    </div>
  );

  const folderPage = ()=> (
    <div className='grid grid-cols-4 gap-4 mt-8'>
      {folderData?.folders &&
        folderData?.folders.map((it) =>
          <FolderContainer
            key={it.id}
            project={it}
            fetchFolders={fetchFolderData}
          />
        )
      }
      {folderData?.projects &&
        folderData?.projects.map((it) =>
          <ProjectContainer
            key={it.id}
            project={it}
            fetchFolders={fetchFolderData}
            close={close}
          />
        )
      }
    </div>
  );

  return (
    <div>
      <div className='flex items-center justify-between mb-4'>
        <div className="flex items-center gap-4" >
          { currentProject &&
            <button>
              <FiChevronLeft onClick={()=> setCurrentProject(null) } />
            </button>
          }
          <p className='font-bold text-sm text-gray-500'>{t("HOME.NAV.folders")}</p>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={()=> openNewDiagramModal() }
            className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg'>
            <FiPlus size={16} strokeWidth={2.5}/>
            {t("HOME.FOLDERS_VIEW.NEW_PROCESS_BTN")}
          </button>
          <button
            onClick={()=> openNewFolderModal() }
            className='flex items-center gap-2 px-2.5 py-1.5 rounded-lg border border-azure bg-azure text-xs font-semibold text-white hover:shadow-lg'>
            <FiPlus size={16} strokeWidth={2.5}/>
            {t("HOME.FOLDERS_VIEW.NEW_FOLDER_BTN")}
          </button>
          <FiX onClick={() => close()} size={20} className='cursor-pointer'/>
        </div>
      </div>
      {
        currentProject
          ? folderPage()
          : foldersPage()
      }

      <Modal isOpen={isNewFolderModalOpen} close={closeNewFolderModal} title={t("HOME.FOLDERS_VIEW.NEW_FOLDER_TITLE")} >
        <NewProjectModal close={closeNewFolderModal} idFolder={currentProject || ""} />
      </Modal>
      <Modal isOpen={isNewDiagramModalOpen} title={t("HOME.NEW_BTN")} close={closeNewDiagramModal} >
        <NewDiagramModal setShapes={setShapes} navigate={navigate} close={closeNewDiagramModal} origine="folder" parentFolder={currentProject} />
      </Modal>
    </div>
  );
}

export default function Header({projectRessources, setProjectRessources, setUndoHistory, setRedoHistory, undoHistory}) {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const {shapes, setShapes, stageRef, stageScale, user, currDiagramId} = useContext(UserContext);
  const [currRibbon, setCurrRibbon] = useState("home");
  const [oldShapes, setOldShapes] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);

  const accountMenuRef = useRef(null);
  const settingsMenuRef = useRef(null);

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isShapesEqual, setIsShapesEqual] = useState(false);
  const [isRessourcesEqual, setIsRessourcesEqual] = useState(false);

  const [isFoldersModalOpen, setIsFoldersModalOpen] = useState(false);

  const openFoldersModal = ()=>{
    setIsFoldersModalOpen(true);
  }
  const closeFoldersModal = ()=>{
    setIsFoldersModalOpen(false);
  }

  const getCurrRibbon=()=>{
    switch (currRibbon) {
      case "file":
        return <FileRibbon
          customShapes={shapes}
          changeShapes={setShapes}
          stageRef={stageRef}
          stageScale={stageScale}
          currDiagramId={currDiagramId}
          openFoldersModal={openFoldersModal}
        />;
      case "home":
        return <HomeRibbon shapes={shapes} setShapes={setShapes} setUndoHistory={setUndoHistory} setRedoHistory={setRedoHistory} undoHistory={undoHistory} />;
      default:
        break;
    }
  }

  const logOut = ()=>{
    loginEvent.emit('login', false);
  }

  const saveProject = async ()=>{
    setSaving(true);

    const formData = new FormData();
    const resultToXML = await shapesToXMLV2(shapes);
    const blob = new Blob([resultToXML.xml], { type: 'application/xml' });
    formData.append('file', blob, 'file.bpmn');

    let editData = {
      intitule: projectInfo.intitule,
      ressources: projectRessources.map((it)=> {return {nom: it.nom, description: it.description, ressourceType: it.ressourceType} } ),
      project: currDiagramId
    }
    formData.append('object', new Blob([JSON.stringify(editData)], { type: 'application/json' }));
    updateProject(formData, currDiagramId)
    .then(()=>{ setIsShapesEqual(true); setIsRessourcesEqual(true); })
    .catch((err)=> console.log(err) )
    .finally(()=> setSaving(false) )
  }

  const getProjectOldShapes = ()=>{
    getProjectCurrVersion(currDiagramId)
    .then((res)=>{
      parseXML(res, setOldShapes);
    }).catch((err)=> console.log(err) );

    getProjectById(currDiagramId)
    .then((res)=>{
      setProjectInfo(res);
    }).catch((err)=> console.log(err) )
  }

  const compareToOldShape  = ()=>{
    const comparisonResult = isEqual(shapes, oldShapes);
    setIsShapesEqual(comparisonResult);
  }
  const compareToOldRessources = ()=>{
    const comparisonResult = isEqual(projectRessources, projectInfo.ressources);
    setIsRessourcesEqual(comparisonResult);
  }

  useEffect(()=>{
    if(Object.keys(projectInfo).length > 0){
      compareToOldRessources();
    }
  },[projectInfo, projectRessources]);

  useEffect(()=>{
    compareToOldShape()
  },[shapes]);

  const handleClickOutside = (event) => {
    if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
      setIsAccountMenuOpen(false);
    }
    if (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target)) {
      setIsSettingsMenuOpen(false);
    }
  };

  useEffect(()=>{
    if(currDiagramId){
      getProjectOldShapes();
    }
  },[currDiagramId]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='h-[6rem] relative z-10 bg-[#F3F3F3] select-none' >
      <div className='pt-4 pb-2.5 px-8 flex justify-between items-center' >
        <ul className='flex text-gray-600 text-xs font-semibold gap-6 pl-4' >
          <li >
            <div onClick={()=>{ setProjectRessources([]); navigate(-1) }} className='group cursor-pointer border rounded-full p-1 absolute top-2.5 left-4 text-gray-500 hover:border-azure' >
              <FiChevronLeft size={18} className='text-gray-500 group-hover:text-azure cursor-pointer' />
            </div>
          </li>
          <li value="file" className='flex flex-col items-center cursor-pointer group' onClick={()=> setCurrRibbon("file")} >
            {t("NAV.TOP.FILE.TITLE")}
            { currRibbon === "file"
              ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
              : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
            }
          </li>
          <li value="home" className='flex flex-col items-center cursor-pointer group' onClick={()=> setCurrRibbon("home")} >
            {t("NAV.TOP.HOME.TITLE")}
            { currRibbon === "home"
              ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
              : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
            }
          </li>
          {/* <li value="format" className='flex flex-col items-center cursor-pointer group' onClick={()=> setCurrRibbon("format")} >
            {t("NAV.TOP.FORMAT.TITLE")}
            { currRibbon === "format"
              ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
              : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
            }
          </li>
          <li value="preview" className='flex flex-col items-center cursor-pointer group' onClick={()=> setCurrRibbon("preview")} >
            {t("NAV.TOP.PREVIEW.TITLE")}
            { currRibbon === "preview"
              ? <div className='border-b-2 border-azure pt-1 w-2/3 group-hover:w-full transition-all duration-300' />
              : <div className='border-b-2 border-gray-400 pt-1 w-0 group-hover:w-full transition-all duration-300' />
            }
          </li> */}
        </ul>
        <ul className='flex text-gray-600 gap-4 items-center' >
          <li className='relative' >
            <div onClick={()=> setIsAccountMenuOpen(true)} className='text-xs font-semibold flex items-center gap-1.5 border rounded-lg px-1.5 py-0.5 cursor-pointer' >
              <FiUser size={16} />
              <span style={{lineHeight: '10px'}} >{user.firstName} {user.lastName}</span>
            </div>
            { isAccountMenuOpen &&
              <div ref={accountMenuRef} className='absolute z-20 py-2 w-max bottom-[-4px] translate-y-[100%] left-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden' >
                <ul className='flex flex-col gap-2' >
                  <li >
                    <Link onClick={()=> setIsAccountMenuOpen(false) } to="/account" className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                      <FiUser/>
                      <span>{t("GLOBAL.ACCOUNT")}</span>
                    </Link>
                  </li>
                  <li onClick={()=> logOut() } className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200' >
                    <FiLogOut/>
                    <span>{t("GLOBAL.LOGOUT")}</span>
                  </li>
                </ul>
              </div>
            }
          </li>
          <li className='cursor-pointer' >
            <div className='text-xs font-semibold flex items-center gap-1.5 border rounded-lg px-1.5 py-0.5' >
              <FiGlobe size={16} />
              <span style={{lineHeight: '10px'}} >Fr</span>
            </div>
          </li>
          {/* <li onClick={()=> console.log(shapes) } >
            <FiSettings/>
          </li> */}
        </ul>
      </div>
      <div className={cx(styles.ribbon)} >
        {getCurrRibbon()}
        {
          !isShapesEqual || !isRessourcesEqual ?
          <button disabled={saving} onClick={()=> saveProject() } className='relative border rounded-lg border-azure text-azure hover:text-white hover:bg-azure text-xs font-semibold flex items-center py-1 px-1.5 gap-2' >
            {
              saving
              ? <div className='loader' />
              : <><FiSave size={14}/> {t("GLOBAL.SAVE")}</>
            }
          </button>
          : null
        }
      </div>

      <FullModal isOpen={isFoldersModalOpen} close={closeFoldersModal} >
        <FoldersModal close={closeFoldersModal} />
      </FullModal>
    </div>
  )
}
