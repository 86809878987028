import api from "./api";

export const getAllFolders = async ()=>{
  try {
    const response = await api.get('/regular/folders/');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createFolder = async (data)=>{
  try {
    const response = await api.post('/regular/folders/create', data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateFolder = async (data, id)=>{
  try {
    const response = await api.put(`/regular/folders/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteFolder = async (id)=>{
  try {
    const response = await api.delete(`/regular/folders/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const folderContent = async (id)=>{
  try {
    const response = await api.get(`/regular/folders/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
