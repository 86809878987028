import React,{ createContext, useState, useEffect, useRef } from "react";
import { getMyInfo } from "../services/accountService";

export const UserContext = createContext();

export const UserProvider = ({ children, isLogged }) => {

  const stageRef = useRef(null);

  const [isFullLeftBar, setIsFullLeftBar] = useState(false);
  const [isFullRightBar, setIsFullRightBar] = useState(false);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [mode, setMode] = useState("select");
  const [diagram, setDiagram] = useState("d-1");
  const [shapes, setShapes] = useState([]);
  const [currDiagramId, setCurrDiagramId] = useState("");
  const [currDiagramAccessType, setCurrDiagramAccessType] = useState("");
  const [user, setUser] = useState({});
  const [userPrivileges, setUserPriviliges] = useState([]);

  const getUserData = ()=>{
    if(isLogged){
      getMyInfo()
      .then((res)=>{
        setUser(res);
        setUserPriviliges(res.roles.flatMap((it)=> it.privileges.map((it)=> it.code)))
      })
      .catch((err)=> console.log(err) )
    }
  }

  useEffect(()=>{
    getUserData();
  },[isLogged]);

  const values = {
    isFullLeftBar,
    setIsFullLeftBar,
    isFullRightBar,
    setIsFullRightBar,
    stageScale,
    setStageScale,
    stageX,
    setStageX,
    stageY,
    setStageY,
    mode,
    setMode,
    diagram,
    setDiagram,
    shapes,
    setShapes,
    stageRef,
    user,
    getUserData,
    currDiagramId,
    setCurrDiagramId,
    currDiagramAccessType,
    setCurrDiagramAccessType,
    userPrivileges
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};
