import api from "./api";

export const getAllProjects = async ()=>{
  try {
    const response = await api.get('/regular/projects/all');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getProjectById = async (id)=>{
  try {
    const response = await api.get(`/regular/projects/show/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getProjectInfosById = async (id)=>{
  try {
    const response = await api.get(`/regular/projects/show-infos/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getProjectCurrVersion = async (id)=>{
  try {
    const response = await api.get(`/regular/projects/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getMyProjectsByPage = async (page, size, search) =>{

  const params = {
    page: page,
    size: size,
    sort: `updatedAt,desc`,
  };

  const formData = new FormData();
  formData.append('filter', new Blob([JSON.stringify({
    keyWord: search,
  })], { type: 'application/json' }));

  try {
    const response = await api.post('/regular/projects/my-projects', {keyWord: ''}, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getSharedProjectsByPage = async (page, size, search) =>{

  const params = {
    page: page,
    size: size,
    sort: `updatedAt,desc`,
  };

  const formData = new FormData();
  formData.append('filter', new Blob([JSON.stringify({
    keyWord: search,
  })], { type: 'application/json' }));

  try {
    const response = await api.post('/regular/projects/shared-with-me', {keyWord: ''}, { params });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getSharedUsers = async (id) =>{
  try {
    const response = await api.post(`/regular/projects/shared-by-me/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const createProject = async (dataToSend, pj, type, data, tenantCode) =>{

  const formData = new FormData();
  formData.append('object', new Blob([JSON.stringify(dataToSend)], { type: 'application/json' }));
  // formData.append('tenantCode', tenantCode);
  console.log(tenantCode);
  if (pj.length > 0) {
    formData.append('pj', pj[0]);
  }

  if(type === "new"){
    const fileResponse = await fetch('/emptyXml.bpmn');
    const blob = await fileResponse.blob();
    formData.append('file', blob, 'file.bpmn');
  }else{
    formData.append('file', data, 'file.bpmn');
  }

  try {
    const response = await api.post('/regular/projects/create', formData);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateProject = async (formData, id) =>{
  try {
    const response = await api.put(`/regular/projects/update/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const updateProjectInfos = async (formData, id) =>{
  try {
    const response = await api.put(`/regular/projects/update-infos/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const deleteProject = async (id)=>{
  try {
    const response = await api.delete(`/regular/projects/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const shareProject = async (formData, id) =>{
  try {
    const response = await api.post(`/regular/projects/share/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const downloadFile = async (name)=>{
  try {
    const response = await api.get(`/regular/download/${name}`, {responseType: 'blob'});
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
